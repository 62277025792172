const HTable = {
  pagination: [15, 25, 50, 100, 500],
  pageSize: 50,
  align: 'left'
}

export const ROOT = 'Landscape Manager'

const HBreadcrumbs = {
  HOME: [
  ],
  TITLE_SCAN: [
    { text: ROOT, href: '#/' },
    { text: 'Titles', href: "#/titles" },
    { text: 'Scans' },
  ],
  SITE_SCAN: [
    { text: ROOT, href: '#/' },
    { text: 'Sites', href: "#/titles" },
    { text: 'Scans' },
  ],
  LANDSCAPES: [
    { text: ROOT, href: '#/' },
    { text: 'Landscapes' },
  ],
  SITES: [
    { text: ROOT, href: '#/' },
    { text: 'Sites' },
  ],
  TITLES: [
    { text: ROOT, href: '#/' },
    { text: 'Titles' },
  ]
}



export { HTable, HBreadcrumbs };