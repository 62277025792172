import { useRef, useState, useCallback, useEffect, useContext } from 'react'
import {
  EuiBasicTable,
  Direction,
  Pagination,
  EuiTableSelectionType,
  EuiTableSortingType,
  EuiFlexGroup,
  EuiBasicTableColumn, EuiBadge,
  CriteriaWithPagination,
  EuiButtonEmpty,
  EuiFlexItem,
  EuiLink,
  EuiTextAlign,
  EuiComboBoxOptionOption,
  EuiIcon,
} from "@elastic/eui"
import debounce from 'lodash/debounce';
import _ from 'lodash'
import { PageTemplateTable } from ".."
import { HTable } from '../../helpers';
import SearchInput from '../../components/SearchInput';
import BulkSelecteds from '../../components/Bulks/BulkSelecteds';
import BulkExport from '../../components/Bulks/BulkExport';
import { APISites, APITitles } from '../../services/api';
import { formatDate } from '../../services/format';
import { TRender } from '../../@types/IGlobal';
import { AiOutlineCheckCircle } from 'react-icons/ai'
import PopoverObservation from '../../components/Popovers/PopoverObservation';
import { FaRegFlag } from 'react-icons/fa'
import PopoverOptions from '../../components/Popovers/PopoverOptions';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { ToastsContext } from '../../modules/toast';
import { Fragment } from 'react';
import { IScanFilters, IScanFiltersString, IScanSite, IScanSiteResult, ISSPagination, TScanFilter } from 'src/@types/IScan';
import MDetails from 'src/components/Modals/MDetails';
import FFScan from 'src/components/Flyouts/FFilters/FFScan';
import { RiFilter2Fill } from 'react-icons/ri';
import { DStatus } from 'src/helpers/dictionaries';
import moment from 'moment';
import BFilter from 'src/components/Badges/BFilter';
import HOptions from 'src/helpers/options';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { ImFileText2 } from 'react-icons/im';

const SSites = (props: any) => {

  const {
    match: {
      params: { id },
    },
    isExpaned = false,
    updateTitle
  } = props;

  const tableRef = useRef<EuiBasicTable>(null);
  const toastsContext = useContext(ToastsContext);

  const [store, setStore] = useState<IScanSiteResult>({
    results: [],
  });

  const TITLE = localStorage.getItem("TITLE")

  const [loading, setLoading] = useState({
    export: false,
    store: true,
  })

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(isExpaned ? 10 : HTable.pageSize);
  const [sortField, setSortField] = useState<keyof IScanSite>('id');
  const [sortDirection, setSortDirection] = useState<Direction>('asc');
  const [selectedItems, setSelectedItems] = useState<IScanSite[]>([]);
  const [pageOfItems] = [store?.results];
  const [edit, setEdit] = useState<{ field: string | null, id: number | null, isLoading: boolean }>({ field: null, id: null, isLoading: false })
  const [query, setQuery] = useState<string>("")
  const [csv, setCsv] = useState([])
  const [item, setItem] = useState<IScanSite | null>(null)

  const [render, setRender] = useState<TRender>(null)

  const [filters, setFilters] = useState<IScanFilters | undefined>(undefined)

  const selectedFilters: IScanFiltersString | undefined = filters ? {
    obs: filters.obs?.map((item) => item.value).toString() || undefined,
    status: filters.status?.map((item) => item.value).toString() || undefined,
    colect: filters.colect?.map((item) => item.value).toString(),
    startDueDate: filters.startDueDate ? moment(filters.startDueDate).format('YYYY-MM-DD') : undefined,
    endDueDate: filters.endDueDate ? moment(filters.endDueDate).format('YYYY-MM-DD') : undefined,
    startScanDate: filters.startScanDate ? moment(filters.startScanDate).format('YYYY-MM-DD') : undefined,
    endScanDate: filters.endScanDate ? moment(filters.endScanDate).format('YYYY-MM-DD') : undefined,
  } : undefined

  const rows = item ? [
    {
      label: 'Title (slug)',
      value: item?.scan_title.title.slug
    },
    {
      label: 'Type',
      value: item?.scan_title.title.type
    },
    {
      label: 'Release Date',
      value: formatDate(item?.scan_title.title.release_date, 'dobLong')
    },
    {
      label: 'Season',
      value: item?.season
    },
    {
      label: 'Episode',
      value: item?.episode
    },
    {
      label: 'Site',
      value: item?.site.domain
    },
    {
      label: 'Scan Date',
      value: item?.scan_date?.split('-').reverse().join('/')
    },
    {
      label: 'Title Check',
      value: item?.title_check !== null ? item?.title_check ? "Has Title" : "No Title" : null
    },
    {
      label: 'Title Page',
      value: item?.title_page ? <EuiLink href={item?.title_page}>{item?.title_page}</EuiLink> : null
    },
    {
      label: 'Observations',
      value: item?.obs
    },
  ] : null

  const hasSelecteds = selectedItems.length !== 0

  const columns: EuiBasicTableColumn<IScanSite>[] = [
    {
      field: "domain",
      name: 'Domain',
      sortable: true,
      className: "cellWithRightBorder ssite",
      align: "left",
      mobileOptions: {
        //@ts-ignore
        render: (item: any, row: ISiteScan) =>
          <EuiLink href={`http://${item.domain}/?bc_domain=${encodeURIComponent(item.domain)}&bc_scan_title=${id}`} target="_blank">
            {item.domain}
          </EuiLink>
        ,
        header: false,
        //@ts-ignore
        truncateText: false,
        enlarge: true,
        fullWidth: true,
      },
      render: (domain: string, row: IScanSite) => (
        <EuiFlexGroup direction="column" gutterSize="none" className="p-15">
          <EuiFlexItem>
            <EuiLink href={`http://${row.site.domain}/?bc_domain=${encodeURIComponent(row.site.domain)}&bc_scan_title=${id}`} target="_blank" >
              {row.site.domain}
            </EuiLink>
            <EuiFlexItem grow={false} className={`status ${HOptions.site._status.includes(row.status) ? DStatus[row.status] : '-'}`}>
              {HOptions.site._status.includes(row.status) ? DStatus[row.status] : '-'}
            </EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    },
    {
      field: 'visits_sum',
      name: '#VISITS',
      align: "center",
      render: (value: null, scanSite: IScanSite) => <EuiTextAlign textAlign="right" className="w-100">{scanSite.visits_sum?.toLocaleString() || "-"}</EuiTextAlign>,
      sortable: true,
    },
    {
      field: "title_check",
      name: "TITLE CHECK",
      align: "center",
      sortable: true,
      render: (title_check: boolean | null, row: IScanSite) =>
        <PopoverOptions
          id={`$title_check_${row.id}`}
          options={[{ value: true, label: "Has title", color: "#41f075" }, { value: false, label: "No title", color: "#ff003d" }]}
          isLoading={edit.isLoading && edit.field === "title_check" && edit.id === row.id}
          onChange={(value) => onScan(row.id, "title_check", value)}
          button={title_check !== null ?
            <EuiBadge className="title--type" color={title_check ? "#41f075" : "#ff003d"} >
              {title_check ? "Has title" : "No title"}
            </EuiBadge>
            :
            <EuiButtonEmpty
              color="text"
              onClick={() => null}
              isLoading={edit.isLoading && edit.field === "title_check" && edit.id === row.id}
            >
              <AiOutlineCheckCircle /> Set status
            </EuiButtonEmpty>
          }
        />
    },
    {
      field: "assigned",
      name: "ASSIGNED",
      className: "assigned",
      align: "center",
    },
    {
      field: 'scan_date',
      name: 'SCAN DATE',
      align: "center",
      render: (scan_date: string | null, row: IScanSite) => formatDate(scan_date, 'dobLong'),
      sortable: true,
    },
    {
      field: 'due_date',
      name: 'DUE DATE',
      align: "center",
      render: (due_date: string) => formatDate(due_date, 'dobLong'),
      sortable: true,
    },
    {
      field: "obs",
      name: "OBSERVATIONS",
      align: "center",
      render: (obs: string, row: IScanSite) =>
        <PopoverObservation
          value={obs}
          onSubmit={(value, callback) => onScan(row.id, "obs", value, callback)}
          isLoading={edit.isLoading && edit.field === "obs" && edit.id === row.id}
        />
    },
    {
      field: "collect",
      align: "center",
      name: "COLLECT",
      render: (collect: boolean | null, row: IScanSite) => {
        if (!collect)
          return (
            <EuiButtonEmpty
              color="text"
              isLoading={edit.isLoading && edit.field === "collect" && edit.id === row.id}
              onClick={() => onScan(row.id, "collect", true)}
            >
              <FaRegFlag /> Flag to collect
            </EuiButtonEmpty>
          )

        return (
          <EuiBadge className="title--type action--close" color="#00C1D4" >
            Collect
            <IoCloseCircleSharp onClick={() => onScan(row.id, "collect", false)} />
          </EuiBadge>

        )
      }
    },
    {
      name: 'EDIT',
      hideForMobile: true,
      align: 'center',
      render: (item: IScanSite) => {
        return (
          <PopoverOptions
            panelClassName="optionsTable"
            anchorPosition="leftCenter"
            onChange={(key) => onRender(key, item)}
            button={
              <BiDotsHorizontalRounded />
            }
            options={
              [
                {
                  label: <Fragment><ImFileText2 className="euiIcon" /> Details</Fragment>,
                  value: 'details'
                }
              ]
            }
          />
        )
      }
    },
  ];

  const pagination: Pagination = {
    pageIndex,
    pageSize,
    totalItemCount: store?.count || 0,
    pageSizeOptions: HTable.pagination,
    hidePerPageOptions: isExpaned
  };

  const sorting: EuiTableSortingType<IScanSite> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  const onRender = (key?: TRender, item?: IScanSite) => {
    if (key && render) {
      setRender(null)
      return
    }
    if (key) {
      setRender(key)
      if (item) setItem(item)
      return
    }

    setItem(null);
    setRender(null)
  }

  const onSelectionChange = (selectedItems: IScanSite[]) => {

    setSelectedItems(selectedItems);


    let copy = _.cloneDeep(selectedItems)
    copy.map((data: IScanSite) => {

      //@ts-ignore
      data.scan_title = data.scan_title.title.name
      //@ts-ignore
      data.site = data.site.domain
    })
    //@ts-ignore
    setCsv(copy)

  };

  const selection: EuiTableSelectionType<IScanSite> = {
    selectable: (item: IScanSite) => true,
    selectableMessage: (selectable: boolean) =>
      !selectable ? 'User is currently offline' : '',
    onSelectionChange: onSelectionChange
  };

  const renderBulksRight = () => (
    <EuiFlexGroup className="bulk--actions" >
      {/* @ts-ignore */}
      <BulkExport hasSelecteds={hasSelecteds} onClick={() => getSSites({ pageSize: Number.MAX_SAFE_INTEGER })} filename="scans" data={csv} isLoading={loading.export} />
    </EuiFlexGroup>
  )

  const renderBulksLeft = () => {
    return (
      <EuiFlexGroup className="euiTable-actions" gutterSize="none">
        <BulkSelecteds
          onClick={() => {
            onSelectionChange([]);
            tableRef.current?.clearSelection();
          }}
          total={selectedItems.length}
          type="Scans"
        />
        <SearchInput
          id="searchInputSites"
          query={query}
          onChange={(e) => setQuery(e.target.value)}
          onClear={() => setQuery('')}
          onKeyUp={keyUpSearch}
        />
        <EuiButtonEmpty onClick={() => onRender('filters')}>
          <RiFilter2Fill />
        </EuiButtonEmpty>
        <EuiFlexGroup className="filters--badges">
          {filters && Object.keys(filters).map((key) => {
            const items = filters[key as TScanFilter]

            switch (key as TScanFilter) {
              case 'startScanDate':
              case 'endScanDate':
              case 'endDueDate':
              case 'startDueDate':
                if (items) return (
                  <BFilter
                    type={key}
                    // @ts-ignore
                    filter={moment(items).format('YYYY-MM-DD')}
                    // @ts-ignore
                    onClear={() => deleteFilters(key as TTitleFilter, key)}
                  />
                )
                break

              default:
                // @ts-ignore
                return items.map(el => {
                  return el.hasOwnProperty('label') ? (
                    <BFilter type={key} filter={el.label} onClear={() => deleteFilters(key as TScanFilter, el)} />
                  ) : null
                })
            }
          }
          )}
        </EuiFlexGroup>
      </EuiFlexGroup>
    )
  }

  const deleteFilters = (type: TScanFilter, item?: EuiComboBoxOptionOption | string) => {
    if (filters)
      switch (type) {
        case "startDueDate":
        case 'endDueDate':
        case 'endScanDate':
        case 'startScanDate':
          setFilters({ ...filters, [type]: undefined })
          break;

        default:
          if (typeof item === 'object') {
            const index = filters[type]?.findIndex(q => q.value?.toString() === item?.value?.toString())

            if (index === 0 && Array.isArray(filters[type]) && filters[type]?.length === 0) {
              setFilters({ ...filters, [type]: [] })
              return
            }
            if (index || index === 0) {
              filters[type]?.splice(index, 1)
              setFilters({ ...filters, [type]: filters[type] })
            }
          }
          break;
      }
  }


  const onTableChange = ({ page = { index: 0, size: 10 }, sort = { direction: 'asc', field: 'id' } }: CriteriaWithPagination<IScanSite>) => {
    const { index: pageIndex, size: pageSize } = page;

    const { field: sortField, direction: sortDirection } = sort;

    const ordering = sortDirection === "asc" ? `-${sortField}` : sortField

    debounceSSites({ page: pageIndex + 1, pageSize, domain: query, ordering, filters: selectedFilters })

    setPageIndex(pageIndex);
    setPageSize(pageSize);
    // @ts-ignore
    setSortField(sortField);
    setSortDirection(sortDirection);
  };

  const debounceSSites = useCallback(
    debounce((params) => {
      getSSites(params)
    }, 1000),
    []
  );

  const onListenerStatus = () => {
    const rows = document.querySelectorAll(".cellWithRightBorder.euiTableRowCell--hideForMobile.ssite");

    store?.results.forEach((item, index) => {
      rows[index].classList.add(`${DStatus[item.site.status ? item.site.status : '-']}`)
    })
  }

  const keyUpSearch = (e: KeyboardEvent) => {
    if (e.key === "Enter")
      debounceSSites({ filters: selectedFilters, domain: query, page: pageIndex + 1, pageSize })
  }

  const onScan = async (id: number, field: string, value: string | boolean, callback?: Function) => {
    setEdit({ id, field, isLoading: true })
    try {

      let response = await APISites.putScanSite(id, { [field]: value })

      const index = store?.results.findIndex((item: IScanSite) => item.id === response.data.id)

      store.results[index] = { ...store.results[index], ...response.data }

      if (index)
        setStore({ ...store, results: store.results })

      setEdit({ ...edit, isLoading: false })

      if (typeof callback === 'function')
        callback()

      if (typeof updateTitle === 'function' && field === 'title_check')
        updateTitle(response.data)

    } catch (e: any) {
      setEdit({ ...edit, isLoading: false })

      toastsContext.addErrorToast({
        title: 'Couldn\'t complete editing',
        message: (e as Error).toString()
      });

    }
  }


  const onUpdateFilters = (value: EuiComboBoxOptionOption[] | EuiComboBoxOptionOption | string, key: TScanFilter) => {
    setFilters({ ...filters, [key]: value })
  }

  const getSSites = async ({ page, pageSize, ordering, domain, filters, titleId = id }: ISSPagination) => {
    const isExport = pageSize === Number.MAX_SAFE_INTEGER
    try {

      if (isExport) {
        setLoading({ ...loading, export: true })
        setCsv([])
      } else setLoading({ ...loading, store: true });

      const response: IScanSiteResult = await APITitles.getSSites({
        titleId,
        page: page || pageIndex + 1,
        pageSize,
        ordering,
        domain,
        filters
      });

      if (isExport) {
        response.results.forEach((data: IScanSite) => {
          //@ts-ignore
          data.slug = data.scan_title.title.slug
          //@ts-ignore
          data.site = data.site.domain
          //@ts-ignore
          delete data.scan_title

        })

        //@ts-ignore
        setCsv(response.results)
        setLoading({ ...loading, export: false });

        return;
      }
      setStore(response)
      setLoading({ ...loading, store: false })
    }
    catch (e: any) {
      isExport ? setLoading({ ...loading, export: false }) : setLoading({ ...loading, store: true });
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete scan site request',
        message: (e as Error).toString()
      });
    }

  }

  useEffect(() => {
    getSSites({ page: 1, pageSize, titleId: id })
  }, [])

  useEffect(() => {
    onListenerStatus();
  }, [store])

  const table = (
    <EuiBasicTable
      ref={tableRef}
      items={pageOfItems || []}
      itemId="id"
      tableLayout="auto"
      hasActions={true}
      loading={loading.store}
      columns={columns}
      pagination={pagination}
      sorting={(!isExpaned && !loading.store) ? sorting : undefined}
      isSelectable={!isExpaned}
      selection={isExpaned ? undefined : selection}
      onChange={onTableChange}
      noItemsMessage={<EuiFlexItem className="p-30">No items found</EuiFlexItem>}
    />
  )

  useEffect(() => {
    debounceSSites({ filters: selectedFilters, domain: query, page: pageIndex + 1, pageSize })
  }, [filters, query])

  const bulksLeft = renderBulksLeft()
  const bulksRight = renderBulksRight()

  return (
    <Fragment>
      {isExpaned ? table
        :
        <PageTemplateTable
          id="sscan-page"
          breadcrumbs={[
            { text: "Landscape Manager", href: '#/' },
            { text: 'Titles', href: "#/titles" },
            { text: TITLE, href: `#/titles/${id}` },
            { text: 'Scans' },
          ]}
          bulksLeft={bulksLeft}
          bulksRight={bulksRight}
        >
          {table}
        </PageTemplateTable>
      }
      {render === 'details' && <MDetails title="Details - Site Scan" onClose={onRender} rows={rows} />}
      <FFScan
        isOpen={render === 'filters'}
        onClose={onRender}
        filters={filters}
        onChange={onUpdateFilters}
        resetFilters={() => setFilters(undefined)}
      />
    </Fragment>
  )
}

export default SSites;