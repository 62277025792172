
import styled from 'styled-components';


const OptionsGroup = styled.div`
display: flex;
justify-content: left;
flex-flow: column;
max-height: 300px;
overflow-y: auto;
overflow-x: hidden;
`

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row;
  transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);

  padding: 16px;
  cursor: pointer;

  color: black;

  svg {
    margin-right: 5px;
  }

  :hover {
    color: ${props => props.color};
    transform: translateY(-2px);
  }
  .euiAvatar {
    margin-right: 5px;
  }
`

export { OptionsGroup, OptionItem }