import { useState, useContext} from 'react'
import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFormRow,
  EuiDualRange,
  EuiDatePicker,
  EuiForm, htmlIdGenerator
} from '@elastic/eui'
import React, { Fragment } from 'react'
import { renderOption } from 'src/helpers/eui'
import HOptions from 'src/helpers/options'
import FFTemplate from './FFTemplate'
import { ITitlesFilters, TTitleFilter } from 'src/@types/ITitle'
import { APILandscapes, APITitles } from 'src/services/api'
import { useEffect } from 'react'
import { SimpleLoading } from 'src/components/Loaders/SimpleLoading'
import { ToastsContext } from 'src/modules/toast'

interface Props {
  onClose: () => void
  isOpen?: boolean
  readonly className?: string,
  resetFilters?: () => void,
  filters?: ITitlesFilters | null,
  applyFilters?: Function,
  onChange?: (value: EuiComboBoxOptionOption | EuiComboBoxOptionOption[], key: TTitleFilter) => void
}


const FFTitles: React.FC<Props> = (props) => {
  const toastsContext = useContext(ToastsContext);
  const { onClose, filters, resetFilters, isOpen } = props;
  const [landscapesOptions, setLandscapesOptions] = useState<{ data?: EuiComboBoxOptionOption[], isLoading: boolean }>({ isLoading: true })
  const [clientsOptions, setClientsOptions] = useState<{ data?: EuiComboBoxOptionOption[], isLoading: boolean }>({ isLoading: true })
  const [maxSitesAmount, setMaxSitesAmount] = useState<{ value: number, isLoading: boolean }>({ value: 10000, isLoading: true })

  const onChange = (value: any, key: TTitleFilter) => {
    if (typeof props.onChange === 'function')
      props.onChange(value, key)
  }

  const getClients = async () => {
    try {
      const response = await APITitles.getClients();

      const clientsFiltereds =  response.data.filter((item: string) => item.length > 0)
      
      setClientsOptions({
        data: clientsFiltereds.map((item: string) =>  ({ label: item, value: item })), 
        isLoading: false
      })
    } catch (e) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete client request',
        message: (e as Error).message
      });
    }
  }

  const getLandscapes = async (page = 1) => {
    try {
      const landscapes = await APILandscapes.fetchLandscapes({ page, pageSize: Number.MAX_SAFE_INTEGER });
      setLandscapesOptions({
        data: landscapes.results.map((landscape) => ({
          label: landscape.name, value: landscape.id
        })), isLoading: false
      })

    } catch (e) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete landscapes request',
        message: (e as Error).message
      });
    }
  }

  const getMaxSitesAmount = async () => {
    try {
      const response = await APITitles.getMaxSitesAmount();
      setMaxSitesAmount({ value: response.data.max_sites_amount, isLoading: false })

    } catch (e) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete max sites amount request',
        message: (e as Error).message
      });
    }
  }

  useEffect(() => {
    if (isOpen) {
      getClients()
      getLandscapes()
      getMaxSitesAmount()
    }
  }, [isOpen])

  return (
    <FFTemplate
      title='FILTERS/TITLES'
      onClose={onClose}
      isOpen={isOpen}
      resetFilters={() => typeof resetFilters === 'function' ? resetFilters() : null}
    >
      <EuiForm>
        <EuiFormRow label="Status">
          <EuiComboBox
            compressed
            renderOption={renderOption}
            sortMatchesBy="startsWith"
            placeholder="All"
            options={HOptions.status}
            selectedOptions={filters?.status as EuiComboBoxOptionOption[]}
            onChange={(value) => onChange(value, "status")}
            data-test-subj="demoComboBox"
          />
        </EuiFormRow>
        <EuiFormRow label="Client">
          <EuiComboBox
            compressed
            renderOption={renderOption}
            sortMatchesBy="startsWith"
            placeholder="All"
            isLoading={clientsOptions.isLoading}
            options={clientsOptions.data}
            selectedOptions={filters?.clients as EuiComboBoxOptionOption[]}
            onChange={(value) => onChange(value, "clients")}
          />
        </EuiFormRow>
        <div className="euiFormRow" id="i7e2f3913-0d0e-11ec-8d9a-e375a361c472-row">
          {maxSitesAmount.isLoading ? <SimpleLoading />
            :
            <Fragment>
              <div className="euiFormRow__labelWrapper">
                <label className="euiFormLabel euiFormRow__label" htmlFor="i7e2f3913-0d0e-11ec-8d9a-e375a361c472">Sites #</label>
                <div className="">{`${filters?.sites_amount ? filters?.sites_amount[0] : 0} - ${filters?.sites_amount ? filters?.sites_amount[1] : maxSitesAmount.value}`}</div>
              </div>
              <EuiDualRange
                id={htmlIdGenerator()()}
                min={0}
                max={maxSitesAmount.value || 1000}
                value={filters?.sites_amount || [0, 0]}
                onChange={(value) => onChange(value, "sites_amount")}
              />
            </Fragment>
          }
        </div>
        <EuiFormRow label="Type">
          <EuiComboBox
            compressed
            renderOption={renderOption}
            sortMatchesBy="startsWith"
            placeholder="All"
            options={HOptions.title.types}
            selectedOptions={filters?.types as EuiComboBoxOptionOption[]}
            onChange={(value) => onChange(value, "types")}
          />
        </EuiFormRow>
        <EuiFormRow label="Due Date">
          <Fragment>
            <EuiDatePicker
              selected={filters?.start_date}
              // @ts-ignore
              onChange={(value) => onChange(value, "start_date")}
              startDate={filters?.start_date}
              endDate={filters?.end_date}
              // isInvalid={startDate > endDate}
              placeholder="Start Date"
            />
            <EuiDatePicker
              selected={filters?.end_date}
              // @ts-ignore
              onChange={(value) => onChange(value, "end_date")}
              startDate={filters?.end_date}
              endDate={filters?.end_date}
              // isInvalid={startDate > endDate}
              aria-label="End date"
              placeholder="End Date"
            />
          </Fragment>
        </EuiFormRow>
        <EuiFormRow label="Landscapes">
          <EuiComboBox
            compressed
            sortMatchesBy="startsWith"
            placeholder="All"
            renderOption={renderOption}
            // async
            // onSearchChange={(searchValue) => handleSearch(searchValue, "landscapes")}
            isLoading={landscapesOptions.isLoading}
            options={landscapesOptions.data}
            selectedOptions={filters?.landscapes as EuiComboBoxOptionOption[]}
            onChange={(value) => onChange(value, "landscapes")}
          />
        </EuiFormRow>
      </EuiForm>
    </FFTemplate>
  )
}

export default FFTitles