import { useEffect, useState, useContext, useRef, useCallback, Fragment } from 'react';
import {
  EuiBasicTable,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiBadge,
  EuiBasicTableColumn,
  EuiTableSortingType,
  Direction,
  Pagination,
  EuiTableSelectionType,
  CriteriaWithPagination,
  EuiComboBoxOptionOption,
  EuiLink,
  EuiButtonEmpty
} from '@elastic/eui';
import _ from 'lodash'
import { APITitles } from '../../services/api';
import { ToastsContext } from '../../modules/toast';
import { HTable } from '../../helpers';
import BulkExport from '../../components/Bulks/BulkExport';
import { AddLandscapesButton } from '../Landscapes';
import { PageTemplateTable } from '..';
import { IDict, IPaginationFetch, TRender } from '../../@types/IGlobal';
import Progress from '../../components/Progress';
import { IScanSite, IScanSiteResult, IScanTitle, IScanTitleResult, ISSPagination } from 'src/@types/IScan';
import MDetails from 'src/components/Modals/MDetails';
import { DTypeColors } from 'src/helpers/dictionaries';
import { formatDate } from 'src/services/format';
import SSites from './SSites';
import { RowLandscapes } from 'src/components/Rows'
import HOptions from 'src/helpers/options';
import PopoverOptions from 'src/components/Popovers/PopoverOptions';


interface ILoading {
  store: boolean
  export: boolean
}

interface IEdit {
  key: string | null
  id: number | null,
  isLoading: boolean
}

const STitles = (props: any) => {
  const {
    match: {
      params: { id },
    }
  } = props;

  const toastsContext = useContext(ToastsContext);
  const tableRef = useRef<EuiBasicTable>(null);


  const [loading, setLoading] = useState<ILoading>({
    store: true,
    export: false
  });

  const [item, setItem] = useState<IScanTitle | any | undefined>(undefined)

  const [store, setStore] = useState<IScanTitleResult>({
    results: [],
    count: 0
  });
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(HTable.pageSize);
  const [sortField, setSortField] = useState<keyof IScanTitle>('id');
  const [sortDirection, setSortDirection] = useState<Direction>('asc');

  const [selectedItems, setSelectedItems] = useState<IScanTitle[]>([]);
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<IDict>({});

  const [sSites, setSSites] = useState<IScanSiteResult>({
    results: [], isLoading: false
  });

  const [render, setRender] = useState<TRender>(null)

  const [landscapes, setLandscapes] = useState<EuiComboBoxOptionOption[]>([])
  const [edit, setEdit] = useState<IEdit>({ key: null, id: null, isLoading: false })


  const [titles, setTitles] = useState<IScanTitle[]>([]);
  const [query, setQuery] = useState<string>("")
  const [csv, setCsv] = useState([]);

  const getSSites = async ({ titleId }: ISSPagination) => {
    setItem({ titleId })
    setSSites({ ...sSites, isLoading: true })

    try {
      const response: IScanSiteResult = await APITitles.getSSites({
        titleId,
        page: 1,
        pageSize: Number.MAX_SAFE_INTEGER,
      });

      response.results.forEach((data: IScanSite) => {
        //@ts-ignore
        data.slug = data.scan_title.title.slug
        //@ts-ignore
        data.site = data.site.domain
        //@ts-ignore
        delete data.scan_title
      })

      setSSites({ results: response.results, isLoading: false })

    }
    catch (e: any) {
      setSSites({ ...sSites, isLoading: false })
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete export',
        message: (e as Error).toString()
      });
    }

  }

  const hasSelecteds = selectedItems.length !== 0

  // const DataContext = createContext();

  const columns: EuiBasicTableColumn<IScanTitle>[] = [
    {
      field: 'name',
      name: 'Name',
      className: "cellWithRightBorder Active",
      sortable: true,
      render: (name: string, row: IScanTitle) =>
        <EuiFlexGroup direction="column" gutterSize="none" className="p-15" >
          <EuiFlexItem>
            <EuiLink href={`#/titles/${row.id}/scans`} onClick={() => localStorage.setItem("TITLE", row.title.name)} >
              {row.title.name}
            </EuiLink>
          </EuiFlexItem>
          {row.title.type === "tv" &&
            <EuiFlexGroup direction="row" alignItems="center">
              <EuiFlexItem grow={false} >
                <PopoverOptions
                  id={`season_${row.id}`}
                  options={seasonsOptions}
                  onChange={(value) => onScanTitle(row.id, "season", value)}
                  button={
                    <EuiButtonEmpty
                      className="titleOption"
                      isLoading={edit.isLoading && edit.key === "season" && edit.id === row.id}
                      color={row.season ? "ghost" : 'text'}
                      onClick={() => null}
                    >
                      {row.season ? `Season ${row.season}` : "Select Season"}
                    </EuiButtonEmpty>
                  }
                />
              </EuiFlexItem>
              <div className="barHorizontal" />

              <EuiFlexItem grow={false} >
                <PopoverOptions
                  id={`episode_${row.id}`}
                  options={episodesOptions}
                  onChange={(value) => onScanTitle(row.id, "episode", value)}
                  button={
                    <EuiButtonEmpty
                      color={row.episode ? 'ghost' : 'text'}
                      className="titleOption"
                      isLoading={edit.isLoading && edit.key === "episode" && edit.id === row.id}
                      onClick={() => onScanTitle}
                    >
                      {row.episode ? `Episode ${row.episode}` : 'Select Episode'}
                    </EuiButtonEmpty>
                  }
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        </EuiFlexGroup>,
    },

    {
      field: 'sites_amount',
      name: '# OF SITES',
      align: 'center',
      render: (sites_amount: number) => sites_amount,
      sortable: true,
    },
    {
      field: "type",
      name: 'Type',
      align: 'center',
      sortable: true,
      truncateText: true,
      render: (type: null, row: IScanTitle) =>
        <EuiBadge className="title--type" color={DTypeColors[row.title.type]}>
          {row.title.type}
        </EuiBadge>
    },
    {
      field: "assigned",
      name: "ASSIGNED",
      align: "center",
    },
    {
      field: 'scan_progress',
      name: 'HAS TITLE',
      align: 'center',
      render: (scan_progress: number, row: IScanTitle) => <Progress value={row.scan_progress} max={row.sites_amount} />,
      sortable: true,
    },
    {
      field: 'due_date',
      name: 'DUE DATE',
      className: 'overflow',
      align: "center",
      render: (due_date: string | null) => formatDate((due_date), 'dobLong'),
      sortable: true,
    },
    {
      field: "landscapes",
      align: "center",
      name: 'LANDSCAPES',
      render: (value: null, row: IScanTitle) =>
        <RowLandscapes
          landscapes={row.title.landscapes}
          readonly={true}
        />
    },
    {
      align: "center",
      isExpander: true,
      render: (scan: IScanTitle) => (
        <EuiButtonIcon
          onClick={() => toggleDetails(scan.id)}
          aria-label={itemIdToExpandedRowMap[scan.id] ? 'Collapse' : 'Expand'}
          iconType={itemIdToExpandedRowMap[scan.id] ? 'arrowUp' : 'arrowDown'}
        />
      ),
    },
  ];

  const onRender = (key?: TRender, item?: IScanTitle) => {
    if (key && render) {
      setRender(null)
      return
    }
    if (key) {
      setRender(key)
      if (item) setItem(item)
      return
    }

    setItem(null);
    setRender(null)
  }

  const onTableChange = ({ page = { index: 0, size: 5 }, sort = { direction: 'asc', field: 'id' } }: CriteriaWithPagination<IScanTitle>) => {
    const { index: pageIndex, size: pageSize } = page;

    const { field: sortField, direction: sortDirection } = sort;

    const ordering = sortDirection === "asc" ? `-${sortField}` : sortField

    getTitlesScan({ page: pageIndex + 1, pageSize, ordering, query })

    setPageIndex(pageIndex);
    setPageSize(pageSize);
    setSortField(sortField);
    setSortDirection(sortDirection);
  };

  const onSelectionChange = (selectedItems: IScanTitle[]) => {
    //@ts-ignore
    setTitles(setSelectedItems)
    setLandscapes([])


    let copy = _.cloneDeep(selectedItems)
    copy.map((data: IScanTitle) => {
      //@ts-ignore
      data.title.landscapes = data.title.landscapes.map((item: any) => item.name).toString()
    })
    //@ts-ignore
    setCsv(copy)

    setSelectedItems(selectedItems);
  };

  const updateTitle = (response: any) => {
    const indexTitle = store?.results.findIndex((item: IScanTitle) => item.id === response.scan_title.id)
    store.results[indexTitle] = response.scan_title

    setStore({ ...store, results: store.results })
  }

  const onScanTitle = async (id: number, key: string, value: any) => {
    setEdit({ id, key, isLoading: true })
    try {

      let response = await APITitles.putScanTitle(id, { [key]: value })

      const index = store?.results.findIndex((item) => item.id === response.data.id)

      store.results[index] = response.data

      if (index)
        setStore({ ...store, results: store.results })


      setEdit({ ...edit, isLoading: false })

    } catch (e: any) {

      toastsContext.addErrorToast({
        title: 'Couldn\'t complete editing',
        message: (e as Error).toString()
      });

    }
  }



  const toggleDetails = async (id: number, isOpen?: boolean) => {
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap }; //deep copy

    if (itemIdToExpandedRowMapValues[id] && !isOpen) {
      delete itemIdToExpandedRowMapValues[id];
    } else {

      itemIdToExpandedRowMapValues[id] = (
        <SSites match={{ params: { id } }} isExpaned={true} updateTitle={updateTitle} />
      );
    }

    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues)
  };

  const [pageOfItems] = [store?.results];
  const pagination: Pagination = {
    pageIndex,
    pageSize,
    totalItemCount: store?.count || 0,
    pageSizeOptions: HTable.pagination,
  };

  const sorting: EuiTableSortingType<IScanTitle> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  const selection: EuiTableSelectionType<IScanTitle> = {
    selectable: (item: IScanTitle) => true,
    selectableMessage: (selectable: boolean) =>
      !selectable ? 'User is currently offline' : '',
    onSelectionChange: onSelectionChange
  };

  const getTitlesScan = async ({ page, pageSize, query, ordering }: IPaginationFetch) => {
    const isExport = pageSize === Number.MAX_SAFE_INTEGER
    try {
      if (isExport) {
        setLoading({ ...loading, export: true })
        setCsv([])
      } else setLoading({ ...loading, store: true });

      const response: IScanTitleResult = await APITitles.getSTitles({ titleId: id, pageSize: 10, ordering, page: 1 });

      // if (isExport) {
      //   response.results.forEach((data: any) => {
      //     data.landscapes = data.title.landscapes.map((item: any) => item.name).toString()
      //     data.slug = data.title.slug
      //     data.type = data.title.type
      //     data.relese_date = data.title.release_date
      //     data.title = data.title.name
      //   })
      //   {/* @ts-ignore */ }
      //   setCsv(response.results)
      //   setLoading({ ...loading, export: false })

      //   return;
      // }
      // @ts-ignore
      response.data.results.forEach(item => {
        if (typeof item.start_date === 'undefined') {
          item.start_date = new Date()
        }
        if (typeof item.end_date === 'undefined') {
          item.end_date = new Date()
        }
      })
      // @ts-ignore
      setStore(response.data);
      setLoading({ ...loading, store: false })
    } catch (e: any) {
      isExport ? setLoading({ ...loading, export: false }) : setLoading({ ...loading, store: true });
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete titles request',
        message: (e as Error).toString()
      });
    }
  }

  const seasonsOptions = HOptions.generator(1, 90, 'Season')
  const episodesOptions = HOptions.generator(1, 90, 'Episode')

  const TITLE = localStorage.getItem("TITLE")

  useEffect(() => {
    const addTitleBtn = document.querySelector("#addTitleBtn")
    addTitleBtn?.addEventListener("click", () => {
      getTitlesScan({ page: pageIndex + 1, pageSize, query, ordering: "-created_at" })
    })
  })

  useEffect(() => {
    getTitlesScan({ pageSize, ordering: "-created_at" });
  }, [])

  return (
    <PageTemplateTable
      id="titles-page"
      breadcrumbs={[
        { text: "Landscape Manager", href: '#/' },
        { text: 'Titles', href: "#/titles" },
        { text: TITLE },
      ]}
      bulksRight={[]}
    >
      <EuiBasicTable
        ref={tableRef}
        items={pageOfItems || []}
        itemId="id"
        tableLayout="auto"
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        isExpandable={true}
        hasActions={true}
        loading={loading.store}
        columns={columns}
        pagination={pagination}
        sorting={!loading.store ? sorting : undefined}
        isSelectable={true}
        selection={selection}
        onChange={onTableChange}
        noItemsMessage={<EuiFlexItem className="p-30">No items found</EuiFlexItem>}
      />

    </PageTemplateTable>
  );
};

export default STitles;