import { HashRouter, Switch, Route, BrowserRouter, Router } from 'react-router-dom';
import { Home, Sites, Titles, Landscapes } from './pages';
import FSidebar from './components/Flyouts/FSidebar';
import STitles from './pages/Scan/STitles';
import SSites from './pages/Scan/SSites';
import history from './services/history';
import { AuthProvider } from './hooks/useAuth';

function App() {
  return (

    <HashRouter>

      {/* <Router history={history}> */}
      <AuthProvider>
        <Switch>
          <FSidebar>
            <Route exact path="/"><Home /></Route>
            <Route exact path="/sites"><Sites /></Route>
            <Route exact path="/titles"><Titles /></Route>
            <Route exact path="/titles/:id" component={STitles} />
            <Route exact path="/titles/:id/scans" component={SSites} />
            <Route exact path="/landscapes"><Landscapes /></Route>
          </FSidebar>
        </Switch>
      </AuthProvider>
      {/* </Router> */}

    </HashRouter>
  );
}

export default App;
