import axios from "axios";
import { IScanTitleResult } from "src/@types/IScan";
import { apiBaseURL } from ".";
import { ILandscapeResult, ILandscapesPagination } from '../../@types/ILandscape';

const APILandscapes = {
  async fetchLandscapes({ pageSize = 10, page = 1, name, ordering, callback, filters }: ILandscapesPagination): Promise<ILandscapeResult> {
    try {
      let filter: string = ''

      if (name && name?.length > 0) filter += `&name=${name}`
      if (ordering && ordering?.length > 0) filter += `&ordering=${ordering}`
      if (filters) {
        if (filters?.status && filters?.status.length > 0) filter += `&status=${encodeURIComponent(filters.status)}`;
        if (filters?.titles && filters?.titles.length > 0) filter += `&titles=${encodeURIComponent(filters.titles)}`;
        if (filters?.sites_count_lower && filters?.sites_count_lower.length > 0) filter += `&sites_count_lower=${encodeURIComponent(filters.sites_count_lower)}`;
        if (filters?.sites_count_upper && filters?.sites_count_upper.length > 0) filter += `&sites_count_upper=${encodeURIComponent(filters.sites_count_upper)}`;
        if (filters?.titles_count_lower && filters?.titles_count_lower.length > 0) filter += `&titles_count_lower=${encodeURIComponent(filters.titles_count_lower)}`;
        if (filters?.titles_count_upper && filters?.titles_count_upper.length > 0) filter += `&titles_count_upper=${encodeURIComponent(filters.titles_count_upper)}`;
        if (filters?.domains && filters?.domains.length > 0) filter += `&domains=${encodeURIComponent(filters.domains)}`;
      }

      const response: Response = await fetch(`${apiBaseURL}/landscapes?page=${page}&page_size=${pageSize}${filter}`);
      const data: ILandscapeResult = await response.json();
      if (typeof callback === 'function')
        callback()
      return data;
    }
    catch (e: any) {
      throw new Error((e as TypeError).message);
    }
  },

  postlandscape(body: Object) {
    return axios.post(`${apiBaseURL}/landscapes/`, body)
  },
  postSitesLandscapes(item: { sites: any, landscapes: any }) {
    return axios.post(`${apiBaseURL}/sitelandscapes/assosiate_sites/`, item)
  },
  postTitlesLandscapes(item: { titles: any, landscapes: any }) {
    return axios.post(`${apiBaseURL}/landscapetitles/assosiate_titles/`, item)
  },
  putLandscape(landscapeId: number, body: Object) {
    return axios.put(`${apiBaseURL}/landscapes/${landscapeId}/`, body)
  },
  getMaxSitesAmount() {
    return axios.get(`${apiBaseURL}/landscapes/max-sites/`)
  },
  getMaxTitlesAmount() {
    return axios.get(`${apiBaseURL}/landscapes/max-titles/`)
  },
  async fetchTitlesByLandscape({ landscape_id, page = 1 }: { landscape_id: number, page?: number }): Promise<IScanTitleResult> {
    try {
      const response: Response = await fetch(`${apiBaseURL}/landscapes/${landscape_id}/titles/?page=${page}`);
      if (response.status >= 400 && response.status < 600) {
        throw new Error("Bad response from server");
      }
      const data: IScanTitleResult = await response.json();
      return data;
    } catch (e) {
      throw new Error((e as TypeError).message);
    }
  },
  getTitlesByLandscape({ landscapeId, page = 1 }: { landscapeId: number, page?: number }) {
    return axios.get(`${apiBaseURL}/titles?landscape_id=${landscapeId}&page=${page}`)
  },
  postDisassociate(body: { titles: any, landscapes: any }) {
    return axios.post(`${apiBaseURL}/landscapetitles/disassociate/`, body)
  },

  deleteLandscape(id: number) {
    return axios.delete(`${apiBaseURL}/landscapes/${id}/`)
  },
  getTitles(idLandscape: number) {
    return axios.get(`${apiBaseURL}/landscapes/${idLandscape}/titles/`)
  }
}



export default APILandscapes;
