import React, { Fragment } from 'react';
import { EuiButtonEmpty } from '@elastic/eui';
import { FaRetweet } from 'react-icons/fa';
import { IoInfiniteOutline } from 'react-icons/io5';
import moment from 'moment';
import { formatDate } from 'src/services/format';


interface Props {
  onClick?: () => void
  startDate?: string | null
  endDate?: string | null
  dueDate?: string | null
  recurrency?: string | null
  isForever?: boolean
}

const RowDate: React.FC<Props> = (props) => (
  <EuiButtonEmpty className={`row-date ${props.recurrency || ''}`} onClick={() => typeof props.onClick === 'function' ? props.onClick() : null} iconType={(!props.recurrency && !props.dueDate && !props.startDate) ? 'calendar' : ''}>
    {props.recurrency &&
      <Fragment>
        <FaRetweet />
        {(props.isForever && props.recurrency === 'monthly') ? moment(props.startDate).format('DD') : moment(props.startDate).format('dddd')}
        {props.isForever && <Fragment>/<IoInfiniteOutline className="icon-infinity" /></Fragment>}
      </Fragment>}
    {props.dueDate && formatDate((props.dueDate), 'dobLong')}
    {(!props.recurrency && !props.dueDate && !props.startDate) && 'Set date'}
  </EuiButtonEmpty>
)

export default RowDate;