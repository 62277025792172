import axios from "axios";
import { apiBaseURL } from ".";

const APIGlobals = {
  getLogs() {
    return axios.get(`${apiBaseURL}/logs/?page_size=8`);
  }
}

export default APIGlobals;



