import React, { useState, useEffect } from 'react'
import {
  EuiComboBoxOptionOption,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiTitle,
  EuiFlyoutFooter,
  EuiButton,
} from '@elastic/eui'
import SearchInputAdvanced from '../SearchInput/SearchInputAdvanced'

interface Props {
  isOpen?: boolean
  onClose: () => void
  readonly className?: string,
  callback?: Function
  options?: EuiComboBoxOptionOption[]
  onChange?: (searchValue: string, hasMatchingOptions?: boolean | undefined) => void
}

const FSearch: React.FC<Props> = (props) => {
  const { onClose, callback, isOpen, options } = props;

  const [selectedOptions, setSelectedOptions] = useState<EuiComboBoxOptionOption[]>([])

  useEffect(() => {
    if (typeof callback === 'function')
      callback(selectedOptions)
  }, [selectedOptions])

  return isOpen ? (
    <EuiFlyout
      className="flyout-rigth bg-dark"
      ownFocus={false}
      // type="push"
      size="s"
      onClose={onClose}
    >
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="s">
          <div className="">ADVANCED SEARCH</div>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFormRow label="Domains">
          <SearchInputAdvanced
            onSearchChange={props.onChange} // request options
            options={options}
            selectedOptions={selectedOptions}
            onChange={(options) => setSelectedOptions(options)}
            setSelecteds={(searchValue) => setSelectedOptions(selectedOptions => [...selectedOptions, searchValue])}
          />
        </EuiFormRow>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiButton color="secondary" onClick={onClose}>
          Close
        </EuiButton>
      </EuiFlyoutFooter>
    </EuiFlyout>
  ) : null
}

export default FSearch