import React, { useState, FC } from 'react'
import {
  EuiPopover, EuiPopoverTitle, EuiButtonEmpty,
  EuiPopoverFooter, EuiTextArea, EuiLink
} from "@elastic/eui";
import { BiEdit } from 'react-icons/bi'


interface Props {
  id?: number,
  readonly title?: string,
  readonly panelClassName?: string,
  readonly value: string,
  onSubmit?: (event: any, callback?: Function) => void
  isLoading?: boolean
}

const PopoverObservation: FC<Props> = ({ id, panelClassName = "popover--default", value, onSubmit, isLoading = false }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [obs, setObs] = useState<string>(value)

  const handlePopoverOpen = () => {
    setOpen(open => !open)
  }
  

  const handlerSubmit = (e?: any) => {
    e?.preventDefault()
    e?.stopPropagation()
    if (typeof onSubmit === 'function')
      onSubmit(obs, () => setOpen(false))
  }

  return (
    <EuiPopover
      id={`popoverObs_${id}`}
      ownFocus={true}
      button={
        value?.length > 0 ?
          <EuiLink
            color="text"
            onClick={handlePopoverOpen}
          >
            <BiEdit /> {value}
          </EuiLink>
          :
          <EuiLink
            color="text"
            onClick={handlePopoverOpen}
          >
            <BiEdit /> Add observation
        </EuiLink>
      }
      className="render-text"
      panelClassName="popover  default w-middle"
      isOpen={open}
      closePopover={() => setOpen(false)}
      anchorPosition="downLeft"
      hasArrow={false}
    >
      <EuiPopoverTitle>Add observation</EuiPopoverTitle>
      <EuiTextArea
        placeholder="Observation"
        maxLength={255}
        value={obs}
        autoFocus
        onChange={(e: any) => setObs(e.target.value)}
      />
      <EuiPopoverFooter>
        <EuiButtonEmpty onClick={(e: any) => setOpen(false)} >
          Cancel
        </EuiButtonEmpty>
        <EuiButtonEmpty onClick={handlerSubmit} isLoading={isLoading}>
          Save
        </EuiButtonEmpty>
      </EuiPopoverFooter>
    </EuiPopover>
  )
}

export default PopoverObservation;
