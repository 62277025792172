import React, { useEffect, useState, useContext, FC } from 'react';
import * as Yup from 'yup';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiButton,
  EuiFormRow,
  EuiFieldText,
  EuiForm
} from '@elastic/eui';
import _ from 'lodash';
import { APILandscapes, APITitles } from '../../services/api';
import { ToastsContext } from '../../modules/toast';
import { isEmpty } from '../../utils';
import { ILandscape } from 'src/@types/ILandscape';
import { IScanTitleResult } from 'src/@types/IScan';
import HOptions from 'src/helpers/options';
import { DStatus } from 'src/helpers/dictionaries';

export interface Props {
  onClose: () => void
  landscape?: ILandscape
  callback?: Function
}

interface ISelecteds {
  titles?: EuiComboBoxOptionOption[],
  status?: EuiComboBoxOptionOption[],
}

interface IOptions {
  data: EuiComboBoxOptionOption[],
  isLoading: boolean
}

const MLandscape: FC<Props> = ({ onClose, landscape, callback }) => {
  const toastsContext = useContext(ToastsContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [titlesOptions, setTitleOptions] = useState<IOptions>({ data: [], isLoading: true });

  const [originalTitles, setOriginalTitles] = useState<EuiComboBoxOptionOption[]>([]);

  const [selectedOptions, setSelectedOptions] = useState<ISelecteds | undefined>({
    status: (landscape && landscape.status) ? [{ value: landscape.status, label: DStatus[landscape.status] }] : [{ value: 'On', label: DStatus['On'] }],
  })

  const [name, setName] = useState<string>("")
  const [errors, setErrors] = useState<any>(undefined)

  const hasLandscape = landscape ? true : false

  const getTitles = async (page = 1, pageSize = Number.MAX_SAFE_INTEGER) => {
    setTitleOptions({ ...titlesOptions, isLoading: true })
    try {
      const response = await APITitles.getTitles({ page, pageSize });

      setTitleOptions({
        //@ts-ignore
        data: response.data.results.map((item: any) => ({
          label: item.name,
          value: item.id
        })),
        isLoading: false
      })
    } catch (e) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete getTitles',
        message: (e as Error).toString()
      });
    }
  }

  const cleanModal = () => {
    setSelectedOptions(undefined);
  }

  const closeModal = (event?: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    cleanModal();
    if (typeof onClose === 'function') onClose();
  }

  const onSubmit = async (event?: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    setIsLoading(true)
    try {
      let schema = Yup.object().shape({
        name: Yup.string().required("Field is required"),
        status: Yup.string()
      });

      const fields = {
        name,
        titles: selectedOptions?.titles?.map(item => item.value),
        status: selectedOptions?.status?.map(item => item.value).toString()
      }

      await schema.validate(fields, {
        abortEarly: false,
      })

      await APILandscapes.postlandscape(fields)

      toastsContext.addSuccessToast({
        title: 'Success',
        message: "Landscape created successfully"
      });

      window.location.href = '/#/landscapes'

      closeModal();
    }
    catch (e: any) {
      setIsLoading(false);
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {}

        e.inner.forEach((error) => {
          //@ts-ignore
          errorMessages[error.path] = error.message
        })
        setErrors(errorMessages)
        return;
      }
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete the export',
        message: (e as Error).toString()
      });
    }
  }

  const getTitlesByLandscape = async (id: number) => {
    setTitleOptions({ ...titlesOptions, isLoading: true })
    try {
      const response: IScanTitleResult = await APILandscapes.fetchTitlesByLandscape({ landscape_id: id })

      setOriginalTitles(response.results.map((item: any) => {
        return {
          label: item.name,
          value: item.id
        }
      }))

      setSelectedOptions({
        ...selectedOptions,
        titles: response.results.map((item: any) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      })

      setTitleOptions({ ...titlesOptions, isLoading: false })
    }
    catch (e: any) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete titles',
        message: (e as Error).toString()
      });

    }
  }

  const handlerUpdate = async () => {
    setIsLoading(true)
    try {
      const disassociate = _.differenceBy(originalTitles.map(item => item.value) || [],
        selectedOptions?.titles?.map(item => item.value) || [])
      const associate = _.differenceBy(selectedOptions?.titles?.map(item => item.value),
        originalTitles.map(item => item.value) || [])

      if (disassociate.length > 0) {
        await APILandscapes.postDisassociate({ landscapes: [landscape?.id], titles: disassociate })
      }
      if (associate.length > 0) {
        await APILandscapes.postTitlesLandscapes({
          titles: associate,
          landscapes: [landscape?.id],
        })
      }

      if (landscape) {
        const response = await APILandscapes.putLandscape(landscape?.id, { name, status: selectedOptions?.status ? selectedOptions?.status[0].value : 'On' })
        if (typeof callback === 'function')
          callback(response.data)
      }
      toastsContext.addSuccessToast({
        title: 'Success',
        message: ""
      });
      onClose()
    }

    catch (e: any) {
      setIsLoading(false)
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete',
        message: (e as Error).toString()
      });
    }
  }

  const resetErrors = (key: string) => {
    !isEmpty(errors) ? delete errors[key] : setErrors(undefined)
  }

  const onChange = (options: EuiComboBoxOptionOption[], key: string) => {
    resetErrors(key)
    setSelectedOptions({
      ...selectedOptions,
      [key]: options
    })
  }

  useEffect(() => {
    getTitles();

    if (landscape) {
      setName(landscape.name)
      getTitlesByLandscape(landscape.id)
    }
  }, []);

  return (
    <EuiModal id="modal-add-to-landscapes" onClose={closeModal} initialFocus="[name=popswitch]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{hasLandscape ? `Edit Landscape` : `Create a new Landscape`}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody style={{ color: 'black' }}>
        <EuiForm error={errors} component="form">
          <EuiFormRow label="Name" isInvalid={errors?.name ? true : false} error={errors?.name} aria-required>
            <EuiFieldText
              placeholder="Name"
              name="name"
              autoFocus
              value={name}
              isInvalid={errors?.name}
              onChange={(e: any) => { setName(e.target.value); resetErrors(e.target.name) }}
              aria-label="Use aria labels when no actual label is in use"
            />
          </EuiFormRow>
          <EuiFormRow label="Status" className="text-sky-blue" >
            <EuiComboBox
              placeholder="Select status"
              options={HOptions.status}
              isClearable={false}
              onChange={(options: EuiComboBoxOptionOption[]) => onChange(options, 'status')}
              singleSelection={{ asPlainText: true }}
              selectedOptions={selectedOptions?.status}
            />
          </EuiFormRow>
          <EuiFormRow label="Titles">
            <EuiComboBox
              placeholder="Select one or more Titles"
              options={titlesOptions.data}
              isLoading={titlesOptions.isLoading}
              selectedOptions={selectedOptions?.titles}
              onChange={(options: EuiComboBoxOptionOption[]) => onChange(options, 'titles')}
              isClearable={true}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        <EuiButton isLoading={isLoading} type="submit" form="modalFormId" onClick={hasLandscape ? handlerUpdate : onSubmit} fill color={(errors && !isEmpty(errors)) ? 'danger' : 'primary'}>
          {hasLandscape ? "Save" : "Create"}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}

export default MLandscape;
