import styled from 'styled-components';

const GroupBadgeWrapper = styled.div`
  justify-content: center;
  .more,
  .euiAvatar {
    margin: .25rem 0 .25rem .25rem;
  }
`

const CircleMore = styled.div`

  display: flex;
  border-radius: 50%;
  background-color: transparent !important;
  cursor: pointer;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  background-size: cover;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  color: white;
  border: 1px solid white;

  &.m{
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 14.4px;
  }
`

const ListGroup = styled.div`
  display: flex;
  justify-content: left;
  flex-flow: column;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`

const ListItem = styled.div`
    display: flex;
    align-items: center;
    flex-flow: row;
    margin-bottom: 8px;
    .euiAvatar {
      margin-right: 5px;
    }
`

export { GroupBadgeWrapper, CircleMore, ListItem, ListGroup }