import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiProgress } from "@elastic/eui";

interface Props {
  value?: number
  max: number
}


const Progress: React.FC<Props> = ({ value = 0, max = 0 }) => (
  <EuiFlexGroup direction="column" gutterSize="none">
    <EuiFlexItem className="justify-center">
      <EuiProgress value={value} max={max} size="m" color="#3ad369" />
      <span className="text--center text--white" style={{ fontSize: '14px' }}>{max > 0 ?((value) / max * 100).toFixed(2) : '0.00'}%</span>
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default Progress;
