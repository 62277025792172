import React, { useEffect, useState, useContext, FC, useCallback, Fragment } from 'react';
import * as Yup from 'yup';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiButton,
  EuiFormRow,
  EuiFieldText,
  EuiForm,
  EuiDescriptionListDescription,
  EuiDescriptionList,
} from '@elastic/eui';
import debounce from 'lodash/debounce';
import _ from 'lodash';
import { APILandscapes, APITitles, APISites } from '../../services/api';
import { ToastsContext } from '../../modules/toast';
import { renderOption } from 'src/helpers/eui';
import { ILandscape } from 'src/@types/ILandscape';
import { ITitle } from 'src/@types/ITitle';
import HOptions from 'src/helpers/options';
import { DStatus } from 'src/helpers/dictionaries';
import { TRender } from 'src/@types/IGlobal';
import { ISite } from 'src/@types/ISite';
import { isEmpty } from 'src/utils';


export interface Props {
  onClose: () => void,
  item?: ITitle | ISite
  titles?: ITitle[]
  sites?: ISite[],
  callback?: (data: any) => void
  render?: TRender
}

interface ISelecteds {
  slug?: EuiComboBoxOptionOption[],
  status?: EuiComboBoxOptionOption[],
  landscapes?: EuiComboBoxOptionOption[]
}

interface IOptions {
  data: EuiComboBoxOptionOption[],
  isLoading: boolean
}

const MTitle: FC<Props> = (props) => {
  const { onClose, item, callback, render = 'edit', titles, sites } = props;
  const toastsContext = useContext(ToastsContext);
  const items = titles || sites || undefined

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [landscapesOptions, setLandscapesOptions] = useState<IOptions>({ data: [], isLoading: true });
  const [slugsOptions, setSlugsOptions] = useState<IOptions>({ data: [], isLoading: true });

  const status = item?.status && HOptions.site._status.includes(item?.status) ? item?.status : "On"

  const [selectedOptions, setSelectedOptions] = useState<ISelecteds | undefined>({
    status: item ? [{ value: status, label: DStatus[status] }] : [],
    slug: (item && (item as ITitle).slug) ? [{ value: (item as ITitle).slug, label: (item as ITitle).slug }] : [],
  })

  const hasTitle = item ? true : false

  const [slug, setSlug] = useState({
    name: '',
    type: '',
    release_date: '',
    isLoading: false
  })

  const [client, setClient] = useState<string>('')

  const [errors, setErrors] = useState<any>(undefined)

  const setAttributeByType = (render: TRender) => {

    switch (render) {
      case 'bulk':
      case 'add':
        if (sites) {
          return {
            title: 'Add Site(s) to Landscape(s)',
            label: 'Save',
            message: { success: 'Site(s) successfully added to Landscape(s)', fail: 'Unable to add site(s) to Landscape(s)' }
          }
        }
        return {
          title: 'Add Title(s) to Landscape(s)',
          label: 'Save',
          message: { success: 'Title(s) successfully added to Landscape(s)', fail: 'Unable to add Title(s) to Landscape(s)' }
        }
      case 'edit':
        return {
          title: 'Edit Title',
          label: 'Save',
          message: { success: 'Title changed successfully', fail: 'Unable to edit Title' }
        }
      case 'create':
        return {
          title: 'Create a new Title',
          label: 'Create',
          message: { success: 'Title created successfully', fail: 'Unable to create Title' }
        }
      default:
        return {
          title: '',
          label: '',
          message: { success: 'Title created successfully', fail: 'Unable to create Title' }
        }
    }
  }

  const { label, title, message } = setAttributeByType(render);

  const getSlugs = async (slug: string) => {
    setSlugsOptions({ ...slugsOptions, isLoading: true })
    try {
      const response = await APITitles.getSlugs(slug);
      setSlugsOptions({ data: response.data.map((slug: string) => ({ label: slug, value: slug })), isLoading: false })
    }
    catch (e: any) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete search slugs',
        message: (e as Error).toString()
      });
    }
  }

  const getSlug = async (sl: string) => {
    setSlug({ ...slug, isLoading: true })
    try {
      const response = await APITitles.getSlug(sl)

      setSlug({ ...response.data, isLoading: false })
    } catch (e: any) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete search slug',
        message: (e as Error).toString()
      });
    }
  }

  const getLandscapes = async (page = 1, pageSize = Number.MAX_SAFE_INTEGER) => {
    setLandscapesOptions({ ...landscapesOptions, isLoading: true })
    try {
      const landscapes = await APILandscapes.fetchLandscapes({ page, pageSize });

      setLandscapesOptions({
        data: landscapes.results.map((landscape) => ({
          label: landscape.name,
          value: landscape.id
        })),
        isLoading: false
      })
    } catch (e) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete getLandscapes',
        message: (e as Error).toString()
      });
    }
  }

  const debounceSearch = useCallback(
    debounce((slug: string) => getSlugs(slug?.toLowerCase()), 1000),
    []
  );

  const handleSearch = (searchValue: string) =>
    debounceSearch(searchValue)

  const handleSlugs = (options: EuiComboBoxOptionOption[]) => {
    resetErrors('slug')
    setSelectedOptions({ ...selectedOptions, slug: options })

    if (options.length > 0 && options[0]?.value && !item) {
      getSlug(options[0]?.value?.toString())
    }
  }

  const cleanModal = () => {
    setSelectedOptions(undefined)
  }

  const closeModal = (event?: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    cleanModal();
    if (typeof onClose === 'function') onClose();
  }

  const onSubmit = async (event?: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    setIsLoading(true)
    try {
      const landscapesArray = selectedOptions?.landscapes?.map(item => item.value) || undefined
      const sitesArray = sites?.map(item => item.id) || undefined
      const titlesArray = titles?.map(item => item.id) || undefined

      switch (render) {
        case 'create':
        case 'edit':
          const fields = {
            slug: selectedOptions?.slug ? selectedOptions?.slug[0]?.value : '',
            landscapes: selectedOptions?.landscapes?.map(item => item.value) || [],
            client,
            status: selectedOptions?.status ? selectedOptions?.status[0]?.value : 'On'
          }

          if (render === 'create') {
            let schema = Yup.object().shape({
              slug: Yup.string().required(),
            });

            await schema.validate(fields, {
              abortEarly: false,
            })
          }

          if (render === 'edit' && item) {
            const fields = {
              client,
              status: selectedOptions?.status ? selectedOptions?.status[0]?.value : 'On'
            }
            const disassociate = _.differenceBy(item?.landscapes.map(item => item.id) || [],
              selectedOptions?.landscapes?.map(item => item.value) || [])
            const associate = _.differenceBy(selectedOptions?.landscapes?.map(item => item.value),
              item?.landscapes.map(item => item.id) || [])

            if (disassociate.length > 0) {
              await APILandscapes.postDisassociate({ landscapes: disassociate, titles: [item?.id] })
            }
            if (associate.length > 0) {
              await APILandscapes.postTitlesLandscapes({
                titles: [item?.id], // titleId original
                landscapes: associate,
              })
            }

            const response = await APITitles.putTitle(item.id, fields)

            if (typeof callback === 'function' && response)
              callback(response.data)
          }

          if (render === 'create') {
            await APITitles.postTitle(fields)
            window.location.href = '/#/titles'
          }

          break;

        case 'add':
          let disassociate: any = []
          let associate: any = []

          if (selectedOptions?.landscapes && selectedOptions?.landscapes.length < 1) {
            setErrors({ landscapes: "Field is required" });
            setIsLoading(false)
            return;
          }

          if (sites?.length === 1) {
            disassociate = _.differenceBy(sites[0].landscapes.map(item => item.id) || [],
              selectedOptions?.landscapes?.map(item => item.value) || [])
            associate = _.differenceBy(selectedOptions?.landscapes?.map(item => item.value),
              sites[0]?.landscapes.map(item => item.id) || [])

            let response = undefined

            if (disassociate.length > 0) {
              response = await APISites.postSiteLandscapes({ landscapeIds: disassociate, site: sites[0].id })
            }
            if (associate.length > 0) {
              response = await APILandscapes.postSitesLandscapes({
                sites: [sites[0].id],
                landscapes: associate,
              })
            }

            if (typeof callback === 'function' && response)
              callback(response.data)
          }

          if (item) {
            disassociate = _.differenceBy(item?.landscapes.map(item => item.id) || [],
              selectedOptions?.landscapes?.map(item => item.value) || [])
            associate = _.differenceBy(selectedOptions?.landscapes?.map(item => item.value),
              item?.landscapes.map(item => item.id) || [])

            if (disassociate.length > 0) {
              await APILandscapes.postDisassociate({ landscapes: disassociate, titles: [item?.id] })
            }
            if (associate.length > 0) {
              await APILandscapes.postTitlesLandscapes({
                titles: [item?.id], // titleId original
                landscapes: associate,
              })
            }

            const response = await APITitles.putTitle(item?.id, { client, status: selectedOptions?.status ? selectedOptions?.status[0].value : 'On' })

            if (typeof callback === 'function')
              callback(response.data)
          }
          break;
        case 'bulk':
          if (titles) {
            if (!landscapesArray || landscapesArray.length < 1) {
              setErrors({ landscapes: "Field is required" })
              setIsLoading(false)
              return
            }
            await APILandscapes.postTitlesLandscapes({
              titles: titlesArray,
              landscapes: landscapesArray,
            })
          }

          if (sites) {
            if (!landscapesArray || landscapesArray.length < 1) {
              setErrors({ landscapes: "Field is required" })
              setIsLoading(false)
              return
            }
            await APILandscapes.postSitesLandscapes({
              sites: sitesArray,
              landscapes: landscapesArray,
            })
          }

          if (typeof callback === 'function')
            callback(true)

          break;

        default:
          break;
      }

      toastsContext.addSuccessToast({
        title: 'Success',
        message: message.success
      });

      closeModal();
      setIsLoading(false)
    }
    catch (e: any) {
      setIsLoading(false)

      if (e instanceof Yup.ValidationError) {
        const errorMessages = {}
        e.inner.forEach((error) => {
          // @ts-ignore
          errorMessages[error.path] = error.message
        })
        setErrors(errorMessages)
        return;
      }

      let msg: string = (e as Error).toString()

      switch (e?.response?.status) {
        case 409:
          msg = "Title already exists"
          setErrors({ ...errors, slug: msg })
          break;

        default:
          break;
      }

      toastsContext.addErrorToast({
        title: message.fail,
        message: msg
      });
    }
  }



  const resetErrors = (key: string) => {
    !isEmpty(errors) ? delete errors[key] : setErrors(undefined)
  }

  const onChange = (options: EuiComboBoxOptionOption[], key: string) => {
    resetErrors(key)
    setSelectedOptions({
      ...selectedOptions,
      [key]: options
    })
  }

  useEffect(() => {
    getLandscapes();

    if (item) {
      // @ts-ignore
      if (render !== 'add' || render !== 'bulk') {
        setClient((item as ITitle).client || '')
        handleSlugs([{ value: (item as ITitle).slug, label: (item as ITitle).slug }])
        setSlugsOptions({ ...slugsOptions, isLoading: false })
        setSlug({
          type: (item as ITitle).type,
          release_date: (item as ITitle).release_date,
          name: (item as ITitle).name,
          isLoading: false
        })
      }

      setSelectedOptions({
        ...selectedOptions, landscapes: item.landscapes.map((item: ILandscape) => {
          return { value: item.id, label: item.name }
        })
      })
    } else {
      if (sites && sites.length === 1 && (render === 'add' || render === 'bulk')) {
        setSelectedOptions({
          ...selectedOptions, landscapes: sites[0].landscapes.map((item: ILandscape) => {
            return { value: item.id, label: item.name }
          })
        })
        return
      }
      if (render === 'create')
        getSlugs("")
    }
  }, []);

  return (
    <EuiModal id="modalAddTitle" onClose={closeModal} initialFocus="[name=popswitch]" >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{title}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody style={{ color: 'black' }}>
        <EuiForm error={errors} component="form">
          {(render === 'edit' || render === 'create') &&
            <Fragment>
              <EuiFormRow label="Slug" aria-required isInvalid={errors?.slug} error={errors?.slug} >
                <EuiComboBox
                  renderOption={renderOption}
                  placeholder="Search or select the slug"
                  async
                  onSearchChange={handleSearch}
                  isLoading={slugsOptions.isLoading}
                  isDisabled={hasTitle}
                  singleSelection={{ asPlainText: true }}
                  options={slugsOptions.data}
                  selectedOptions={selectedOptions?.slug}
                  onChange={handleSlugs}
                  isInvalid={errors?.slug}
                />
              </EuiFormRow>
              <EuiFormRow label="Name" aria-readonly isInvalid={errors?.name ? true : false} error={errors?.name} aria-required className="text-sky-blue" >
                <EuiFieldText
                  placeholder="Name"
                  name="name"
                  isLoading={slug.isLoading}
                  value={slug?.name}
                  disabled
                />
              </EuiFormRow>
              <EuiFormRow label="Type" aria-readonly className="text-sky-blue">
                <EuiFieldText
                  placeholder="Type"
                  value={slug?.type}
                  name="type"
                  isLoading={slug.isLoading}
                  disabled
                  aria-label="Use aria labels when no actual label is in use"
                />
              </EuiFormRow>
              <EuiFormRow label="Release date" aria-disabled="true" aria-readonly className="text-sky-blue">
                <EuiFieldText
                  placeholder="Release date"
                  name="release_date"
                  value={slug?.release_date}
                  isLoading={slug.isLoading}
                  disabled
                />
              </EuiFormRow>
              <EuiFormRow label="Status" className="text-sky-blue" >
                <EuiComboBox
                  placeholder="Select status"
                  isClearable={false}
                  options={HOptions.status}
                  onChange={(options) => onChange(options, 'status')}
                  singleSelection={{ asPlainText: true }}
                  selectedOptions={selectedOptions?.status}
                />
              </EuiFormRow>
              <EuiFormRow label="Client" aria-owns="opcional">
                <EuiFieldText
                  placeholder="Client name"
                  name="client"
                  value={client}
                  onChange={(e) => setClient(e.target.value)}
                  aria-label="Use aria labels when no actual label is in use"
                />
              </EuiFormRow>
            </Fragment>
          }
          {(items && (render === "add" || render === "bulk")) &&
            <EuiFormRow label="Selected Items">
              <EuiDescriptionList>
                {items?.map((item: any, index: number) => {
                  return (
                    <EuiDescriptionListDescription>{item?.name || item.domain}</EuiDescriptionListDescription>
                  )
                })
                }
              </EuiDescriptionList>
            </EuiFormRow>
          }
          <EuiFormRow label="Landscapes" isInvalid={errors?.landscapes} error={errors?.landscapes} >
            <EuiComboBox
              renderOption={renderOption}
              placeholder="Search and select one or more landscapes"
              isLoading={landscapesOptions.isLoading}
              options={landscapesOptions.data}
              selectedOptions={selectedOptions?.landscapes}
              onChange={(options) => onChange(options, 'landscapes')}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        <EuiButton isLoading={isLoading} type="submit" form="modalFormId" onClick={onSubmit} fill color={(errors && !isEmpty(errors)) ? 'danger' : 'primary'}>
          {label}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}

export default MTitle;
