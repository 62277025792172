import { FC } from 'react'
import {
  EuiComboBox, EuiComboBoxOptionOption
} from '@elastic/eui'

interface Props {
  id?: string
  isLoading?: boolean
  onKeyUp?: (event: KeyboardEvent) => void,
  onChange?: (options: EuiComboBoxOptionOption<string | number | string[] | undefined>[]) => void
  selectedOptions?: EuiComboBoxOptionOption[]
  options?: EuiComboBoxOptionOption[]
  setSelecteds?: (searchValue: EuiComboBoxOptionOption) => void
  onSearchChange?: (searchValue: string, hasMatchingOptions?: boolean | undefined) => void
}

const SearchInputAdvanced: FC<Props> = ({ id = "search", onChange, onSearchChange, isLoading, onKeyUp, selectedOptions, setSelecteds, options }) => {

  const onCreateOption = (searchValue: string, options: Array<EuiComboBoxOptionOption>) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }
    if (typeof setSelecteds === 'function')
      setSelecteds({ label: searchValue, value: searchValue })
  };

  return (
    <EuiComboBox
      className="search"
      async
      delimiter=" "
      onCreateOption={onCreateOption}
      sortMatchesBy="startsWith"
      placeholder="All"
      isClearable
      onChange={onChange}
      onSearchChange={onSearchChange}
      selectedOptions={selectedOptions}
      options={options}
      isLoading={isLoading}
    />
  )
}


export default SearchInputAdvanced