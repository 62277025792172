import { EuiButton, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiComboBoxOptionOption } from '@elastic/eui';
import React from 'react';
import BGroup from '../Badges/BGroup';

interface Props {
  title: string
  onClose: () => void
  rows: Array<{ value: any | EuiComboBoxOptionOption[], label: any }> | null
}

const MDetails: React.FC<Props> = ({ onClose, rows, title }) => {
  return (
    <EuiModal id="modalDetails" onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{title}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        {rows?.map((row, i) => (
          <div className="row">
            <b>
              {row.label}
            </b>
            {Array.isArray(row.value) ?
              <BGroup items={row.value} isExpaned={true} isAvatar={false} maxBadges={5} />
              :
              <p>
                {row.value || '-'}
              </p>
            }

          </div>
        ))}
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton onClick={onClose}>
          Close
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}

export default MDetails;