import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { FetchStatus } from '../@types';
import { apiBaseURL } from '../services/api';

interface IAuthData {
    token: string | null;
    user: {
        id: string;
        name: string;
    } | null;
}

type IUseAuth = IAuthData;

const AuthContext = createContext<IUseAuth>({
    token: null,
    user: null,
});

const AuthProvider: React.FC = ({ children }) => {
    const storageSlug = 'landscape-manager:auth';
    const userDirectoryUri = window.EXTERNAL_URI_USERDIRECTORY;

    const [authStatus, setAuthStatus] = useState<FetchStatus>(
        'loading'
    );
    const [authData] = useState<IAuthData>(() => {
        const emptyData = {
            token: null,
            user: null,
        };
        const storageData = sessionStorage.getItem(storageSlug);
        if (!storageData) return emptyData;
        return JSON.parse(storageData) as IAuthData;
    });

    const validateToken = async (token: string | null) => {
        // TODO: validate some token
        return !!token;
    };

    useEffect(() => {
        if (!authData) return;
        const query = new URLSearchParams(window.location.search);
        const hash = query.get('hash');
        if (!hash) {
            validateToken(authData.token).then((isValid) => {
                if (!isValid) {
                    const currentUrl = window.location.href;
                    window.location.replace(`${userDirectoryUri}?callback=${currentUrl}`);
                }
                else setAuthStatus('success');
            });
            return;
        }
        const formData = new FormData();
        formData.append('hash', hash);
        formData.append('app', 'videoidapp');
        axios.post(`${userDirectoryUri}/auth_hash/`, formData, {
            withCredentials: false,
        })
            .then(({ data }) => {
                // fake token for now
                const userData: IAuthData = {
                    token: 'ABCDEF123123123',
                    user: {
                        id: data.user_id,
                        name: data.first_name,
                    },
                };
                sessionStorage.setItem(storageSlug, JSON.stringify(userData));
                window.location.replace(window.location.origin + window.location.pathname);
                return;
            })
            .catch(() => setAuthStatus('error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData]);

    return (
        <AuthContext.Provider value={authData}>
            {authStatus === 'error' ? (
                'Error accessing UserDirectory'
            ) : authStatus === 'loading' ? (
                'Checking your login...'
            ) : (
                children
            )}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);
    return context;
};

export { AuthProvider, useAuth };
