import { EuiButton, EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLink, EuiToolTip } from '@elastic/eui';
import React, { Fragment } from 'react';
import { ILandscape } from 'src/@types/ILandscape';
import { minThreeDigits } from 'src/services/utils';

interface Props {
  landscapes: ILandscape[], readonly: boolean, onMoreClick?: () => void
}

const RowLandscapes: React.FC<Props> = ({ landscapes, readonly = false, onMoreClick }) => {
  const InsertButton = () => {
    return (
      <EuiFlexItem grow={false} >
        <EuiLink
          color="text"
          onClick={() => typeof onMoreClick === 'function' ? onMoreClick() : null}
        >
          <EuiIcon type="visMapRegion" /> Add to landscapes
        </EuiLink>
      </EuiFlexItem>
    )
  }

  const PlusButton = () => {
    return (
      <EuiToolTip position="top" content="Add to landscape">
        <EuiButtonIcon
          color="success"
          style={{ color: '#3ad369', border: 'solid 1px transparent', borderColor: '#3ad369', borderRadius: '4px' }}
          onClick={() => typeof onMoreClick === 'function' ? onMoreClick() : null}
          iconType="plus"
          aria-label="Next"
        />
      </EuiToolTip>
    )
  }

  return (
    <EuiFlexGroup className="align-center m-l-30px" gutterSize="none" justifyContent="flexStart" >
      {!readonly && (!landscapes || landscapes.length < 1) && <InsertButton />}
      {landscapes.length > 2 ?
        <Fragment>
          <EuiButton
            color="secondary"
            iconType="visMapRegion"
            iconSide="left"
            className="m-r-1"
          >
            {minThreeDigits(landscapes.length)}
          </EuiButton>
          {!readonly &&
            <PlusButton />
          }
        </Fragment>
        :
        <Fragment>
          {landscapes?.map((landscape, i) =>
            <EuiButton key={i} color="secondary" className="m-r-1">{landscape.name}</EuiButton>).filter(el => el !== null && typeof el !== 'undefined')}
          {(!readonly && landscapes.length !== 0) &&
            <PlusButton />
          }
        </Fragment>
      }
    </EuiFlexGroup>
  )
}

export default RowLandscapes