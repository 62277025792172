
import styled from 'styled-components';

const DataPickerOptionsWrapper = styled.div`
  align-items: center;
  flex-flow: row;
  transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
  .euiDatePicker.euiDatePicker--shadow.euiDatePicker--inline .react-datepicker {
    margin-left: 20px;
  }
  .euiDatePickerRange {
    max-width: inherit;
  }
  .euiDatePickerRange__delimeter {
    padding: 5px;
    align-items: center;
    display: flex;
    height: 80px;
  }
  .react-datepicker__month-container {

  }
  .react-datepicker__month {
    margin: 0 16px 0px 16px;
  }
  .euiDatePickerRange.recurrence {
    .euiDatePickerRange__delimeter {
      display: none;
    } 
  }
  .euiComboBoxPill--plainText {
    text-transform: capitalize;
  }
`
const DataPickerBody = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: row;
  transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
`
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
  svg {
    margin-right: 5px;
  }
`
const DataPickerRange = styled.div`
  display: flex;
  align-items: normal;
  flex-flow: row;
  transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
  margin-bottom: 10px;
`
const Recur = styled.div`
  display: flex;
  flex-direction: column;
  width: 10vw;
  
  transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
  .euiComboBox__inputWrap {
    margin-bottom: 0.5rem;
  }
`

export { DataPickerOptionsWrapper, DataPickerBody, Recur, DataPickerRange, Row }