import React, { useEffect, useState, useContext, FC } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiButton, EuiFieldText,
  EuiFormRow, EuiForm
} from '@elastic/eui';
import { APICategories, APILandscapes, APISites } from '../../services/api';
import { ToastsContext } from '../../modules/toast';
import { renderOption } from 'src/helpers/eui';
import HOptions from 'src/helpers/options';
import { ISite } from 'src/@types/ISite';
import { DStatus } from 'src/helpers/dictionaries';
import { isEmpty } from 'src/utils';

interface ISelecteds {
  category: EuiComboBoxOptionOption[],
  status: EuiComboBoxOptionOption[],
  classification: EuiComboBoxOptionOption[],
  language: EuiComboBoxOptionOption[],
  landscapes: EuiComboBoxOptionOption[]
}

export interface Props {
  site?: ISite,
  onClose: () => void,
  callback?: Function
}

interface IOptions {
  data: EuiComboBoxOptionOption[],
  isLoading: boolean
}

const MSite: FC<Props> = ({ onClose, callback, site }) => {
  const toastsContext = useContext(ToastsContext);

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [categoriesOptions, setCategoriesOptions] = useState<IOptions>({ data: [], isLoading: true })
  const [landscapesOptions, setLandscapesOptions] = useState<IOptions>({ data: [], isLoading: true })
  const originalLandscapes = site?.landscapes.map(item => ({ value: item.id, label: item.name })) || [];

  const [selectedOptions, setSelectedOptions] = useState<ISelecteds>({
    category: site?.categories.map(item => ({ value: item.id, label: item.name })) || [],
    // @ts-ignore
    status: (site && site.status && HOptions.site._status.includes(site.status)) ? [{ value: site.status, label: DStatus[site.status] }] : [],
    // @ts-ignore
    classification: site ? [{ value: site.classification, label: site.classification }] : [],
    language: site ? [{ value: site.language[0], label: site.language[0] }] : [],
    landscapes: site?.landscapes.map(item => ({ value: item.id, label: item.name })) || [],
  })

  const [redirect, setRedirect] = useState(site?.redirect_url ? site?.redirect_url : "")
  const [errors, setErrors] = useState<any>(undefined)

  const [domains, setDomains] = useState<EuiComboBoxOptionOption[] | undefined>(site ? [{ value: site.domain, label: site.domain }] : undefined)

  const closeModal = (event?: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    if (typeof onClose === 'function') onClose();
  }

  const getCategories = async (query = '', page = 1, pageSize = Number.MAX_SAFE_INTEGER) => {
    setLandscapesOptions({ ...categoriesOptions, isLoading: true })
    try {
      const response = await APICategories.getCategories({ page, pageSize });

      setCategoriesOptions({
        data: response.results.map((categorie) => ({
          label: categorie.name,
          value: categorie.id
        })),
        isLoading: false
      })

    }
    catch (e) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete getCategories',
        message: (e as Error).toString()
      });
    }
  }

  const getLandscapes = async (page = 1, pageSize = Number.MAX_SAFE_INTEGER) => {
    setLandscapesOptions({ ...landscapesOptions, isLoading: true })
    try {
      const response = await APILandscapes.fetchLandscapes({ page, pageSize });

      setLandscapesOptions({
        data: response.results.map((landscape) => ({
          label: landscape.name,
          value: landscape.id
        })),
        isLoading: false
      })

    } catch (e) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete getLandscapes',
        message: (e as Error).toString()
      });
    }
  }

  const onChange = (options: EuiComboBoxOptionOption[], key: string) => {
    setSelectedOptions({
      ...selectedOptions,
      [key]: options
    })
  }

  const onCreateOption = (searchValue: string, options: Array<EuiComboBoxOptionOption>) => {

    resetErrors('domain')
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    if (!normalizedSearchValue)
      return;

    if (domains) {
      // @ts-ignore
      setDomains(domains => [...domains, { label: searchValue, value: searchValue }]);
      return
    }

    setDomains([{ label: searchValue, value: searchValue }]);

  };

  const onSubmit = async () => {
    setIsLoading(true)

    try {
      const fields = {
        domain: (domains && domains?.length > 0) ? domains.map(item => item.value) : undefined,
        status: selectedOptions.status[0]?.value?.toString(),
        language: selectedOptions.language[0]?.value,
        classification: selectedOptions.classification[0]?.value,
        category: selectedOptions.category.map(item => item.value),
        landscapes: selectedOptions.landscapes.map(item => item.value),
        redirect_url: redirect
      }



      let schema = Yup.object().shape({
        domain: Yup.array().required('Field is required'),
        status: Yup.string(),
        language: Yup.string(),
        classification: Yup.string(),
        category: Yup.array(),
        landscapes: Yup.array(),
        redirect_url: Yup.string()
      });

      await schema.validate(fields, {
        abortEarly: false,
      })

      if (site) {
        const disassociate = _.differenceBy(originalLandscapes?.map(item => item.value) || [],
          selectedOptions.landscapes.map(item => item.value))

        const associate = _.differenceBy(selectedOptions.landscapes.map(item => item.value),
          originalLandscapes?.map(item => item.value) || [])

        if (associate.length > 0)
          await APILandscapes.postSitesLandscapes({
            sites: [site.id],
            landscapes: associate,
          })

        if (disassociate.length > 0)
          await APISites.postSiteLandscapes({ site: site.id, landscapeIds: disassociate })

        switch (fields.status) {
          case "Active":
            fields.status = "On"
            break
          case "Inactive":
            fields.status = "Off"
        }

        const response = await APISites.putSite(site.id, fields)

        if (typeof callback === 'function')
          callback(response.data)
      } else {
        await APISites.postSites(fields)
        window.location.href = '/#/sites'
      }

      toastsContext.addSuccessToast({
        title: 'Successfully registered!',
        message: ""
      });

      closeModal();

      setIsLoading(false)
    }
    catch (e: any) {
      setIsLoading(false)
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {}
        e.inner.forEach((error) => {
          // @ts-ignore
          errorMessages[error.path] = error.message
        })
        setErrors(errorMessages)
        return;
      }

      toastsContext.addErrorToast({
        title: 'Couldn\'t complete submit',
        message: (e as Error).toString()
      });
    }
  }

  const resetErrors = (key: string) => {
    !isEmpty(errors) ? delete errors[key] : setErrors(undefined)
  }

  useEffect(() => {
    getCategories()
    getLandscapes()
  }, [])

  return (
    <EuiModal id="modal-add-to-landscapes" onClose={closeModal} initialFocus="[name=popswitch]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{site ? 'Edit Site' : 'Create a new(s) Site(s)'}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody style={{ color: 'black' }}>
        <EuiForm component="form">
          <EuiFormRow label="Domain" aria-required isInvalid={errors?.domain} error={errors?.domain} >
            {site ?
              <span className="">{site.domain}</span>
              :
              <EuiComboBox
                placeholder="Select one or more Domain"
                onCreateOption={onCreateOption}
                isClearable
                autoFocus
                sortMatchesBy="startsWith"
                // renderOption={renderOption}
                selectedOptions={domains}
                delimiter=" "
                onChange={(options: EuiComboBoxOptionOption[]) => { setDomains(options); resetErrors('domain') }}
              />
            }
          </EuiFormRow>
          <EuiFormRow label="Status" className="text-sky-blue" >
            <EuiComboBox
              placeholder="Select status"
              isClearable={false}
              options={(site || domains?.length === 1) ? HOptions.site.status : HOptions.site.status.filter(item => item.value !== "Redirect")}
              onChange={(options: EuiComboBoxOptionOption[]) => onChange(options, 'status')}
              singleSelection={{ asPlainText: true }}
              selectedOptions={selectedOptions.status}
            />
          </EuiFormRow>
          {((site || domains?.length === 1) && selectedOptions?.status[0]?.value === "Redirect") &&
            <EuiFormRow label="Redirect" aria-required={true} className="text-sky-blue" >
              <EuiFieldText
                name="redirect"
                placeholder="Domain"
                value={redirect || ""}
                onChange={(e) => setRedirect(e.target.value)}
              />
            </EuiFormRow>
          }
          {
            [
              {
                id: 'category',
                label: "Category",
                placeholder: "Select category",
                options: categoriesOptions.data,
                selectedOptions: selectedOptions.category,
                singleSelection: false,
                required: true,
                className: 'text-sky-blue',
                error: errors?.category,
                isLoading: categoriesOptions.isLoading,
                isCrearable: true
              },
              {
                id: 'language',
                label: "Language",
                placeholder: "Select language",
                options: HOptions.languages,
                selectedOptions: selectedOptions.language,
                singleSelection: { asPlainText: true },
                required: true,
                className: 'text-sky-blue',
                error: errors?.language,
                isCrearable: false
              },
              {
                id: 'classification',
                label: "Classification",
                placeholder: "Select classification",
                options: HOptions.site.classifications,
                selectedOptions: selectedOptions.classification,
                singleSelection: { asPlainText: true },
                required: true,
                className: 'text-sky-blue',
                error: errors?.classification,
                isCrearable: false
              },
              {
                id: 'landscapes',
                label: "Landscapes",
                placeholder: "Select one or more Landscapes",
                options: landscapesOptions.data,
                selectedOptions: selectedOptions.landscapes,
                singleSelection: false,
                isLoading: landscapesOptions.isLoading,
                error: errors?.landscapes,
                isCrearable: true
              }
            ].map((item, index) => (
              <EuiFormRow
                key={index}
                isInvalid={item.error} error={item.error}
                label={item.label}
                aria-required={item.required}
                className={item.className || ''}
              >
                <EuiComboBox
                  isLoading={item.isLoading}
                  placeholder={item.placeholder}
                  isClearable={item.isCrearable}

                  options={item.options}
                  renderOption={renderOption}
                  onChange={(options: EuiComboBoxOptionOption[]) => onChange(options, item.id)}
                  selectedOptions={item.selectedOptions}
                  singleSelection={item.singleSelection}
                />
              </EuiFormRow>
            ))
          }
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton onClick={closeModal}>Cancel</EuiButton>
        <EuiButton onClick={onSubmit} className="euiButton--fill" isLoading={isLoading} color={(errors && !isEmpty(errors)) ? 'danger' : 'primary'}>{site ? 'Save' : 'Create'}</EuiButton>
      </EuiModalFooter>
    </EuiModal >
  )
}

export default MSite;
