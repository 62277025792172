import axios from "axios";
import { apiBaseURL } from ".";
import { ICategoryResult } from "../../@types/ICategory";
import { IPaginationFetch } from '../../@types/IGlobal';

const APICategories = {
  async getCategories({ pageSize = 10, page = 1 }: IPaginationFetch): Promise<ICategoryResult> {
    const response: Response = await fetch(`${apiBaseURL}/categories?page=${page}&page_size=${pageSize}`);
    const data: ICategoryResult = await response.json();
    return data;
  }
}

export default APICategories;