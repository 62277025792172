import React from 'react'
import { EuiButtonEmpty } from "@elastic/eui";

interface Props {
  total: number,
  onClick: () => void
  type: string
}


const BulkSelecteds: React.FC<Props> = ({ total, onClick, type }) => {
  const hasSelecteds = total > 0
  return (
    <EuiButtonEmpty
      color="ghost"
      className={`bulkSelecteds--selecteds ${hasSelecteds ? "active" : ""}`}
      onClick={() => typeof onClick === 'function' ? onClick() : null}
    >
      {hasSelecteds && `Bulk action on ${total} ${type} `}
    </EuiButtonEmpty>
  )
}

export default BulkSelecteds