import { useEffect, useState, Fragment, useContext, useRef, useCallback } from 'react';
import { formatDate } from '../../services/format';
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiBadge,
  EuiLink,
  EuiIcon,
  EuiBasicTableColumn,
  EuiTableSortingType,
  Direction,
  Pagination,
  EuiTableSelectionType,
  CriteriaWithPagination,
  EuiComboBoxOptionOption,
  EuiTextAlign,
  EuiButtonEmpty, EuiCheckboxGroupOption
} from '@elastic/eui';
import { RiFilter2Fill } from 'react-icons/ri'
import { ISite, ISiteFilters, ISiteResult, ISitesFiltersString, ISitesPagination, TSiteFilter } from '../../@types/ISite';
import { APISites } from '../../services/api';
import { ToastsContext } from '../../modules/toast';
import debounce from 'lodash/debounce';
import _ from 'lodash'
import { ICategory } from '../../@types/ICategory';
import PopoverCharts from '../../components/Popovers/PopoverChart';
import { HBreadcrumbs, HTable } from '../../helpers';
import BulkSelecteds from '../../components/Bulks/BulkSelecteds';
import BulkExport from '../../components/Bulks/BulkExport';
import SearchInput from '../../components/SearchInput';
import { AddLandscapesButton } from '../Landscapes'
import { PageTemplateTable } from '../';
import BGroup from '../../components/Badges/BGroup';
import FSearch from 'src/components/Flyouts/FSearch';
import { IDict, TRender } from 'src/@types/IGlobal';
import MSite from 'src/components/Modals/MSite';
import FFSites from 'src/components/Flyouts/FFilters/FFSites';
import BFilter from 'src/components/Badges/BFilter';
import MDetails from 'src/components/Modals/MDetails';
import { DCategoryColor, DStatus } from 'src/helpers/dictionaries';
import { RowLandscapes } from 'src/components/Rows';
import MTitle from 'src/components/Modals/MTitle';
import HOptions from 'src/helpers/options';
import { ImFileText2 } from 'react-icons/im';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import PopoverOptions from 'src/components/Popovers/PopoverOptions';

//* Interfaces **//

const langFlag: IDict = {
  'pt-br': 'br',
  'en': 'us',
  'es': 'es',
  'in': 'in'
}

interface ILoading {
  landscapes: boolean,
  categories: boolean,
  store: boolean,
  export: boolean,
}

const Sites = () => {
  const tableRef = useRef<EuiBasicTable>(null);
  const toastsContext = useContext(ToastsContext);
  const [loading, setLoading] = useState<ILoading>({
    landscapes: false,
    categories: false,
    store: true,
    export: false
  });

  const [store, setStore] = useState<ISiteResult>({
    results: [],
    count: 0
  });

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(HTable.pageSize);
  const [sortField, setSortField] = useState<keyof ISite>('created_at');
  const [sortDirection, setSortDirection] = useState<Direction>('asc');
  const [selectedItems, setSelectedItems] = useState<ISite[]>([]);

  const [sites, setSites] = useState<ISite[]>([])
  const [query, setQuery] = useState<string>("")
  const [csv, setCsv] = useState([])
  const [filters, setFilters] = useState<ISiteFilters | undefined>()
  const selectedFilters: ISitesFiltersString | undefined = filters ? {
    landscapes: filters.landscapes?.map((item) => item.value).toString() || undefined,
    status: filters.status?.map((item) => item.value).toString() || undefined,
    categories: filters.categories?.map((item) => item.value).toString(),
    classifications: filters.classifications ? Object.keys(filters.classifications).map(key => key).toString() : undefined,
    languages: filters.languages?.map((item) => item.value).toString(),
    month: filters.month ? (filters.month as EuiComboBoxOptionOption)?.value?.toString() : undefined,
    visits_sum_lower: filters.visits && filters.visits?.length > 0 ? filters.visits[0].toString() : undefined,
    visits_sum_upper: filters.visits && filters.visits?.length > 0 ? filters?.visits[1].toString() : undefined
  } : undefined

  const [item, setItem] = useState<ISite | null>(null);

  const hasSelecteds = selectedItems.length !== 0


  //** Flyout States */

  const [render, setRender] = useState<TRender>(null)



  const rows = item ? [
    {
      label: 'Id',
      value: item.id
    },
    {
      label: 'Domain',
      value: item.domain
    },
    {
      label: 'Status',
      value: item.status
    },
    {
      label: 'Language',
      value: item.language
    },
    {
      label: 'Classification',
      value: item.classification
    },
    {
      label: 'Crowler status',
      value: item.crawler_status
    },
    {
      label: 'Last new link collected',
      value: formatDate(item.last_new_link_collected, 'dobLong')
    },
    {
      label: 'Create at',
      value: formatDate(item.created_at, 'dobLong')
    },
    {
      label: 'Redirected',
      value: item.redirect_url
    },
    {
      label: 'Categories',
      value: item.categories.map(item => item.name)
    },
    {
      label: 'Landscapes',
      value: item.landscapes.map(item => item.name)
    },
    {
      label: 'Observations',
      value: item.obs
    },
  ] : null


  const columns: EuiBasicTableColumn<ISite>[] = [
    {
      field: "domain",
      name: 'Domain',
      truncateText: true,
      sortable: true,
      align: "left",
      className: "cellWithRightBorder sites",
      mobileOptions: {
        render: (item: ISite) => (
          <EuiLink href={`http://${item.domain}`} target="_blank">
            {item.domain}
          </EuiLink>
        ),
        header: false,
      },
      render: (domain: string, row: ISite) => {
        return (
          <EuiFlexGroup direction="column" gutterSize="none" className="p-15">
            <EuiFlexItem>
              <EuiLink href={`http://${domain}`} target="_blank" >
                {domain}
              </EuiLink>
              <EuiFlexItem grow={false} className={`status ${HOptions.site._status.includes(row.status) ? DStatus[row.status] : ''}`}>
                {HOptions.site._status.includes(row.status) ? DStatus[row.status] : "-"}
              </EuiFlexItem>
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'categories',
      name: 'Categories',
      align: 'center',
      sortable: true,
      render: (categories: ICategory[]) => (
        //@ts-ignore
        <BGroup items={categories.map(item => item.name)} colors={DCategoryColor} title="Categories" />
      )
    },
    {
      field: 'language',
      name: 'LANGUAGE',
      align: 'center',
      render: (language: string) => <>
        <EuiFlexGroup direction="column" className="p-x-3 h-cell" gutterSize="none">
          <EuiFlexItem className="justify-center align-center">
            <span className={`fp ${langFlag[language] || language}`}></span>
            {language}
          </EuiFlexItem>
        </EuiFlexGroup>
      </>,
      sortable: true,
    },
    {
      field: 'classification',
      name: 'CLASS',
      align: 'center',
      render: (classification: string) => classification === 'Legal' ? <span className="text--success">{classification}</span> : classification === 'Illegal' ? <span className="text--red">{classification}</span> : <span>Unknown</span>,
      sortable: true,
    },
    {
      field: 'crawler_status',
      name: 'CRAWLER',
      align: 'center',
      render: (crawler_status: string) =>
        <EuiBadge
          className="pill pill--m display-flex justify-center text--black m-x-1"
          color={crawler_status === 'started' ? '#3ad369' : '#FF003D'}
        >
          {crawler_status === 'started' ? 'ON' : 'OFF'}
        </EuiBadge>,
    },
    {
      field: 'last_new_link_collected',
      name: 'LAST UPDATE',
      align: "center",
      render: (last_new_link_collected: Date) => formatDate(last_new_link_collected, 'dobLong'),
      sortable: true,
    },
    {
      field: 'visits_sum',
      name: '#VISITS',
      align: "center",
      render: (visit_sum: number) => <EuiTextAlign textAlign="right" className="w-100">{visit_sum?.toLocaleString() || "-"}</EuiTextAlign>,
      sortable: true,
    },
    {
      name: 'TREND',
      align: "center",
      render: (item: ISite) => {
        return (
          <EuiFlexItem>
            <PopoverCharts item={item} />
          </EuiFlexItem>
        )
      }
    },
    {
      name: 'LANDSCAPES',
      align: 'center',
      render: (site: ISite) =>
        <RowLandscapes
          landscapes={site.landscapes}
          onMoreClick={() => { onRender('add', site); setSites([site]) }
          }
          readonly={false}
        />
    },
    {
      name: 'EDIT',
      align: 'center',
      render: (item: ISite) => {
        return (
          <PopoverOptions
            panelClassName="optionsTable"
            anchorPosition="leftCenter"
            onChange={(key) => onRender(key, item)}
            button={
              <BiDotsHorizontalRounded />
            }
            options={
              [
                {
                  label: <Fragment><EuiIcon type="documentEdit" className="euiIcon" /> Edit Site</Fragment>,
                  value: 'edit'
                },
                {
                  label: <Fragment><ImFileText2 className="euiIcon" /> Details</Fragment>,
                  value: 'details'
                }
              ]
            }
          />
        )
      }
    },
  ];

  const onRender = (key?: TRender, item?: ISite) => {
    if (key && render) {
      setRender(null)
      return
    }
    if (key) {
      setRender(key)
      if (item) setItem(item)
      return
    }

    setItem(null);
    setRender(null)
  }

  // Renders Functions

  const renderBulksRight = () => (
    <EuiFlexGroup className="bulk--actions" >
      {hasSelecteds &&
        <AddLandscapesButton
          onClick={() => {
            setSites(selectedItems)
            onRender('bulk')
          }}
        />
      }
      {/* @ts-ignore */}
      <BulkExport hasSelecteds={hasSelecteds} onClick={() => getSites({ pageSize: Number.MAX_SAFE_INTEGER })} filename="sites" data={csv} isLoading={loading.export} />
    </EuiFlexGroup>
  )

  const renderBulksLeft = () => {
    return (
      <EuiFlexGroup className="euiTable-actions" gutterSize="none">
        <BulkSelecteds
          onClick={() => {
            onSelectionChange([]);
            tableRef.current?.clearSelection();
          }}
          total={selectedItems.length}
          type="Sites"
        />
        <SearchInput
          id="searchInputSites"
          query={query}
          callbackToggle={() => onRender('search')}
          onChange={(e) => setQuery(e.target.value)}
          onClear={() => setQuery('')}
          onKeyUp={keyUpSearch}
          hasAdvancedSearch={true}
        />
        <EuiButtonEmpty onClick={() => onRender('filters')}>
          <RiFilter2Fill />
        </EuiButtonEmpty>
        <EuiFlexGroup className="filters--badges" >
          {filters && Object.keys(filters).map((key) => {
            const items = Array.isArray(filters[key as TSiteFilter]) ? filters[key as TSiteFilter] : [filters[key as TSiteFilter]]

            switch (key as TSiteFilter) {
              case 'classifications':
                const classifications = filters[key as TSiteFilter]
                return Object.keys(classifications).map(classification => <BFilter type={key} filter={classification} onClear={(filter) => deleteFilters(key as TSiteFilter, filter)} />)

              case 'visits':
                if (filters.visits) {
                  return (
                    <BFilter
                      type={key}
                      // @ts-ignore
                      filter={`${filters.visits[0].toLocaleString()} - ${filters.visits[1].toLocaleString()} `}
                      // @ts-ignore
                      onClear={() => deleteFilters(key as TTitleFilter, key)}
                    />
                  )
                }
                break

              default:

                return items.map((el: any) => {
                  return el?.hasOwnProperty('label') ? (
                    <BFilter type={key} filter={el.label} onClear={() => deleteFilters(key as TSiteFilter, el)} />
                  ) : null
                })
            }
          }
          )}
        </EuiFlexGroup>
      </EuiFlexGroup>

    )
  }

  const onUpdateFilters = (value: EuiComboBoxOptionOption[] | EuiComboBoxOptionOption | string, key: TSiteFilter) => {
    switch (key) {
      case 'classifications':
        if (filters) {
          if (filters.classifications?.hasOwnProperty([value])) {
            delete filters.classifications[value as string]
            setFilters({ ...filters })
          } else setFilters({ ...filters, classifications: { ...filters.classifications, [value as string]: true } })
        } else
          setFilters({ classifications: { [value as string]: true } })
        break;
      default:
        if (filters)
          setFilters({ ...filters, [key]: value })
        else  // @ts-ignore 
          setFilters({ [key]: value })
        break;
    }
  }

  const deleteFilters = (type: TSiteFilter, item?: EuiComboBoxOptionOption | EuiCheckboxGroupOption | string) => {
    if (filters)
      switch (type) {
        case "classifications":
          if (typeof item === 'string')
            delete filters.classifications[item]
          setFilters({ ...filters })
          break;
        case "month":
        case 'visits':
          setFilters({ ...filters, [type]: undefined })
          break;

        default:
          if (typeof item === 'object') {
            const index = filters[type]?.findIndex(q => q.value?.toString() === item?.value?.toString())

            if (index === 0 && Array.isArray(filters[type]) && filters[type]?.length === 0) {
              setFilters({ ...filters, [type]: [] })
              return
            }
            if (index || index === 0) {
              filters[type]?.splice(index, 1)
              setFilters({ ...filters, [type]: filters[type] })
            }
          }
          break;
      }
  }

  const onTableChange = ({ page = { index: 0, size: 10 }, sort = { direction: 'asc', field: 'created_at' } }: CriteriaWithPagination<ISite>) => {
    const { index: pageIndex, size: pageSize } = page;
    const { field: sortField, direction: sortDirection } = sort;
    const ordering = sortDirection === "asc" ? `-${sortField}` : sortField

    debounceSites({ pageSize, ordering, filters: selectedFilters, page: pageIndex + 1, domain: query })

    setPageIndex(pageIndex);
    setPageSize(pageSize);
    setSortField(sortField);
    setSortDirection(sortDirection);
  };

  const [pageOfItems] = [store?.results];

  const pagination: Pagination = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    totalItemCount: store?.count || 0,
    pageSizeOptions: HTable.pagination,
  };

  const sorting: EuiTableSortingType<ISite> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  const onSelectionChange = (selectedItems: ISite[]) => {
    setSites(selectedItems)

    let copy = _.cloneDeep(selectedItems)
    copy.map((data: any) => {
      data.categories = data.categories.map((item: any) => item.name).toString()
      data.landscapes = data.landscapes.map((item: any) => item.name).toString()
      data.language = data.language.map((item: any) => item).toString()
    })
    //@ts-ignore
    setCsv(copy)
    setSelectedItems(selectedItems);
  };

  const selection: EuiTableSelectionType<ISite> = {
    selectable: (item: ISite) => true,
    selectableMessage: (selectable: boolean) =>
      !selectable ? 'User is currently offline' : '',
    onSelectionChange: onSelectionChange
  };

  const getSites = async ({ page, pageSize, domain, ordering, exact, filters }: ISitesPagination) => {
    const isExport = pageSize === Number.MAX_SAFE_INTEGER
    try {
      if (isExport) {
        setLoading({ ...loading, export: true })
        setCsv([])
      } else setLoading({ ...loading, store: true });

      const response = await APISites.getSites({
        page: page || pageIndex + 1,
        pageSize,
        domain: domain || query,
        ordering,
        filters,
        exact
      });

      if (isExport) {
        response.data.results.forEach((data: any) => {
          data.categories = data.categories.map((item: any) => item.name).toString()
          data.landscapes = data.landscapes.map((item: any) => item.name).toString()
          data.language = data.language.map((item: any) => item).toString()
        })

        setCsv(response.data.results)
        setLoading({ ...loading, export: false })

        return;
      }

      setStore(response.data);
      setLoading({ ...loading, store: false });

    } catch (e: any) {
      isExport ? setLoading({ ...loading, export: false }) : setLoading({ ...loading, store: true });
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete site request',
        message: (e as Error).toString()
      });
    }
  }

  const debounceSites = useCallback(
    debounce((params) => {
      getSites(params)
    }, 1000),
    []
  );

  const onListenerStatus = () => {
    const rows = document.querySelectorAll(".cellWithRightBorder.sites.euiTableRowCell--hideForMobile");

    store?.results.forEach((item, index) => {
      rows[index].classList.add(`${DStatus[item.status ? item.status : '']}`)
    })
  }

  const keyUpSearch = (e: KeyboardEvent) => {
    if (e.key === "Enter")
      debounceSites({ domain: query, filters: selectedFilters, pageSize, page: pageIndex + 1, ordering: sortDirection })
  }

  useEffect(() => {
    getSites({ pageSize, ordering: "-created_at" });
  }, [])

  useEffect(() => {
    onListenerStatus();
  }, [store])

  useEffect(() => {
    debounceSites({ domain: query, filters: selectedFilters, pageSize, page: pageIndex + 1 })
  }, [filters, query])

  const bulksLeft = renderBulksLeft()
  const bulksRight = renderBulksRight()

  return (
    <PageTemplateTable
      id="sites-page"
      breadcrumbs={HBreadcrumbs.SITES}
      bulksLeft={bulksLeft}
      bulksRight={bulksRight}
    >
      <EuiBasicTable
        ref={tableRef}
        tableLayout="auto"
        items={pageOfItems || []}
        itemId="id"
        // itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        isExpandable={true}
        hasActions={true}
        loading={loading.store}
        columns={columns}
        pagination={pagination}
        sorting={!loading.store ? sorting : undefined}
        isSelectable={true}
        selection={selection}
        onChange={onTableChange}
        responsive={true}
        noItemsMessage={<EuiFlexItem className="p-30">No items found</EuiFlexItem>}
      />
      {(render === 'edit' && item) &&
        <MSite
          site={item}
          onClose={onRender}
          callback={(site: ISite) => {
            const index = store?.results.findIndex((item: ISite) => item.id === site.id)
            const rows = document.querySelectorAll(".cellWithRightBorder.sites.euiTableRowCell--hideForMobile")
            rows[index].classList.remove(`${DStatus[store.results[index].status]}`);

            store.results[index] = { ...store.results[index], ...site }
            rows[index].classList.add(`${DStatus[site.status]}`);
            setStore({ ...store, results: store.results })
          }}
        />
      }
      {(render === 'bulk' || render === 'add') &&
        <MTitle
          onClose={onRender}
          render={render}
          sites={sites}
          callback={(data: any) => {
            switch (render) {
              case 'add':
                const site = data;
                const index = store?.results.findIndex((item: ISite) => item.id === site.id)

                store.results[index] = { ...store.results[index], ...site }

                setStore({ ...store, results: store.results })

                break;

              case 'bulk':
                getSites({ domain: query, filters: selectedFilters, pageSize, page: pageIndex + 1 })
                break;

              default:
                break;
            }

          }}
        />
      }
      {render === 'details' && <MDetails title="Details - Site" onClose={onRender} rows={rows} />}
      <FFSites
        isOpen={render === 'filters'}
        onClose={onRender}
        filters={filters}
        onChange={onUpdateFilters}
        resetFilters={() => setFilters(undefined)}
      />
      <FSearch
        isOpen={render === 'search'}
        onClose={onRender}
        options={store.results.map(item => ({ value: item.domain, label: item.domain }))}
        onChange={(searchValue) => debounceSites({ filters: selectedFilters, page: pageIndex + 1, pageSize, domain: searchValue })}
        callback={(options: any) => debounceSites({ domain: options.map((item: any) => item.value).toString(), exact: true, filters: selectedFilters, pageSize, page: pageIndex + 1 })}
      />
    </PageTemplateTable>
  );
};

export default Sites;
