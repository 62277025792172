import { EuiFocusTrap,EuiHighlight } from "@elastic/eui";


  const renderOption = (option: any, searchValue: string, contentClassName: string) => {
    const { label } = option;
    return (
      <EuiFocusTrap disabled={false} scrollLock={false} >
        <span style={{ zIndex: 10000 }} >
          <EuiHighlight search={searchValue}>{label.toString()}</EuiHighlight>
        </span>
      </EuiFocusTrap>
    );
  };

  export { renderOption }