import React, { Fragment, useState, useEffect } from 'react';
import {
  EuiHeader,
  EuiImage,
  EuiIcon, EuiFlexItem, EuiHeaderSectionItemButton, EuiAvatar, EuiFlyout,
} from '@elastic/eui';

import { useHistory } from "react-router-dom";

import { SidebarNavGroup, SidebarNavItem } from './styles';
import { FaHome } from 'react-icons/fa';
import { AiFillPlusCircle, AiOutlineGlobal } from 'react-icons/ai';
import { FiMap } from 'react-icons/fi';
import { ImFilm } from 'react-icons/im'
import { ToastsProvider } from 'src/modules/toast';
import Toast from 'src/components/Toast';
import MTitle from 'src/components/Modals/MTitle';
import MLandscape from 'src/components/Modals/MLandscape';
import MSite from 'src/components/Modals/MSite';

const Sidebar = ({ children }: any) => {
  const [openMTitle, setOpenMTitle] = useState<boolean>(false)
  const [openMLandscape, setOpenMLandscape] = useState<boolean>(false)
  const [openMSite, setOpenMSite] = useState<boolean>(false)
  const [active, setActive] = useState("#")

  useEffect(() => {
    const url = window.location.hash.split('#')[1].split('/')
    const currentPath = url[1].substring(0, url[1].length) || '#'

    setActive(currentPath)
  })

  const TopLinks = [
    {
      label: 'Home',
      iconType: <FaHome className="euiIcon" />,
      isActive: active === "#",
      'aria-current': true,
      href: '#/',
      onClick: () => setActive("#")
    },
    {
      label: 'Sites',
      iconType: "globe",
      isActive: active === "sites",
      onClick: () => setActive("sites"),
      'aria-current': true,
      href: '#/sites',
    },
    {

      label: 'Landscapes',
      iconType: 'visMapRegion',
      isActive: active === "landscapes",
      'aria-current': true,
      href: '#/landscapes',
      onClick: () => setActive("landscapes"),
    },
    {
      label: "Titles",
      iconType: <ImFilm className="euiIcon" />,
      isActive: active === "titles",
      'aria-current': true,
      href: '#/titles',
      onClick: () => setActive("titles"),
    },
    {
      label: "Add Site",
      iconType:
        <div className="circle dashed p">
          <AiOutlineGlobal className="euiIcon" />
          <AiFillPlusCircle className="plus" />
        </div>,
      isActive: false,
      'aria-current': true,
      onClick: () => setOpenMSite(true),
    },
    {
      label: 'Add Landscape',
      iconType:
        <div className="circle dashed p">
          <FiMap />
          <AiFillPlusCircle className="plus" />
        </div>,
      isActive: false,
      'aria-current': true,
      onClick: () => setOpenMLandscape(true),
    },
    {
      label: "Add Title",
      iconType:
        <div className="circle dashed p">
          <ImFilm className="euiIcon" />
          <AiFillPlusCircle className="plus" />
        </div>,
      isActive: false,
      'aria-current': true,
      onClick: () => setOpenMTitle(true),
    },
  ];

  const collapsibleNav = (
    <EuiFlyout
      id="sidebar"
      side="left"
      ownFocus={false}
      hideCloseButton
      type="push"
      size="xs"
      onClose={() => null}
    >
      <SidebarNavGroup >
        {TopLinks.map((item: any, index: number) => {
          const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (typeof item.onClick === 'function')
              item.onClick(event)
            if (typeof item.href === 'string')
              window.location.href = item.href
          }
          return (
            <EuiFlexItem onClick={handleClick} grow={false} key={index} >
              <SidebarNavItem data-active={item.isActive}>
                {typeof item.iconType === 'string' && <EuiIcon type={`${item.iconType}`} />}
                {typeof item.iconType === 'object' && item.iconType}
              </SidebarNavItem>
            </EuiFlexItem>
          )
        })
        }
      </SidebarNavGroup>
    </EuiFlyout >
  );

  const leftSectionItems = [
    <EuiImage
      style={{ marginLeft: '1rem', marginRight: '1rem' }}
      alt="Logo"
      src={process.env.PUBLIC_URL + '/landscape-logo.svg'}
    />
  ];

  return (
    <Fragment>
      <EuiHeader position="fixed" theme="dark" sections={[
        {
          items: leftSectionItems,
          borders: 'right',
        },
        {
          items: [
            <EuiHeaderSectionItemButton aria-label="Account menu">
              <EuiIcon type="search" />
            </EuiHeaderSectionItemButton>,
            <EuiHeaderSectionItemButton
              aria-label="2 Notifications"
              notification={'2'}>
              <EuiIcon type="bell" />
            </EuiHeaderSectionItemButton>,
            <EuiHeaderSectionItemButton aria-label="Account menu">
              <EuiAvatar name="John Username" size="s" />
            </EuiHeaderSectionItemButton>,
          ],
          borders: 'none',
        },
      ]}
      />
      {collapsibleNav}
      {children}
      {openMSite && <MSite onClose={() => setOpenMSite(false)} />}
      {openMLandscape && <MLandscape onClose={() => setOpenMLandscape(false)} />}
      {openMTitle && <MTitle onClose={() => setOpenMTitle(false)} render="create" />}
    </Fragment>
  );
};

const FSidebar = ({ children }: any) => (
  <ToastsProvider>
    <Sidebar>
      {children}
    </Sidebar>
    <Toast />
  </ToastsProvider>
)

export default FSidebar;
