import React, { Fragment, useState } from 'react'
import {
  EuiBadge,
  EuiAvatar,
  EuiToolTip,
  EuiPopoverTitle,
  EuiPopover
} from "@elastic/eui"
import { IDict, TSizes } from '../../@types/IGlobal'
import { GroupBadgeWrapper, CircleMore, ListItem, ListGroup } from './styles'


interface Props {
  items: string[],
  colors?: IDict[],
  title?: string
  maxBadges?: number
  isExpaned?: boolean
  size?: TSizes
  isAvatar?: boolean
  className?: string
}


const BGroup: React.FC<Props> = ({ items, colors, title, maxBadges = 2, isExpaned, size = "m", isAvatar = true, className }) => {
  const [popover, setPopover] = useState<boolean>(false)
  const remainingBadges = items.length - maxBadges


  return (
    <GroupBadgeWrapper className={`euiFlexGroup euiFlexGroup--directionRow euiFlexGroup--responsive ${className || ''}`}>
      {Array.isArray(items) && items.map((item: string, index: number) => {
        // @ts-ignore
        const _colors = colors ? colors[item as string] : undefined
        if (items.length > maxBadges && !isExpaned)
          return (
            <Fragment key={index}>
              {index < maxBadges &&
                <Fragment>
                  {isAvatar ?
                    <EuiToolTip position="top" content={item}>
                      <EuiAvatar size={size} name={item} initialsLength={2} color={_colors} />
                    </EuiToolTip>
                    :
                    <EuiBadge key={index} className="pill pill--m display-flex justify-center" color={_colors}>{item}</EuiBadge>
                  }
                </Fragment>
              }
            </Fragment>
          )

        return (
          <EuiBadge key={index} className="pill pill--m display-flex justify-center" color={_colors}>{item}</EuiBadge>
        )
      })
      }

      {(items.length > maxBadges && !isExpaned) &&
        <EuiPopover
          button={
            <EuiToolTip position="top" content="View more">
              <CircleMore className="more m" onClick={() => setPopover(popover => !popover)}>{remainingBadges > 99 ? '+99' : remainingBadges}</CircleMore>
            </EuiToolTip>
          }
          panelClassName={`popover list w-middle `}
          isOpen={popover}
          closePopover={() => setPopover(false)}
          anchorPosition="downRight"
          hasArrow={false}
        >
          {title && <EuiPopoverTitle>{title}</EuiPopoverTitle>}

          <ListGroup>
            {items.map((item: string, index: number) => {
              // @ts-ignore
              const _colors = colors ? colors[item] : undefined
              return (
                <ListItem key={index}>
                  <EuiAvatar size="m" name={item} initialsLength={2} color={_colors} />
                  {item}
                </ListItem>
              )
            })
            }
          </ListGroup>
        </EuiPopover>
      }
    </GroupBadgeWrapper>
  )
}

export default BGroup