import React, { useState, FC, useCallback, useEffect } from 'react'
import {
  Axis,
  Chart,
  CurveType,
  LineSeries,
  niceTimeFormatByDay,
  Position,
  ScaleType,
  Settings,
  timeFormatter,
  AreaSeries, BarSeries, LIGHT_THEME, ColorVariant, PartialTheme, LineAnnotation
} from '@elastic/charts';

import "@elastic/charts/dist/theme_light.css";


import {
  EuiPopover, EuiPopoverTitle, EuiButtonEmpty,
  EuiPopoverFooter,
  EuiComboBoxOptionOption,
  EuiBadge,
} from "@elastic/eui";
import { ISite } from '../../@types/ISite';
import FilterPeriodButton from '../FilterPeriodButton';
import { APISites } from 'src/services/api';
import { DCalendarMonth } from 'src/helpers/enums';
import { SimpleLoading } from '../Loaders/SimpleLoading';

interface Props {
  readonly icon?: string | Element | Object,
  readonly title?: string,
  readonly className?: string,
  item: ISite,
}

const PopoverCharts: FC<Props> = ({ icon, title, item }) => {
  item.visits = Object.keys(item.visits).map((key) => item.visits[key])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<EuiComboBoxOptionOption>({ value: "12", label: "12 m" })
  const [data, setData] = useState<{ visits: [], visits_sum: number, period: [] }>({ period: [], visits_sum: 0, visits: [] })

  const getVisits = async () => {
    setIsLoading(true)
    try {
      const response = await APISites.getVisits({ siteId: item.id, month: filter.value?.toString() })
      response.data.period = Object.keys(response.data.visits).map((key) => key)
      response.data.visits = Object.keys(response.data.visits).map((key) => [DCalendarMonth[key.split('-')[1]], response.data.visits[key]])
      setData(response.data)
      setIsLoading(false)
    }

    catch (err: any) {


    }
  }

  const customTheme: PartialTheme = {
    axes: {
      axisLine: {
        visible: false
      },
      tickLabel: {
        fill: "#F5F8FF",
        fontSize: 9,
        // offset: {
        //   x: 0, y: 2
        // },
        // rotation: 90
        padding: {
          inner: 80
        },
      },
      tickLine: {
        visible: false,
      }
    },
    lineSeriesStyle: {
      point: {
        fill: "#000",
        stroke: "#fff",
        strokeWidth: 1,
      },
      line: {
        stroke: "#fff",
        strokeWidth: 2,
      }
    },
    chartMargins: {
      top: 15,
      bottom: 15
    },
    chartPaddings: {
      top: -15,
      bottom: 15,
    },
    crosshair: {
      band: {
        fill: "transparent",
      }
    },
  }

  const handlePopoverOpen = () => {
    setOpen(open => !open)

  }

  useEffect(() => {
    if (open) {
      getVisits()
    }
  }, [open, filter])

  const xDomain = {
    min: 0,
    max: 100,
  };

  const lineDomain = {
    min: 0,
    max: 100,
  };


  return (
    <EuiPopover
      button={
        <EuiButtonEmpty onClick={handlePopoverOpen}>
          {(!item.visits_sum || item.visits[0] > item.visits[item.visits.length - 1])
            ? <svg xmlns="http://www.w3.org/2000/svg" width="27" height="16.2"><path data-name="Path 80" d="M18.9 16.2l3.1-3.1-6.59-6.579-5.4 5.4L0 1.91 1.91 0l8.1 8.1 5.4-5.4 8.49 8.5L27 8.1v8.1z" fill="#ff003d" /></svg>
            : item.visits[0] <= item.visits[item.visits.length - 1] && <svg xmlns="http://www.w3.org/2000/svg" width="27" height="16.2"><path data-name="Path 80" d="M18.9 0L22 3.1l-6.59 6.58-5.4-5.4L0 14.29l1.91 1.91 8.1-8.1 5.4 5.4L23.9 5 27 8.1V0z" fill="#41f075" /></svg>}
        </EuiButtonEmpty>
      }
      panelClassName="chart popover w-middle"
      isOpen={open}
      closePopover={() => setOpen(false)}
      anchorPosition="downLeft"
      hasArrow={false}
    >
      <EuiPopoverTitle>
        <span className="">Views</span>
        <FilterPeriodButton
          updateFilters={(filter: EuiComboBoxOptionOption) => setFilter(filter)}
          hasActiveFilters={filter}
          options={[
            { value: "6", label: "6 m" },
            { value: "12", label: "12 m" }
          ]}
        />
      </EuiPopoverTitle>
      {isLoading ? <SimpleLoading />
        :
        <Chart className="chart--period" >
          <div className="chart-info">
            <EuiBadge iconType="eye">{data?.visits_sum?.toLocaleString() || 0}</EuiBadge>
            {/* @ts-ignore */}
            <span>{`${data?.period[0]} - ${data?.period[data.period.length - 1]}`}</span>
          </div>
          <Settings theme={customTheme} />
          <Axis
            id="bottom"
            position={Position.Top}
            showOverlappingLabels={true}
          />
          <LineSeries
            id="visits"
            yScaleType={ScaleType.Linear}
            xAccessor={0}
            yAccessors={[1]}
            yNice={true}
            data={data?.visits}
            curve={CurveType.CURVE_NATURAL}
          />
        </Chart>
      }
      <EuiPopoverFooter />
    </EuiPopover>
  )
}

export default PopoverCharts;
