import React, { Fragment, FC } from 'react';
import {
  EuiFilterGroup,
  EuiFilterButton,
  EuiComboBoxOptionOption
} from '@elastic/eui';


interface Props {
  updateFilters?: (value: EuiComboBoxOptionOption) => void,
  hasActiveFilters?: EuiComboBoxOptionOption,
  options: EuiComboBoxOptionOption[]
}



const FilterPeriodButton: FC<Props> = ({ hasActiveFilters, updateFilters, options }) => {

  const onClick = (value: any, label: string) => {


    if (typeof updateFilters === 'function')
      updateFilters({ value, label })

  }

  return (
    <EuiFilterGroup className="filters--period">
      { options.map((item, index) => {
        return (
          <EuiFilterButton
            key={index}
            hasActiveFilters={hasActiveFilters?.value === item.value}
            onClick={() => onClick(item?.value, item.label)}
          > {item.label}
          </EuiFilterButton>
        )
      })
      }
    </EuiFilterGroup>
  )
}

export default FilterPeriodButton;