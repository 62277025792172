import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFormRow,
  EuiDualRange,
  EuiForm,
  htmlIdGenerator
} from '@elastic/eui'
import debounce from 'lodash/debounce';
import { renderOption } from 'src/helpers/eui'
import HOptions from 'src/helpers/options'
import SearchInputAdvanced from '../../SearchInput/SearchInputAdvanced'
import FFTemplate from './FFTemplate'
import { APILandscapes, APISites, APITitles } from 'src/services/api'
import { ToastsContext } from 'src/modules/toast'
import { ILandscapesFilters, TLandscapesFilter } from 'src/@types/ILandscape'
import { ISite } from 'src/@types/ISite';
import { SimpleLoading } from 'src/components/Loaders/SimpleLoading';

interface Props {
  isOpen?: boolean
  onClose: () => void
  readonly className?: string,
  filters?: ILandscapesFilters | null,
  applyFilters?: Function,
  resetFilters?: () => void,
  onChange?: (value: EuiComboBoxOptionOption | EuiComboBoxOptionOption[], type: TLandscapesFilter) => void
}

const FFLandascapes: React.FC<Props> = (props) => {
  const { onClose, filters, isOpen = false, resetFilters } = props;
  const toastsContext = useContext(ToastsContext);
  const [titlesOptions, setTitlesOptions] = useState<{ data?: EuiComboBoxOptionOption[], isLoading: boolean }>({ isLoading: true })
  const [sitesOptions, setSitesOptions] = useState<{ data?: EuiComboBoxOptionOption[], isLoading: boolean }>({ isLoading: false })
  const [maxTitlesAmount, setMaxTitlesAmount] = useState<{ value: number, isLoading: boolean }>({ value: 10000, isLoading: true })
  const [maxSitesAmount, setMaxSitesAmount] = useState<{ value: number, isLoading: boolean }>({ value: 10000, isLoading: true })

  const getTitles = async () => {
    try {
      const response = await APITitles.getTitles({
        pageSize: Number.MAX_SAFE_INTEGER,
      });

      setTitlesOptions({
        data: response.results.map((item) => ({
          label: item.name, value: item.id
        })), isLoading: false
      })
    } catch (e: any) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete title request',
        message: (e as Error).toString()
      });
    }
  }

  const getSites = async (domain: string) => {
    setSitesOptions({ ...sitesOptions, isLoading: true })
    try {
      const response = await APISites.getSites({
        page: 1,
        pageSize: Number.MAX_SAFE_INTEGER,
        domain
      });

      setSitesOptions({
        data: response.data.results.map((item: ISite) => ({
          label: item.domain, value: item.domain
        })), isLoading: false
      })

    } catch (e: any) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete site request',
        message: (e as Error).toString()
      });
    }
  }

  const getMaxTitles = async () => {
    try {
      const response = await APILandscapes.getMaxTitlesAmount();
      setMaxTitlesAmount({ value: response.data.max_titles_count, isLoading: false })
    } catch (e) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete max title request',
        message: (e as Error).toString()
      });
    }
  }

  const getMaxSites = async () => {
    try {
      const response = await APILandscapes.getMaxSitesAmount();
      setMaxSitesAmount({ value: response.data.max_sites_count, isLoading: false })
    } catch (e) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete max site request',
        message: (e as Error).toString()
      });
    }
  }

  const debounceSites = useCallback(
    debounce((query: string) => {
      getSites(query)
    }, 1000),
    []
  );

  const onChange = (value: any, key: TLandscapesFilter) => {
    if (typeof props.onChange === 'function')
      props.onChange(value, key)
  }

  useEffect(() => {
    if (isOpen) {
      getTitles()
      getMaxTitles()
      getMaxSites()
    }
  }, [isOpen])

  return (
    <FFTemplate
      isOpen={isOpen}
      onClose={onClose}
      title="FILTERS/LANDSCAPES"
      resetFilters={() => typeof resetFilters === 'function' ? resetFilters() : null}
    >
      <EuiForm>
        <EuiFormRow label="Status">
          <EuiComboBox
            compressed
            renderOption={renderOption}
            sortMatchesBy="startsWith"
            placeholder="All"
            options={HOptions.site.status}
            selectedOptions={filters?.status}
            onChange={(value) => onChange(value, "status")}
            data-test-subj="demoComboBox"
          />
        </EuiFormRow>
        <EuiFormRow label="Domains">
          <SearchInputAdvanced
            options={sitesOptions.data}
            isLoading={sitesOptions.isLoading}
            selectedOptions={filters?.domains}
            onSearchChange={(searchValue) => searchValue?.length > 0 ? debounceSites(searchValue) : null}
            onChange={(options) => onChange(options, 'domains')}
          // setSelecteds={(options) => onChange([...filters?.domains, options], 'domains')} //onPaste
          />
        </EuiFormRow>
        <div className="euiFormRow" id="i7e2f3913-0d0e-11ec-8d9a-e375a361c472-row">
          <Fragment>
            <div className="euiFormRow__labelWrapper">
              <label className="euiFormLabel euiFormRow__label" htmlFor="i7e2f3913-0d0e-11ec-8d9a-e375a361c472">Sites #</label>
              <div className="">{`${filters?.sites_count ? filters?.sites_count[0] : 0} - ${filters?.sites_count ? filters?.sites_count[1] : maxSitesAmount.value}`}</div>
            </div>
          </Fragment>
          {maxSitesAmount.isLoading ? <SimpleLoading />
            :
            <EuiDualRange
              id={htmlIdGenerator()()}
              min={0}
              max={maxSitesAmount.value || 1000}
              // step={10}
              value={filters?.sites_count || [0, 0]}
              onChange={(value) => onChange(value, "sites_count")}
              showLabels
              aria-label="An example of EuiDualRange"
            />
          }
        </div>
        <EuiFormRow label="Titles">
          <EuiComboBox
            compressed
            renderOption={renderOption}
            sortMatchesBy="startsWith"
            placeholder="All"
            isLoading={titlesOptions.isLoading}
            options={titlesOptions.data}
            selectedOptions={filters?.titles}
            onChange={(value) => onChange(value, "titles")}
            data-test-subj="demoComboBox"
          />
        </EuiFormRow>
        <div className="euiFormRow" id="i7e2f3913-11ec-8d9a-e375a361c472-row">
          <div className="euiFormRow__labelWrapper">
            <label className="euiFormLabel euiFormRow__label" htmlFor="i7e2f3913-0d0e-11ec-8d9a-e375a361c472">Titles #</label>
            <div className="">{`${filters?.titles_count ? filters?.titles_count[0] : 0} - ${filters?.titles_count ? filters?.titles_count[1] : maxTitlesAmount.value}`}</div>
          </div>
          {maxTitlesAmount.isLoading ? <SimpleLoading />
            :
            <EuiDualRange
              id={htmlIdGenerator()()}
              min={0}
              max={maxTitlesAmount.value || 1000}
              // step={10}
              value={filters?.titles_count || [0, 0]}
              onChange={(value) => onChange(value, "titles_count")}
              showLabels
              aria-label="An example of EuiDualRange"
            />
          }
        </div>
      </EuiForm>
    </FFTemplate>
  )
}

export default FFLandascapes