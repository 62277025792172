export const getInitials = function (nome: string) {
  // let nome = this;
  let result = '';
  if (nome) {
    let nomes = nome.split(' ');
    let nomeFirst = "";
    let nomeLast = "";
    nomeFirst = nomes[0];
    if (nomes.length > 1) {
      nomeLast = nomes[nomes.length - 1];
    }
    nomeFirst = nomeFirst.charAt(0).toUpperCase();
    nomeLast = nomeLast.charAt(0).toUpperCase();
    result = `${nomeFirst}${nomeLast}`;
  } else {
    result = "";
  }
  return result;
}

export function isEmpty(obj: Object) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
}