import { FC, useState } from "react";
import { EuiPopover } from "@elastic/eui";
import { OptionsGroup, OptionItem } from "./styles";

export type PopoverAnchorPosition = 'upCenter' | 'upLeft' | 'upRight' | 'downCenter' | 'downLeft' | 'downRight' | 'leftCenter' | 'leftUp' | 'leftDown' | 'rightCenter' | 'rightUp' | 'rightDown';

interface Props {
  options: { value: any, label: string | any, color?: string }[]
  panelClassName?: string
  button?: object | React.ReactNode
  anchorPosition?: PopoverAnchorPosition
  isLoading?: boolean
  onChange?: (value: any) => void
  onClickButton?: Function
  id?: string
}

const PopoverOptions: FC<Props> = ({ options, panelClassName = "", button, isLoading, onChange, id = "popover", onClickButton, anchorPosition = "downLeft" }) => {

  const [open, setOpen] = useState<boolean>(false);

  const handlePopoverOpen = () => {
    if (typeof onClickButton === 'function')
      onClickButton()

    setOpen(open => !open)
  }

  const handleOption = (value: any, e: any) => {
    if (typeof onChange === 'function')
      onChange(value)

    setOpen(false)
  }

  return (
    <EuiPopover
      ownFocus={false}
      id={id}
      button={
        <div onClick={handlePopoverOpen} className="buttonPopoverOptions">
          {button}
        </div>
      }
      panelClassName={` popover ${panelClassName}`}
      isOpen={open}
      closePopover={() => setOpen(false)}
      anchorPosition={anchorPosition}
      hasArrow={false}
    >
      <OptionsGroup className="optionsGroup">
        {options.map((item, index) => {
          return (
            <OptionItem className="optionItem" key={index} color={item.color} onClick={(e) => handleOption(item.value, e)}>
              {item.label}
            </OptionItem>
          )
        })
        }
      </OptionsGroup>
    </EuiPopover>
  )
}

export default PopoverOptions;