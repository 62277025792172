import { EuiButton, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle } from "@elastic/eui";
import React, { ReactNode, FC } from "react";

interface Props {
  closeModal: () => void
  confirm: () => void
  title: String
  body: ReactNode
  className?: String
  confirmLabel?: String
  cancelLabel?: String
}

const MConfirm: FC<Props> = ({ title, body, confirmLabel, cancelLabel, className, closeModal, confirm }) => {
  return (
    <EuiModal className={`${className || 'modalConfirm'}`} onClose={closeModal}>
      {!!title && <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>
            {title}
          </h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      }
      <EuiModalBody>
        {body}
      </EuiModalBody>
      <EuiModalFooter>
        {!!confirmLabel &&
          <EuiButton className="buttonLeft" onClick={confirm}>
            {confirmLabel}
          </EuiButton>
        }
        <EuiButton onClick={closeModal}>
          {cancelLabel || 'Cancel'}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}

export default MConfirm;