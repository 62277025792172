import { FC, useRef, useEffect, useState } from 'react';
import { EuiButtonEmpty, EuiFlexItem, EuiIcon } from '@elastic/eui';
import { CSVLink } from 'react-csv';

interface Props {
  data?: [],
  hasSelecteds?: boolean
  onClick?: () => void,
  isLoading: boolean
  filename: string
  className?: string
}

const BulkExport: FC<Props> = ({ data = [], hasSelecteds, onClick, isLoading, filename, className }) => {

  const [async, setAsync] = useState<boolean>(false)

  const csvRef = useRef()

  const handleClick = () => {
    setAsync(true)
    if (typeof onClick === 'function' && !hasSelecteds)
      onClick()
  }

  useEffect(() => {
    // @ts-ignore 
    if (!isLoading && data.length > 0 && csvRef?.current?.link && !hasSelecteds && async) {
      setTimeout(() => {
        // @ts-ignore 
        csvRef.current.link.click();
      }, 1000);
    }
  }, [isLoading]);

  useEffect(() => {
    if (hasSelecteds)
      setAsync(false)
  }, [hasSelecteds])

  return (
    <EuiFlexItem grow={false} className={` bulk--export ${className || 'ghost'}`}>
      {hasSelecteds &&
        <CSVLink
          data={data}
          filename={`${filename}.csv`}
        >
          <EuiIcon type="download" /> Export to csv
        </CSVLink>
      }

      {(!hasSelecteds && (isLoading || !async)) &&
        <EuiButtonEmpty
          onClick={handleClick}
          isLoading={isLoading}
        >
          <EuiIcon type="download" /> Export to csv
        </EuiButtonEmpty>
      }
      {(!hasSelecteds && async && !isLoading) &&
        <CSVLink
          data={data}
          filename={`${filename}.csv`}
          // @ts-ignore 
          ref={csvRef}
        >
          <EuiIcon type="download" /> Export to csv
        </CSVLink>
      }

    </EuiFlexItem>
  )
}

export default BulkExport;