import axios from "axios";
import { apiBaseURL } from ".";
import { IPaginationFetch } from '../../@types/IGlobal';
import { ISiteResult, ISitesPagination } from '../../@types/ISite';

const APISites = {
  async fetchSites({ pageSize = 10, page = 1 }: IPaginationFetch): Promise<ISiteResult> {
    const response: Response = await fetch(`${apiBaseURL}/sites?page=${page}&page_size=${pageSize}`);

    const data: ISiteResult = await response.json();
    return data;
  },

  getSites({ pageSize = 10, page = 1, filters, domain, ordering, exact }: ISitesPagination) {
    let filter = '';
    if (filters?.landscapes && filters?.landscapes?.length > 0) filter += `&landscapes=${filters?.landscapes}`
    if (filters?.languages && filters?.languages?.length > 0) filter += `&languages=${filters?.languages}`
    if (filters?.categories && filters?.categories?.length > 0) filter += `&categories=${filters?.categories}`
    if (filters?.classifications && filters?.classifications?.length > 0) filter += `&classifications=${filters?.classifications}`
    if (filters?.status && filters?.status?.length > 0) filter += `&status=${filters?.status}`
    if (filters?.month && filters?.month?.length > 0) filter += `&date=${filters?.month}`
    if (filters?.visits_sum_upper && filters?.visits_sum_upper?.length > 0) filter += `&visits_sum_upper=${filters?.visits_sum_upper}`
    if (filters?.visits_sum_lower && filters?.visits_sum_lower?.length > 0) filter += `&visits_sum_lower=${filters?.visits_sum_lower}`
    if (domain && domain?.length > 0) filter += `&domain=${domain}`
    if (ordering && ordering?.length > 0) filter += `&ordering=${ordering}`
    if (exact) filter += `&exact=${exact}`

    return axios.get(`${apiBaseURL}/sites/?page=${page}&page_size=${pageSize}${filter}`)
  },

  getVisits({ siteId, month }: { siteId: number, month?: string }) {
    return axios.get(`${apiBaseURL}/sites/visits/${siteId}?date=${month}`)
  },

  putScanSite(siteScanId: number, body: Object) {
    return axios.put(`${apiBaseURL}/scansites/${siteScanId}/`, body)
  },

  postSitesTitles() {
    return axios.get(`${apiBaseURL}/sitetitles/assosiate/ `)
  },

  postSiteLandscapes({ site, landscapeIds }: { site: number, landscapeIds: any }) {
    return axios.post(`${apiBaseURL}/sitelandscapes/disassociate/`, {
      site,
      landscape_ids: landscapeIds
    })
  },

  getMaxVisits() {
    return axios.get(`${apiBaseURL}/sites/max-visits/`)
  },

  postSites(body: any) {
    return axios.post(`${apiBaseURL}/sites/`, body)
  },

  putSite(id: number, body: any) {
    return axios.put(`${apiBaseURL}/sites/${id}/`, body)
  }
}



export default APISites;