import React, { Fragment, useEffect, useState, useContext } from 'react';
import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFormRow,
  EuiCheckboxGroup,
  EuiDatePicker,
  EuiDualRange,
  EuiForm,
  htmlIdGenerator
} from '@elastic/eui'
import { renderOption } from 'src/helpers/eui'
import { ISiteFilters, TSiteFilter } from 'src/@types/ISite'
import FilterPeriodButton from '../../FilterPeriodButton'
import HOptions from 'src/helpers/options'
import FFTemplate from './FFTemplate';
import { APICategories, APILandscapes, APISites } from 'src/services/api';
import { SimpleLoading } from 'src/components/Loaders/SimpleLoading';
import { ToastsContext } from 'src/modules/toast';

interface Props {
  isOpen?: boolean
  onClose: () => void
  readonly className?: string,
  resetFilters: () => void,
  filters?: ISiteFilters | null,
  applyFilters?: Function,
  onChange?: (value: EuiComboBoxOptionOption | EuiComboBoxOptionOption[], key: TSiteFilter) => void
}

const FFSites: React.FC<Props> = (props) => {
  const toastsContext = useContext(ToastsContext);
  const { onClose, filters, resetFilters, isOpen } = props;
  const [landscapesOptions, setLandscapesOptions] = useState<{ data?: EuiComboBoxOptionOption[], isLoading: boolean }>({ isLoading: true })
  const [categoriesOptions, setCategoriesOptions] = useState<{ data?: EuiComboBoxOptionOption[], isLoading: boolean }>({ isLoading: true })
  const [maxVisits, setMaxVisits] = useState<{ value: number, isLoading: boolean }>({ value: 10000, isLoading: true })

  const getLandscapes = async (query = '', page = 1, pageSize = 10) => {
    try {
      const response = await APILandscapes.fetchLandscapes({ page, pageSize: Number.MAX_SAFE_INTEGER });
      setLandscapesOptions({
        data: response.results.map((landscape) => {
          return {
            label: landscape.name,
            value: landscape.id
          }
        }), isLoading: false
      })
    } catch (e) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete landscape request',
        message: (e as Error).message
      });
    }
  }

  const getCategories = async (query = '', page = 1, pageSize = 10) => {
    try {
      const response = await APICategories.getCategories({ page, pageSize: Number.MAX_SAFE_INTEGER });
      setCategoriesOptions({
        data: response.results.map((categorie) => {
          return {
            label: categorie.name,
            value: categorie.id
          }
        }), isLoading: false
      })

    } catch (e) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete categories request',
        message: (e as Error).message
      });
    }
  }

  const getMaxVisits = async () => {
    try {
      const response = await APISites.getMaxVisits();
      setMaxVisits({ value: response.data.max_visits_sum, isLoading: false })
    }
    catch (e: any) {

    }
  }


  const onChange = (value: any, key: TSiteFilter) => {
    if (typeof props.onChange === 'function')
      props.onChange(value, key)
  }

  useEffect(() => {
    if (isOpen) {
      getLandscapes()
      getCategories()
      getMaxVisits()
    }
  }, [isOpen])

  return (
    <FFTemplate
      title='FILTERS/SITES'
      onClose={onClose}
      isOpen={isOpen}
      resetFilters={() => typeof resetFilters === 'function' ? resetFilters() : null}
    >
      <EuiForm>
        <EuiFormRow label="Select period">
          <FilterPeriodButton
            updateFilters={(value) => onChange(value, "month")}
            hasActiveFilters={filters?.month as EuiComboBoxOptionOption}
            options={[
              { value: "1", label: "1 m" },
              { value: "6", label: "6 m" },
              { value: "12", label: "12 m" }
            ]}
          />
        </EuiFormRow>
        <EuiFormRow label="Status">
          <EuiComboBox
            compressed
            renderOption={renderOption}
            sortMatchesBy="startsWith"
            placeholder="All"
            options={HOptions.site.status}
            selectedOptions={filters?.status as EuiComboBoxOptionOption[]}
            onChange={(value) => onChange(value, "status")}
            data-test-subj="demoComboBox"
          />
        </EuiFormRow>
        <EuiFormRow label="Categories">
          <EuiComboBox
            compressed
            renderOption={renderOption}
            sortMatchesBy="startsWith"
            placeholder="All"
            isLoading={categoriesOptions.isLoading}
            options={categoriesOptions.data}
            selectedOptions={filters?.categories as EuiComboBoxOptionOption[]}
            onChange={(value) => onChange(value, "categories")}
          />
        </EuiFormRow>
        <EuiFormRow label="Languages">
          <EuiComboBox
            compressed
            sortMatchesBy="startsWith"
            placeholder="All"
            options={HOptions.languages}
            selectedOptions={filters?.languages as EuiComboBoxOptionOption[]}
            onChange={(value) => onChange(value, "languages")}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Classifications"
        >
          <EuiCheckboxGroup
            options={HOptions.site.classifications}
            idToSelectedMap={filters?.classifications || {}}
            onChange={(value) => onChange(value, "classifications")}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Landscapes"
        >
          <EuiComboBox
            compressed
            sortMatchesBy="startsWith"
            placeholder="All"
            renderOption={renderOption}
            // async
            // onSearchChange={(searchValue) => handleSearch(searchValue, "landscapes")}
            isLoading={landscapesOptions.isLoading}
            options={landscapesOptions.data}
            selectedOptions={filters?.landscapes as EuiComboBoxOptionOption[]}
            onChange={(value) => onChange(value, "landscapes")}
          />
        </EuiFormRow>
        <div className="euiFormRow" id="i7e2f3913-0d0e-11ec-8d9a-e375a361c472-row">
          {maxVisits.isLoading ? <SimpleLoading />
            :
            <Fragment>
              <div className="euiFormRow__labelWrapper">
                <label className="euiFormLabel euiFormRow__label" htmlFor="i7e2f3913-0d0e-11ec-8d9a-e375a361c472">Visits #</label>
                <div className="">{`${filters?.visits ? filters?.visits[0].toLocaleString() || "-" : 0} - ${filters?.visits ? filters?.visits[1].toLocaleString() : maxVisits.value.toLocaleString() || "-"}`}</div>
              </div>
              <EuiDualRange
                id={htmlIdGenerator()()}
                min={0}
                // step={500} The value of 65237270997 is not included in the possible sequence provided by the step of 500.
                max={maxVisits.value || 1000}
                value={filters?.visits || [0, 0]}
                onChange={(value) => onChange(value, "visits")}
              />
            </Fragment>
          }
        </div>

      </EuiForm>
    </FFTemplate>
  )
}

export default FFSites