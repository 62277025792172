import APITitles from './APITitles';
import APILandscapes from './APILandscapes';
import APISites from './APISites';
import APICategories from "./APICategories"
import APIGlobals from './APIGlobals';

const apiBaseURL = window.API_URL;

export {
  apiBaseURL,
  APITitles,
  APILandscapes,
  APISites,
  APICategories,
  APIGlobals
}