



const HOptions = {
  site: {
    status: [
      { value: 'On', label: "Active" },
      { value: 'Off', label: "Inactive" },
      { value: 'Redirect', label: "Redirect" }
    ],
    _status: ['On', 'Off', 'Redirect'],
    classifications: [
      { id: 'Unknown', value: 'Unknown', label: "Unknown" },
      { id: 'Legal', value: 'Legal', label: "Legal" },
      { id: 'Illegal', value: 'Illegal', label: "Illegal" }
    ],
  },
  languages: [
    { value: 'pt-br', label: 'br' },
    { value: 'en', label: 'us' },
    { value: 'es', label: 'es' },
    { value: 'in', label: 'in' }
  ],
  status: [
    { value: 'On', label: "Active" },
    { value: 'Off', label: "Inactive" },
  ],
  recurrences: [
    { value: 'daily', label: "Daily" },
    { value: 'weekly', label: "Weekly" },
    { value: 'monthly', label: "Monthly" },
    { value: 'yearly', label: "Yearly" },
    // { value: 'days', text: "Days after..." },
    // { value: 'custom', text: "Custom" },
  ],
  title: {
    types: [
      { value: 'tv', label: 'tv' },
      { value: 'movie', label: 'movie' }
    ]
  },
  binaries: [
    { value: '0', label: 'No' },
    { value: '1', label: 'Yes' }
  ],
  generator(li: number, ls: number, label: string) {
    let array = []
    for (li; li <= ls; li++) {
      array.push({
        value: li,
        label: `${li} ${label}`,
      })
    }
    return array;
  }
}

export default HOptions

