import React from 'react';
import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutFooter,
  EuiButton
} from '@elastic/eui'

interface Props {
  isOpen?: boolean
  onClose: () => void
  readonly className?: string
  resetFilters: Function
  title?: string
  children: React.ReactNode
}

const FFTemplate: React.FC<Props> = ({ onClose, resetFilters, isOpen, title, children }) => {
  return isOpen ? (
    <EuiFlyout
      className="flyout-rigth bg-dark"
      ownFocus={false}
      // type="push"
      size="s"
      onClose={onClose}
    >
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="s">
          <div className="">{title}</div>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        {children}
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiButton color="secondary" onClick={() => typeof resetFilters === 'function' ? resetFilters() : null}>
          Clear all
        </EuiButton>
      </EuiFlyoutFooter>
    </EuiFlyout>
  ) : null
}

export default FFTemplate