import React from 'react';
import { EuiGlobalToastList } from '@elastic/eui';
import { ToastsContext } from '../../modules/toast';

const Toast = () => {
  const { toasts, removeToast } = React.useContext(ToastsContext);

  return (
    <>
      <EuiGlobalToastList
        toasts={toasts}
        //@ts-ignore
        dismissToast={removeToast}
        toastLifeTimeMs={6000}
      />
    </>
  )
}

export default Toast;