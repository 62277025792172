import React, { Fragment } from 'react';
import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFormRow,
  EuiDatePicker,
  EuiForm,
} from '@elastic/eui'
import { renderOption } from 'src/helpers/eui'
import HOptions from 'src/helpers/options'
import { IScanFilters, TScanFilter } from 'src/@types/IScan';
import FFTemplate from './FFTemplate';

interface Props {
  isOpen?: boolean
  onClose: () => void
  readonly className?: string,
  resetFilters?: () => void,
  filters?: IScanFilters | null,
  applyFilters?: Function,
  onChange?: (value: EuiComboBoxOptionOption | EuiComboBoxOptionOption[], type: TScanFilter) => void
}

const FFScan: React.FC<Props> = (props) => {
  const { filters, resetFilters, isOpen = false, onClose } = props;

  const onChange = (value: any, type: TScanFilter) => {
    if (typeof props.onChange === 'function')
      props.onChange(value, type)
  }

  return (
    <FFTemplate
      title='FILTERS/SCAN'
      onClose={onClose}
      isOpen={isOpen}
      resetFilters={() => typeof resetFilters === 'function' ? resetFilters() : null}
    >
      <EuiForm>
        <EuiFormRow label="Due date">
          <Fragment>
            <EuiDatePicker
              selected={filters?.startDueDate}
              onChange={(value) => onChange(value, "startDueDate")}
              placeholder="Start date"
            />
            <EuiDatePicker
              selected={filters?.endDueDate}
              onChange={(value) => onChange(value, "endDueDate")}
              aria-label="End date"
              placeholder="End date"
            />
          </Fragment>
        </EuiFormRow>
        <EuiFormRow label="Status">
          <EuiComboBox
            compressed
            renderOption={renderOption}
            sortMatchesBy="startsWith"
            placeholder="All"
            options={HOptions.site.status}
            selectedOptions={filters?.status}
            onChange={(value) => onChange(value, "status")}
            data-test-subj="demoComboBox"
          />
        </EuiFormRow>

        <EuiFormRow label="Scan date">
          <Fragment>
            <EuiDatePicker
              selected={filters?.startScanDate}
              // @ts-ignore
              onChange={(value) => onChange(value, "startScanDate")}
              placeholder="Start date"
            />
            <EuiDatePicker
              selected={filters?.endScanDate}
              // @ts-ignore
              onChange={(value) => onChange(value, "endScanDate")}
              aria-label="End date"
              placeholder="End date"
            />
          </Fragment>
        </EuiFormRow>
        <EuiFormRow label="Observations">
          <EuiComboBox
            compressed
            renderOption={renderOption}
            sortMatchesBy="startsWith"
            placeholder="All"
            singleSelection={{ asPlainText: true }}
            options={HOptions.binaries}
            selectedOptions={filters?.obs}
            onChange={(value) => onChange(value, "obs")}
            data-test-subj="demoComboBox"
          />
        </EuiFormRow>
        <EuiFormRow label="Colect">
          <EuiComboBox
            compressed
            renderOption={renderOption}
            sortMatchesBy="startsWith"
            placeholder="All"
            singleSelection={{ asPlainText: true }}
            options={HOptions.binaries}
            selectedOptions={filters?.colect}
            onChange={(value) => onChange(value, "colect")}
            data-test-subj="demoComboBox"
          />
        </EuiFormRow>
      </EuiForm>
    </FFTemplate>
  )
}

export default FFScan