import React, { FC, useEffect } from 'react'
import Home from './Home';
import Sites from './Sites';
import Titles from './Titles';
import Landscapes from './Landscapes';
import {
  EuiPage, EuiPageBody, EuiBreadcrumbs, EuiTitle, EuiPageContent,
  EuiFlexGroup, EuiFlexItem
} from '@elastic/eui';

interface Props {
  breadcrumbs: any,
  bulksLeft?: React.ReactNode,
  bulksRight?: React.ReactNode,
  children: React.ReactNode,
  id?: string
}

const PageTemplateTable: FC<Props> = (
  { breadcrumbs, bulksLeft, bulksRight, children, id = "pageLayout" }) => {

  useEffect(() => {

    const page = document.querySelector('.euiPage');

    if (page) {
      page.addEventListener('scroll', function () {
        if (page.scrollTop > 20) {
          page.classList.add('isFixed')
        } else {
          page.classList.remove('isFixed')
        }
      });
    }

  })

  return (
    <EuiPage paddingSize="none" id={id}>
      <EuiPageBody paddingSize="l">
        <EuiTitle size="s" className="euiTitle-page">
          <EuiBreadcrumbs
            breadcrumbs={breadcrumbs}
            truncate={false}
          />
        </EuiTitle>
        <EuiPageContent
          hasShadow={false}
          hasBorder={false}
          horizontalPosition="center"
          paddingSize="none"
          className="euiTable--page"
        >
          <EuiFlexGroup justifyContent="spaceBetween" className="actionsTable--bar">
            <EuiFlexItem grow={false}>
              {bulksLeft}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {bulksRight}
            </EuiFlexItem>
          </EuiFlexGroup>
          {children}
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage >
  )
}

export {
  Home,
  Sites,
  Titles,
  Landscapes,
  PageTemplateTable
}