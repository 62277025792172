import axios from 'axios';
import { IPaginationFetch } from '../../@types/IGlobal';
import { IScanSiteResult, IScanTitleResult, ISSPagination } from 'src/@types/IScan';
import { apiBaseURL } from '.';
import { ITitleResult, ITitlesPagination } from 'src/@types/ITitle';

const APITitles = {
  async getTitles({ page = 1, pageSize = 100, name, ordering, filters }: ITitlesPagination): Promise<ITitleResult> {
    try {
      let filter = ''
      if (name && name?.length > 0) filter += `&name=${name}`
      if (ordering && ordering?.length > 0) filter += `&ordering=${ordering}`
      if (filters?.clients && filters?.clients.length > 0) filter += `&clients=${encodeURIComponent(filters.clients)}`
      if (filters?.status && filters?.status.length > 0) filter += `&status=${encodeURIComponent(filters.status)}`
      if (filters?.start_date && filters?.start_date.length > 0) filter += `&start_date=${encodeURIComponent(filters.start_date)}`
      if (filters?.end_date && filters?.end_date.length > 0) filter += `&end_date=${encodeURIComponent(filters.end_date)}`

      const response = await axios.get(`${apiBaseURL}/titles?page=${page}&page_size=${pageSize}${filter}`);
      if (response.status >= 400 && response.status < 600) {
        throw new Error("Bad response from server");
      }

      //@ts-ignore
      const data: ITitleResult = response;
      return data;
    } catch (e) {
      throw new Error((e as TypeError).message);
    }
  },

  putTitle(titleId: number, body: Object) {
    return axios.put(`${apiBaseURL}/titles/${titleId}/`, body)
  },

  async getTitlesScan({ page = 1, pageSize = 100, name, ordering, expired }: IPaginationFetch): Promise<IScanTitleResult> {
    try {
      let filter = ''
      if (name && name?.length > 0) filter += `&name=${name}`
      if (ordering && ordering?.length > 0) filter += `&ordering=${ordering}`
      if (expired) filter += `&expired=${expired}`

      const response: Response = await fetch(`${apiBaseURL}/scantitles?page=${page}&page_size=${pageSize}${filter}`);
      if (response.status >= 400 && response.status < 600) {
        throw new Error("Bad response from server");
      }
      const data: IScanTitleResult = await response.json();
      return data;
    } catch (e) {
      throw new Error((e as TypeError).message);
    }
  },

  getSTitles({ titleId, pageSize, ordering }: ISSPagination): Promise<IScanTitleResult> {

    let filter = ''
    if (ordering && ordering?.length > 0) filter += `&ordering=${ordering}`

    return axios.get(`${apiBaseURL}/scantitles/?title=${titleId}&page_size=${pageSize}${filter}`)
  },

  putScanTitle(titleId: number, body: Object) {
    return axios.put(`${apiBaseURL}/scantitles/${titleId}/`, body)
  },

  async getSSites({ titleId, page, pageSize, domain, ordering, filters }: ISSPagination): Promise<IScanSiteResult> {
    try {
      let filter = ''
      if (domain && domain?.length > 0) filter += `&domain=${encodeURIComponent(domain)}`
      if (ordering && ordering?.length > 0) filter += `&ordering=${encodeURIComponent(ordering)}`

      if (filters) {
        if (filters?.status && filters?.status.length > 0) filter += `&status=${encodeURIComponent(filters.status)}`;
        if (filters?.colect && filters?.colect.length > 0) filter += `&colect=${encodeURIComponent(filters.colect)}`;
        if (filters?.startDueDate && filters?.startDueDate?.length > 0) filter += `&start_due_date=${encodeURIComponent(filters.startDueDate)}`;
        if (filters?.endDueDate && filters?.endDueDate.length > 0) filter += `&end_due_date=${encodeURIComponent(filters.endDueDate)}`;
        if (filters?.startScanDate && filters?.startScanDate.length > 0) filter += `&start_scan_date=${encodeURIComponent(filters.startScanDate)}`;
        if (filters?.endScanDate && filters?.endScanDate.length > 0) filter += `&end_scan_date=${encodeURIComponent(filters.endScanDate)}`;
        if (filters?.obs && filters?.obs.length > 0) filter += `&obs=${encodeURIComponent(filters.obs)}`;
      }

      const response: Response = await fetch(`${apiBaseURL}/scansites/title_scan/${titleId}?page=${page}&page_size=${pageSize}${filter}`);
      if (response.status >= 400 && response.status < 600) {
        throw new Error("Bad response from server");
      }
      const data: IScanSiteResult = await response.json();
      return data;
    } catch (e) {
      throw new Error((e as TypeError).message);
    }
  },

  postTitle(body: Object) {
    return axios.post(`${apiBaseURL}/titles/`, body)
  },

  getSlugs(slug: string) {
    return axios.get(`${apiBaseURL}/titles/slugs-list/?slug=${slug}`)
  },

  getSlug(slug: string) {
    return axios.get(`${apiBaseURL}/titles/slug/${slug}`)
  },
  getClients() {
    return axios.get(`${apiBaseURL}/titles/clients-list/`)
  },
  getMaxSitesAmount() {
    return axios.get(`${apiBaseURL}/titles/max-sites-amount/`)
  }
}

export default APITitles;