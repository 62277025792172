import { IDict } from "../@types/IGlobal"

export const EStatus = {
  ACTIVE: "On",
  INACTIVE: "Off",
  REDIRECT: "Redirect"
}

export const DCalendarMonth: IDict = {
  '01': 'JAN',
  '02': 'FEB',
  '03': 'MAR',
  '04': 'APR',
  '05': 'MAY',
  '06': 'JUN',
  '07': 'JUL',
  '08': 'AUG',
  '09': 'SEP',
  '10': 'OCT',
  '11': 'NOV',
  '12': 'DEC',
}
