import React, { useState } from 'react';

interface ISimpleToast {
  title: string;
  message: string;
}

type IToastColor = "danger" | "warning" | "primary" | "success";

interface IToast {
  id: string;
  title: string;
  color: IToastColor;
  text: string;
}

let toasts: IToast[] = [];

export const ToastsContext = React.createContext({
  toasts,
  addInfoToast: ({ title, message }: ISimpleToast) => { },
  addWarningToast: ({ title, message }: ISimpleToast) => { },
  addErrorToast: ({ title, message }: ISimpleToast) => { },
  addSuccessToast: ({ title, message }: ISimpleToast) => { },
  removeToast: (removedToast: IToast) => { }
});

export const ToastsProvider = ({ children }: any) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const addToast = ({ title, message, color }: ISimpleToast & { color: IToastColor }) => {
    setToasts([
      ...toasts,
      {
        id: `toast-${toasts.length}`,
        title,
        color,
        text: message
      }
    ])
  };
  const addInfoToast = ({ title, message }: ISimpleToast) => {
    addToast({ title, message, color: "primary" })
  };
  const addWarningToast = ({ title, message }: ISimpleToast) => {
    addToast({ title, message, color: "warning" })
  };
  const addErrorToast = ({ title, message }: ISimpleToast) => {
    addToast({ title, message, color: "danger" })
  };
  const addSuccessToast = ({ title, message }: ISimpleToast) => {
    addToast({ title, message, color: "success" })
  };
  const removeToast = (removedToast: IToast) => {
    setToasts(toasts.filter((toast: IToast) => toast.id !== removedToast.id))
  };

  return (
    <ToastsContext.Provider
      value={{
        toasts,
        addInfoToast,
        addWarningToast,
        addErrorToast,
        addSuccessToast,
        removeToast
      }}
    >
      {children}
    </ToastsContext.Provider>
  )
}
