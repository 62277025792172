import { FC, useState } from 'react'
import {
  EuiFlexGroup,
  EuiFieldText,
  EuiFormControlLayout,
  EuiButtonEmpty
} from '@elastic/eui'
import { FaSearch } from 'react-icons/fa'

interface Props {
  id?: string,
  query: string,
  onKeyUp?: (event: KeyboardEvent) => void,
  onChange: (event: any) => void
  onClear: () => void
  callbackToggle?: () => void
  readonly hasAdvancedSearch?: boolean
}

const SearchInput: FC<Props> = ({ id = "search", query, onChange, onClear, onKeyUp, callbackToggle, hasAdvancedSearch }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onToggle = () => {
    setIsOpen(isOpen => !isOpen)
  }

  return (
    <EuiFlexGroup className="searchGroup">
      <EuiFormControlLayout
        clear={query ? { onClick: onClear } : undefined}
        icon={{ type: '', side: 'right' }}
        style={{ display: isOpen ? "" : 'none' }}
      >
        <EuiFieldText
          id={id}
          autoComplete="Off"
          placeholder="Search this"
          className={isOpen ? "searchInput" : ""}
          value={query}
          onKeyUp={(event: any) => typeof onKeyUp === 'function' ? onKeyUp(event) : null}
          onChange={onChange}
          autoFocus
        />
        {hasAdvancedSearch && <span className="searhAdvanced--Label" onClick={callbackToggle}>Advanced search</span>}
      </EuiFormControlLayout>
      <EuiButtonEmpty className={isOpen ? "search--icon" : ""} onClick={onToggle} ><FaSearch className={query ? "text--success" : ""} /></EuiButtonEmpty>
    </EuiFlexGroup>
  )
}


export default SearchInput