import { useEffect, useState, useContext, useRef, useCallback, Fragment } from 'react';
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiBadge,
  EuiBasicTableColumn,
  EuiTableSortingType,
  Direction,
  Pagination,
  EuiTableSelectionType,
  CriteriaWithPagination,
  EuiComboBoxOptionOption,
  EuiLink,
  EuiSpacer,
  EuiButtonEmpty,
  EuiCheckboxGroupOption,
  EuiPopover,
  EuiIcon,
  EuiButton,
} from '@elastic/eui';
import moment from 'moment';
import debounce from 'lodash/debounce';
import _ from 'lodash'
import { APILandscapes, APITitles } from '../../services/api';
import { ToastsContext } from '../../modules/toast';
import { HBreadcrumbs, HTable } from '../../helpers';
import BulkSelecteds from '../../components/Bulks/BulkSelecteds';
import BulkExport from '../../components/Bulks/BulkExport';
import { AddLandscapesButton } from '../Landscapes';
import SearchInput from '../../components/SearchInput';
import { IDict, IString, TRender } from '../../@types/IGlobal';
import Progress from '../../components/Progress';
import MTitle from 'src/components/Modals/MTitle';
import { ImFilm, ImFileText2 } from 'react-icons/im'
import FFTitles from 'src/components/Flyouts/FFilters/FFTitles';
import { RiFilter2Fill } from 'react-icons/ri';
import MDetails from 'src/components/Modals/MDetails';
import { DStatus, DTypeColors } from 'src/helpers/dictionaries';
import DatePickerRecur from 'src/components/DatePicker/DatePickerRecur';
import { formatDate } from 'src/services/format';
import STitles from '../Scan/STitles';
import { ITitle, ITitleResult, ITitlesFilters, ITitlesFiltersString, ITitlesPagination, TTitleFilter } from 'src/@types/ITitle';
import { ILandscape } from 'src/@types/ILandscape';
import { RowLandscapes } from '../../components/Rows'
import { PageTemplateTable } from '..';
import BFilter from 'src/components/Badges/BFilter';
import HOptions from 'src/helpers/options';
import PopoverOptions from 'src/components/Popovers/PopoverOptions';
import { BiDotsHorizontalRounded } from 'react-icons/bi';


type IDictOfType<T> = {
  [key in keyof T]: T[key]
}
type IForm<T> = {
  isDirty: () => boolean;
  originalValues: IDictOfType<T>;
  fields: IDictOfType<T>
}

class Form<T> implements IForm<T> {

  public fields: IDictOfType<T>;
  public originalValues: IDictOfType<T>;
  private _isDirty: boolean = false;
  public id: T[keyof T];

  constructor({ fields, idField }: { fields: IDictOfType<T>, idField: keyof T }) {
    this.fields = { ...fields };
    this.originalValues = { ...fields };
    this._isDirty = false;
    this.id = fields[idField];
  }

  public isDirty() {
    return this._isDirty;
  }

  public setValue({ field, value }: { field: keyof T; value: any }) {
    this.fields[field] = value;
    this._isDirty = true;
  }

  public resetValues() {
    this.fields = { ...this.originalValues };
    this._isDirty = false;
  }
}

interface ILoading {
  store: boolean
  export: boolean
}

interface IEdit {
  field: string | null
  id: number | null,
  isLoading: boolean
}


const Titles = (props: any) => {

  const {
    match,
    isExpaned = false,
  } = props;

  const toastsContext = useContext(ToastsContext);
  const tableRef = useRef<EuiBasicTable>(null);


  const [loading, setLoading] = useState<ILoading>({
    store: true,
    export: false
  });

  const [item, setItem] = useState<ITitle | undefined>(undefined)

  const [store, setStore] = useState<ITitleResult>({
    results: [],
    count: 0
  });
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(HTable.pageSize);
  const [sortField, setSortField] = useState<keyof IDictOfType<ITitle>>('id');
  const [sortDirection, setSortDirection] = useState<Direction>('asc');

  const [selectedItems, setSelectedItems] = useState<ITitle[]>([]);
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<IDict>({});

  const [render, setRender] = useState<TRender>(null)
  const [edit, setEdit] = useState<IEdit>({ field: null, id: null, isLoading: false })


  const [titles, setTitles] = useState<ITitle[]>([]);
  const [query, setQuery] = useState<string>("")
  const [csv, setCsv] = useState<IString[]>([]);
  const [filters, setFilters] = useState<ITitlesFilters | undefined>(undefined)


  const selectedFilters: ITitlesFiltersString | undefined = filters ? {
    status: filters.status?.map((item) => item.value).toString(),
    types: filters.types?.map((item) => item.value).toString(),
    clients: filters.clients?.map((item) => item.value).toString(),
    start_date: filters.start_date ? moment(filters.start_date).format('YYYY-MM-DD') : undefined,
    end_date: filters.end_date ? moment(filters.start_date).format('YYYY-MM-DD') : undefined,
    sites_amount_lower: filters.sites_amount && filters.sites_amount.length > 0 ? filters.sites_amount[0].toString() : undefined,
    sites_amount_upper: filters.sites_amount && filters.sites_amount.length > 0 ? filters.sites_amount[1].toString() : undefined,
  } : undefined

  const rows = item ? [
    {
      label: 'Name',
      value: item.name
    },
    {
      label: 'Due date',
      value: formatDate(item.due_date, 'dobLong')
    },
    {
      label: 'Start date',
      value: formatDate(item.start_date, 'dobLong')
    },
    {
      label: 'End date',
      value: formatDate(item.end_date, 'dobLong')
    },
    {
      label: 'Scan progress',
      value: (item.sites_amount > 0 ? ((item?.scan_progress) / item.sites_amount * 100).toFixed(2) : '0.00') + ' %'
    },
    // {
    //   label: 'Last scan site updated',
    //   value: formatDate(item.last_scan_site_updated, 'dobLong')
    // },
    {
      label: 'Client',
      value: item.client
    },
    {
      label: 'Id',
      value: item.id
    },
    {
      label: 'Created  at',
      value: formatDate(item.created_at, 'dobLong')
    },
    {
      label: '# Sites',
      value: item.sites_amount
    },
  ] : null

  const hasSelecteds = selectedItems?.length !== 0

  // const DataContext = createContext();

  const columns: EuiBasicTableColumn<ITitle>[] = [
    {
      field: 'name',
      name: 'Name',
      className: "cellWithRightBorder titles",
      sortable: true,
      mobileOptions: {
        render: (item: ITitle) => (
          <EuiLink target="_blank">
            {item.name}
          </EuiLink>
        ),
        header: false,
      },
      render: (name: string, row: ITitle) =>
        <EuiFlexGroup direction="column" gutterSize="none" className="p-15" >
          <EuiFlexItem>
            <EuiLink href={`/#/titles/${row.id}`} onClick={() => localStorage.setItem("TITLE", name)} >
              {name}
            </EuiLink>
          </EuiFlexItem>
          <EuiFlexGroup direction="row" alignItems="center">
            <EuiFlexItem grow={false} className={`status ${HOptions.site._status.includes(row.status) ? DStatus[row.status] : '-'}`}>
              {HOptions.site._status.includes(row.status) ? DStatus[row.status] : '-'}
            </EuiFlexItem>
            {row.client && <div className="barHorizontal" />}
            {row.client && <EuiSpacer style={{ height: "20px", backgroundColor: "var(--table-color)" }} />}
            {row.client && <EuiFlexItem className="text--white">{row.client}</EuiFlexItem>}
          </EuiFlexGroup>
        </EuiFlexGroup>,
    },

    {
      field: 'sites_amount',
      name: '# OF SITES',
      align: 'center',
      render: (sites_amount: number) => sites_amount,
      sortable: true,
    },
    {
      field: "type",
      name: 'Type',
      align: 'center',
      sortable: true,
      truncateText: true,
      render: (type: string, row: ITitle) =>
        <EuiBadge className="title--type" color={DTypeColors[type]}>
          {type}
        </EuiBadge>
    },
    {
      field: "assigned",
      name: "ASSIGNED",
      align: "center",
    },
    {
      field: 'scan_progress',
      name: 'HAS TITLE',
      align: 'center',
      render: (scan_progress: number, row: ITitle) => <Progress value={scan_progress} max={row.sites_amount} />,
      sortable: true,
    },
    {
      field: 'due_date',
      name: 'DUE DATE',
      className: 'overflow',
      align: "center",
      render: (due_date: string | null, row: ITitle) => {
        return (
          <DatePickerRecur
            readOnly={isExpaned}
            startDate={row.start_date}
            isForever={row.forever}
            recurrency={row.recurrency}
            dueDate={due_date}
            endDate={row.end_date}
            onSubmit={(data, callback) => updateTitle(row.id, data, callback)}
            isLoading={edit.isLoading}
          />
        )
      },
      sortable: true,
    },
    {
      field: "landscapes",
      align: "center",
      name: 'LANDSCAPES',
      render: (landscapes: Array<ILandscape>, row: ITitle) =>
        <RowLandscapes
          landscapes={landscapes}
          onMoreClick={() => {
            setTitles([row])
            onRender('add', row)
          }}
          readonly={isExpaned}
        />
    },
    {
      name: 'EDIT',
      align: "center",
      render: (item: ITitle) => {
        return (
          <PopoverOptions
            panelClassName="optionsTable"
            anchorPosition="leftCenter"
            onChange={(key) => onRender(key, item)}
            button={
              <BiDotsHorizontalRounded />
            }
            options={
              [
                {
                  label: <Fragment><EuiIcon className="euiIcon" type="documentEdit" /> Edit Title</Fragment>,
                  value: 'edit'
                },
                {
                  label: <Fragment><ImFileText2 className="euiIcon" /> Details</Fragment>,
                  value: 'details'
                }
              ]
            }
          />
        )
      }
    }
  ];

  const onUpdateFilters = (value: any, key: TTitleFilter) => {
    setFilters({ ...filters, [key]: value })
  }

  const onRender = (key?: TRender, item?: ITitle) => {
    if (key && render) {
      setRender(null)
      return
    }
    if (key) {
      setRender(key)
      if (item) setItem(item)
      return
    }

    setItem(undefined);
    setRender(null)
  }

  const onTableChange = ({ page = { index: 0, size: 5 }, sort = { direction: 'asc', field: 'id' } }: CriteriaWithPagination<IDictOfType<ITitle>>) => {
    const { index: pageIndex, size: pageSize } = page;

    const { field: sortField, direction: sortDirection } = sort;

    const ordering = sortDirection === "asc" ? `-${sortField}` : sortField

    getTitles({ page: pageIndex + 1, pageSize, ordering, query })

    setPageIndex(pageIndex);
    setPageSize(pageSize);
    setSortField(sortField);
    setSortDirection(sortDirection);
  };

  const onSelectionChange = (selectedItems: ITitle[]) => {
    //@ts-ignore
    setTitles(setSelectedItems)

    let copy = _.cloneDeep(selectedItems)
    copy.map((data: ITitle) => {
      //@ts-ignore
      data.landscapes = data.landscapes.map((item: any) => item.name).toString()
    })
    //@ts-ignore
    setCsv(copy)

    setSelectedItems(selectedItems);
  };

  const renderBulksLeft = () => {
    return (
      <EuiFlexGroup className="euiTable-actions" gutterSize="none">
        <BulkSelecteds
          onClick={() => {
            onSelectionChange([]);
            tableRef.current?.clearSelection();
          }}
          total={selectedItems.length}
          type="Titles"
        />
        <SearchInput
          id="searchInputTitles"
          query={query}
          onChange={(e) => setQuery(e.target.value)}
          onClear={() => setQuery('')}
        />
        <EuiButtonEmpty onClick={() => onRender('filters')}>
          <RiFilter2Fill />
        </EuiButtonEmpty>
        <EuiFlexGroup className="filters--badges">
          {filters && Object.keys(filters).map((key) => {
            const items = filters[key as TTitleFilter]

            switch (key as TTitleFilter) {
              case 'sites_amount':
                if (filters.sites_amount) {
                  return (
                    <BFilter
                      type={key}
                      // @ts-ignore
                      filter={`${sites_amount_lower} - ${sites_amount_upper} `}
                      // @ts-ignore
                      onClear={() => deleteFilters(key as TTitleFilter, key)}
                    />
                  )
                }
                break
              case 'end_date':
              case 'start_date':
                if (items) return (
                  <BFilter
                    type={key}
                    // @ts-ignore
                    filter={moment(items).format('YYYY-MM-DD')}
                    // @ts-ignore
                    onClear={() => deleteFilters(key as TTitleFilter, key)}
                  />
                )
                break

              default:
                // @ts-ignore
                return items.map(el => {
                  return el.hasOwnProperty('label') ? (
                    <BFilter type={key} filter={el.label} onClear={() => deleteFilters(key as TTitleFilter, el)} />
                  ) : null
                })
            }
          }
          )}
        </EuiFlexGroup>
      </EuiFlexGroup>
    )
  }


  const renderBulksRight = () => (
    <EuiFlexGroup className="bulk--actions" >
      {hasSelecteds &&
        <AddLandscapesButton
          onClick={() => {
            setTitles(selectedItems)
            onRender('bulk')
          }}
        />
      }
      {/* @ts-ignore */}
      <BulkExport hasSelecteds={hasSelecteds} onClick={() => getTitles({ pageSize: Number.MAX_SAFE_INTEGER })} filename="titles" data={csv} isLoading={loading.export} />
    </EuiFlexGroup>
  )

  const toggleDetails = async (id: number, isOpen?: boolean) => {
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap }; //deep copy

    if (itemIdToExpandedRowMapValues[id] && !isOpen) {
      delete itemIdToExpandedRowMapValues[id];
    } else {

      itemIdToExpandedRowMapValues[id] = (
        <STitles match={{ params: { id } }} />
      );
    }

    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues)
  };

  const [pageOfItems] = [store?.results];
  const pagination: Pagination = {
    pageIndex,
    pageSize,
    totalItemCount: store?.count || 0,
    pageSizeOptions: HTable.pagination,
  };

  const sorting: EuiTableSortingType<ITitle> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  const selection: EuiTableSelectionType<ITitle> = {
    selectable: (item: ITitle) => true,
    selectableMessage: (selectable: boolean) =>
      !selectable ? 'User is currently offline' : '',
    onSelectionChange: onSelectionChange
  };

  const updateTitle = debounce(async (id: number, data: Object, callback: Function) => {
    setEdit({ ...edit, isLoading: true })
    try {
      const response = await APITitles.putTitle(id, data)

      const index = store?.results.findIndex((item: ITitle) => item.id === response.data.id)

      store.results[index] = response.data

      if (index)
        setStore({ ...store, results: store.results })

      if (itemIdToExpandedRowMap[id]) {
        toggleDetails(id, true)
      }

      if (typeof callback === 'function')
        callback()

      setEdit({ ...edit, isLoading: false })

    } catch (e: any) {
      setEdit({ ...edit, isLoading: false })
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete',
        message: (e as Error).toString()
      });
    }
  }, 1000)

  const getTitles = async ({ page, pageSize, query, ordering, filters }: ITitlesPagination) => {
    const isExport = pageSize === Number.MAX_SAFE_INTEGER
    try {
      if (isExport) {
        setLoading({ ...loading, export: true })
        setCsv([])
      } else setLoading({ ...loading, store: true });

      let response;

      if (match?.params?.id) {
        response = await APILandscapes.getTitles(match.params.id);
      } else {
        response = await APITitles.getTitles({
          page: page || pageIndex + 1,
          pageSize: pageSize || HTable.pageSize,
          name: query ? encodeURIComponent(query) : undefined,
          ordering,
          filters: filters || selectedFilters
        });

      }
      if (isExport) {
        {/* @ts-ignore */ }
        response.results.forEach((data) => {
          {/* @ts-ignore */ }
          data.landscapes = data.landscapes.map((item: ILandscape) => item.name).toString()
        })
        {/* @ts-ignore */ }
        setCsv(response.results)
        setLoading({ ...loading, export: false })

        return;
      }

      {/* @ts-ignore */ }
      setStore(response.data);
      setLoading({ ...loading, store: false })
    } catch (e: any) {
      isExport ? setLoading({ ...loading, export: false }) : setLoading({ ...loading, store: true });
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete title resquest',
        message: (e as Error).toString()
      });
    }
  }

  const deleteFilters = (key: TTitleFilter, item?: EuiComboBoxOptionOption | EuiCheckboxGroupOption | string) => {
    if (filters) {
      switch (key) {
        case 'sites_amount':
        case 'start_date':
        case 'end_date':
          setFilters({ ...filters, [key]: undefined })
          break;

        default:
          if (typeof item === 'object') {
            // @ts-ignore
            const index = filters[key]?.findIndex((q) => q.value?.toString() === item?.value?.toString())

            if (index === 0 && Array.isArray(filters[key]) && filters[key]?.length === 0) {
              setFilters({ ...filters, [key]: [] })
              return
            }
            if (index || index === 0) {
              filters[key]?.splice(index, 1)
              setFilters({ ...filters, [key]: filters[key] })
            }
          }
          break;
      }
    }
  }

  const debounceTitles = useCallback(
    debounce((params) => {
      getTitles(params)
    }, 1000),
    []
  );

  const onListenerStatus = () => {
    const rows = document.querySelectorAll(".cellWithRightBorder.titles.euiTableRowCell--hideForMobile");
    store?.results.forEach((item, index) => {
      rows[index].classList.add(`${HOptions.site._status.includes(item.status) ? DStatus[item.status] : ''}`)
    })
  }

  useEffect(() => {
    onListenerStatus();
  }, [store, match])

  useEffect(() => {
    const addTitleBtn = document.querySelector("#addTitleBtn")
    addTitleBtn?.addEventListener("click", () => {
      debounceTitles({ filters: selectedFilters, page: pageIndex + 1, pageSize, query })
    })
  })

  useEffect(() => {
    debounceTitles({ filters: selectedFilters, page: pageIndex + 1, pageSize, query })
  }, [filters, query])

  useEffect(() => {
    getTitles({ pageSize });
  }, [])

  const bulksLeft = renderBulksLeft()
  const bulksRight = renderBulksRight()

  const table = (
    <EuiBasicTable
      ref={tableRef}
      items={pageOfItems || []}
      itemId="id"
      tableLayout="auto"
      itemIdToExpandedRowMap={itemIdToExpandedRowMap}
      hasActions={!isExpaned}
      loading={loading.store}
      columns={columns}
      pagination={pagination}
      sorting={(!isExpaned && !loading.store) ? sorting : undefined}
      isSelectable={!isExpaned}
      selection={!isExpaned ? selection : undefined}
      onChange={onTableChange}
      noItemsMessage={<EuiFlexItem className="p-30">No items found</EuiFlexItem>}
    />
  )


  return (
    <Fragment>
      {isExpaned ? table
        :
        <PageTemplateTable
          id="titles-page"
          breadcrumbs={HBreadcrumbs.TITLES}
          bulksLeft={bulksLeft}
          bulksRight={bulksRight}
        >
          {table}
        </PageTemplateTable>
      }
      {((render === 'edit' || render === 'bulk' || render === 'add') && (item || titles)) &&
        <MTitle
          onClose={onRender}
          render={render}
          item={item as ITitle}
          titles={titles}
          callback={(data: any) => {
            switch (render) {
              case 'add':
              case 'edit':
                const title = data;
                const index = store?.results.findIndex((item: ITitle) => item.id === title.id)

                const rows = document.querySelectorAll(".cellWithRightBorder.euiTableRowCell--hideForMobile")
                rows[index].classList.remove(`${DStatus[store.results[index].status]}`);

                store.results[index] = { ...store.results[index], ...title }
                rows[index].classList.add(`${DStatus[title.status]}`);

                setStore({ ...store, results: store.results })
                break;
              case 'bulk':
                getTitles({ page: pageIndex + 1, pageSize, query })
                break;
              default:
                break;
            }

          }}
        />
      }
      {render === 'details' && <MDetails title="Details - Titles" onClose={onRender} rows={rows} />}
      <FFTitles
        isOpen={render === 'filters'}
        onClose={onRender}
        onChange={onUpdateFilters}
        filters={filters}
        resetFilters={() => setFilters(undefined)}
      />
    </Fragment>
  );
};

export default Titles;