import { ReactElement, useState, useContext, useRef } from 'react';
import {
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiFlexItem, EuiLink, EuiTab, EuiTabs,
  EuiPage, EuiSpacer, EuiTabbedContent, EuiPageBody, EuiPageContent, EuiTitle
} from '@elastic/eui';
import { APIGlobals, APITitles } from 'src/services/api';
import { useEffect } from 'react';
import { IScanTitle, IScanTitleResult } from 'src/@types/IScan';
import Progress from 'src/components/Progress';
import { ToastsContext } from 'src/modules/toast';
import { ILogResult } from 'src/@types/IGlobal';
import { SimpleLoading } from 'src/components/Loaders/SimpleLoading';
import { Fragment } from 'react';
import { DStatus, DTypeColors } from 'src/helpers/dictionaries';
import { formatDate } from 'src/services/format';

const Home = () => {
  const [store, setStore] = useState<IScanTitleResult>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pageOfItems] = [store?.results];
  const toastsContext = useContext(ToastsContext);
  const [logs, setLogs] = useState<ILogResult>({ isLoading: true, results: [] })

  const columns: EuiBasicTableColumn<IScanTitle>[] = [
    {
      field: 'name',
      name: 'Name',
      className: "cellWithRightBorder Active",
      sortable: true,
      // mobileOptions: {
      //   render: (name: null, row: ITitleScan) => (
      //     <span>
      //       {JSON.stringify(row.title.name)}
      //     </span>
      //   ),
      //   header: false,
      //   //@ts-ignore
      //   truncateText: false,
      //   enlarge: true,
      //   fullWidth: true,
      // },
      render: (name: string, row: IScanTitle) =>
        <EuiFlexGroup direction="column" gutterSize="none" className="p-15" >
          <EuiFlexItem>
            <EuiLink href={`#/titles/${row.id}/scans`} onClick={() => localStorage.setItem("TITLE", row.title.name)} >
              {row.title.name}
            </EuiLink>
          </EuiFlexItem>
          <EuiFlexGroup direction="row" alignItems="center">
            <EuiFlexItem grow={false} className={DStatus['On']}>
              {DStatus['On']}
            </EuiFlexItem>
            {row.client && <EuiSpacer style={{ height: "20px", backgroundColor: "var(--table-color)" }} />}
            {row.client && <EuiFlexItem className="text--white">{row.client}</EuiFlexItem>}
          </EuiFlexGroup>
        </EuiFlexGroup>,
    },

    {
      field: 'sites_amount',
      name: '# OF SITES',
      align: 'center',
      render: (sites_amount: number) => sites_amount,
      sortable: true,
    },
    {
      field: "type",
      name: 'Type',
      align: 'center',
      sortable: true,
      truncateText: true,
      render: (type: null, row: IScanTitle) =>
        <EuiBadge className="title--type" color={DTypeColors[row.title.type]}>
          {row.title.type}
        </EuiBadge>
    },
    {
      field: "assigned",
      name: "ASSIGNED",
      align: "center",
    },
    {
      field: 'scan_progress',
      name: 'HAS TITLE',
      align: 'center',
      render: (scan_progress: number, row: IScanTitle) => <Progress value={row.scan_progress} max={row.sites_amount} />,
      sortable: true,
    },
    {
      field: 'due_date',
      name: 'DUE DATE',
      className: 'overflow',
      align: "center",
      render: (due_date: string | null, row: IScanTitle) => formatDate(due_date, 'dobLong'),
      sortable: true,
    }
  ];


  const [selectedTabId, setSelectedTabId] = useState("todo")

  const tabs = [
    { id: "0", name: 'To do' },
    { id: "1", name: 'Done' }
  ];

  const getTitlesScan = async (tabId: string) => {
    setStore({ results: [] })
    setIsLoading(true)
    try {
      const response = await APITitles.getTitlesScan({
        page: 1,
        pageSize: 4,
        expired: tabId
      });

      setStore(response)
      setSelectedTabId(tabId)
      setIsLoading(false)
    }
    catch (err: any) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete scan title request',
        message: (err as Error).toString()
      });
    }
  }
  const getLogs = async () => {
    try {
      const response = await APIGlobals.getLogs();
      setLogs({ isLoading: false, results: response.data.results})
    }
    catch (e: any) {
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete logs request',
        message: (e as Error).message
      });
    }
  }

  const onSelectedTabChanged = (id: string) => {
    setSelectedTabId(id);
    getTitlesScan(id)
  };

  const renderTabs = () => {
    return tabs.map((tab, index) => (
      <EuiTab
        // {...(tab.href && { href: tab.href, target: '_blank' })}
        onClick={() => onSelectedTabChanged(tab.id)}
        isSelected={tab.id === selectedTabId}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  useEffect(() => {
    getTitlesScan("0")
    getLogs()
    localStorage.setItem("PAGE", "#")
  }, [])

  return (
    <EuiPage id="home-page">
      <EuiPageBody paddingSize="l">
        <EuiTitle>
          <div className="">My Work</div>
        </EuiTitle>
        <EuiPageContent
          hasShadow={false}
          hasBorder={false}
          horizontalPosition="center"
          paddingSize="none"
          className="euiTable--page"
        >
          <EuiTabs>{renderTabs()}</EuiTabs>
          <EuiBasicTable
            // ref={tableRef}
            items={pageOfItems || []}
            itemId="id"
            tableLayout="fixed"
            // itemIdToExpandedRowMap={itemIdToExpandedRowMap}
            loading={isLoading}
            columns={columns}
            // pagination={pagination}
            // sorting={sorting}
            isSelectable={true}
            // selection={selection}
            // onChange={onTableChange}
            noItemsMessage={<EuiFlexItem className="p-30">No items found</EuiFlexItem>}
          />
          {logs.isLoading ? <SimpleLoading />
            :
            <Fragment>
              <EuiTitle className="logs-Title" >
                <div className="">Activity Feed</div>
              </EuiTitle>
              <EuiFlexGroup direction="column" gutterSize="s" className="logs">
                {logs.results.map((item, index) => {
                  return (
                    <EuiFlexItem key={index} >
                      {`${item.action} the ${item.obj}`} <span>{item.name}</span> {`@${item.created_at}`}
                    </EuiFlexItem >
                  )
                })
                }
              </EuiFlexGroup>
            </Fragment>
          }
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Home;
