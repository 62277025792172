import styled from 'styled-components';

export const SidebarNavItem = styled.li`
  width: 30px;
  height: 50px;
  border-radius: 22px;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 5px;
  .euiIcon {
    color: var(--gray-color-1);
  }
  &[data-active="true"]{
    background: var(--secondary-color);
    .euiIcon {
      color: var(--gb-color);
      fill: var(--gb-color);
    }
  }
  &[data-active="false"]&:hover {

    .euiIcon {
      color: #fff;
    } 
  }

  .euiIcon--inherit {
    color: var(--bg-color);
  }
`

export const SidebarNavGroup = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px 15px;
  padding-top: calc(var(--h-header) + 10px);

`