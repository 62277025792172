import React, { Fragment } from 'react'
import { EuiBadge, EuiCheckboxGroupOption, EuiComboBoxOptionOption, EuiToolTip, EuiFieldText } from '@elastic/eui';

interface Props {
  filter?: string
  type: string
  onClear?: (filter: EuiComboBoxOptionOption | EuiCheckboxGroupOption | string) => void
}

const BFilter: React.FC<Props> = ({ filter, type, onClear }) => {
  return filter ? (
    <EuiToolTip
      position="top"
      content={type}
    >
      <EuiBadge
        color="secondary"
        iconType="cross"
        iconSide="right"
        iconOnClick={() => typeof onClear === 'function' ? onClear(filter) : null}
        iconOnClickAriaLabel={type}
      >
        {filter}
      </EuiBadge>
    </EuiToolTip>

  ) : null
}

export default BFilter