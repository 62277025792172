import React, { useEffect, useState, Fragment, useContext, useRef, FC, useCallback } from 'react';
import { formatDate } from '../../services/format';

import {
  EuiBasicTable,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,

  EuiBasicTableColumn,
  EuiTableSortingType,
  Direction,
  Pagination,
  EuiTableSelectionType,
  CriteriaWithPagination,
  EuiText,
  EuiButtonEmpty,
  EuiComboBoxOptionOption,
  EuiCheckboxGroupOption

} from '@elastic/eui';

import debounce from 'lodash/debounce';

import { ILandscape, ILandscapeExport, ILandscapeResult, ILandscapesFilters, ILandscapesFiltersString, ILandscapesPagination, TLandscapesFilter } from '../../@types/ILandscape';
import { APILandscapes } from '../../services/api';
import { ToastsContext } from '../../modules/toast';
import { EuiIcon } from '@elastic/eui';
import { HBreadcrumbs, HTable } from '../../helpers';
import BulkSelecteds from '../../components/Bulks/BulkSelecteds';
import BulkExport from '../../components/Bulks/BulkExport';
import { PageTemplateTable, Titles } from '..';
import SearchInput from '../../components/SearchInput';
import { TRender, } from '../../@types/IGlobal';
import MLandscape from 'src/components/Modals/MLandscape';
import MConfirm from 'src/components/Modals/MConfirm';
import { IScanTitle } from 'src/@types/IScan';
import { RiFilter2Fill } from 'react-icons/ri';
import FFLandascapes from 'src/components/Flyouts/FFilters/FFLandscapes';
import MDetails from 'src/components/Modals/MDetails';
import { DStatus } from 'src/helpers/dictionaries';
import BFilter from 'src/components/Badges/BFilter';
import { ImFileText2, ImFilm } from 'react-icons/im';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import PopoverOptions from 'src/components/Popovers/PopoverOptions';

const RIGHT_ALIGNMENT = 'right';

interface Dict {
  [key: string]: any
}


export const AddLandscapesButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <EuiLink
      color="text"
      onClick={() => typeof onClick === 'function' ? onClick() : null}
    >
      <EuiIcon type="visMapRegion" /> Add to landscapes
    </EuiLink>
  )
}

interface ILoading {
  store: boolean,
  export: boolean,
}


const Landscapes = () => {
  const tableRef = useRef<EuiBasicTable>(null);
  const toastsContext = useContext(ToastsContext);
  const [loading, setLoading] = useState<ILoading>({
    store: true,
    export: false,
  });
  const [store, setStore] = useState<ILandscapeResult>({
    results: [],
    count: 0
  });
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(HTable.pageSize);
  const [sortField, setSortField] = useState<keyof ILandscape>('id');
  const [sortDirection, setSortDirection] = useState<Direction>('asc');
  const [selectedItems, setSelectedItems] = useState<ILandscape[]>([]);
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<Dict>({});
  const [query, setQuery] = useState<string>("");
  const [csv, setCsv] = useState<ILandscapeExport[]>([]);
  const [item, setItem] = useState<ILandscape | null>(null)

  const [filters, setFilters] = useState<ILandscapesFilters | undefined>(undefined)

  let selectedFilters: ILandscapesFiltersString | undefined = filters ? {
    status: filters.status?.map((item) => item.value).toString() || undefined,
    titles: filters.titles?.map((item) => item.value).toString() || undefined,
    sites_count_lower: filters.sites_count ? filters.sites_count[0].toString() : undefined,
    sites_count_upper: filters.sites_count ? filters.sites_count[1].toString() : undefined,
    titles_count_lower: filters.titles_count ? filters.titles_count[0].toString() : undefined,
    titles_count_upper: filters.titles_count ? filters.titles_count[1].toString() : undefined,
    domains: filters.domains?.map((item) => item.value).toString().replace(/,/g, '|'),
  } : undefined

  const [render, setRender] = useState<TRender>(null)

  const rows = item ? [
    {
      label: 'Name',
      value: item.name
    },
    {
      label: 'Id',
      value: item.id
    },
    {
      label: 'Created at',
      value: formatDate(item.created_at, 'dobLong')
    },
    {
      label: '# Titles',
      value: item.titles_count
    },
    {
      label: '# Sites',
      value: item.sites_count
    },
    {
      label: 'Last san Title updated',
      value: formatDate(item.updated_at, 'dobLong')
    }
  ] : null

  const hasSelecteds = selectedItems.length !== 0

  const onTableChange = ({ page = { index: 0, size: 5 }, sort = { direction: 'asc', field: 'id' } }: CriteriaWithPagination<ILandscape>) => {
    const { index: pageIndex, size: pageSize } = page;

    const { field: sortField, direction: sortDirection } = sort;

    const ordering = sortDirection === "asc" ? `-${sortField}` : sortField

    getLandscapes({ page: pageIndex + 1, pageSize, ordering, name: query, filters: selectedFilters })

    setPageIndex(pageIndex);
    setPageSize(pageSize);
    setSortField(sortField);
    setSortDirection(sortDirection);
  };

  const toggleDetails = async (id: number, isOpen?: boolean) => {
    const itemIdToExpandedRowMapValues = Object.assign({}, itemIdToExpandedRowMap); //deep copy

    if (itemIdToExpandedRowMapValues[id] && isOpen) {
      delete itemIdToExpandedRowMapValues[id];
    } else {
      itemIdToExpandedRowMapValues[id] = (
        <Titles
          match={{ params: { id } }}
          isExpaned={true}
        />
      );
    }

    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues)
  };

  const [pageOfItems] = [store?.results];

  const columns: EuiBasicTableColumn<ILandscape>[] = [
    {
      field: 'name',
      name: 'Name',
      sortable: true,
      className: "cellWithRightBorder",
      mobileOptions: {
        render: (item: ILandscape) => (
          <span>
            {item.name}
          </span>
        ),
        header: false,
        //@ts-ignore
        truncateText: false,
        enlarge: true,
        fullWidth: true,
      },
      render: (name: string, row: ILandscape) => (
        <EuiFlexGroup direction="column" gutterSize="none" className="p-15">
          <EuiFlexItem>
            <span style={{ color: 'white', fontWeight: 'bold' }}>{name}</span>
            <EuiFlexItem grow={false} className={`status ${DStatus[row.status ? row.status : 'On']}`}>
              {DStatus[row.status ? row.status : 'On']}
            </EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    },
    {
      field: 'sites_count',
      name: '# OF SITES',
      align: 'center',
      render: (sites_count: number) => sites_count,
      sortable: true,
    },
    {
      field: 'titles_count',
      name: '# OF TITLES',
      align: 'center',
      render: (titles_count: number) => titles_count,
      sortable: true,
    },
    {
      field: 'last_scan_title_updated',
      align: 'center',
      name: 'CURRENT TITLE SCAN',
      render: (last_scan_title_updated: IScanTitle | null) => last_scan_title_updated?.title.name,
      sortable: false,
    },
    {
      field: 'created_at',
      name: 'Created At',
      align: 'center',
      render: (date: string) => formatDate(date, 'dobLong'),
      sortable: true,
    },
    {
      name: 'EDIT',
      align: 'center',
      render: (item: ILandscape) => {
        return (
          <PopoverOptions
            panelClassName="optionsTable"
            anchorPosition="leftCenter"
            onChange={(key) => onRender(key, item)}
            button={
              <BiDotsHorizontalRounded />
            }
            options={
              [
                {
                  label: <Fragment><EuiIcon type="documentEdit" className="euiIcon" /> Edit Landscape </Fragment>,
                  value: 'edit'
                },
                {
                  label: <Fragment><ImFileText2 className="euiIcon" /> Details</Fragment>,
                  value: 'details'
                },
                {
                  label: <Fragment> <EuiIcon type="trash" /> Discard</Fragment>,
                  value: 'delete'
                }
              ]
            }
          />
        )
      }
    },
    {
      align: RIGHT_ALIGNMENT,
      width: '40px',
      isExpander: true,
      render: (item: ILandscape) => (
        <EuiButtonIcon
          onClick={() => toggleDetails(item.id, true)}
          aria-label={itemIdToExpandedRowMap[item.id] ? 'Collapse' : 'Expand'}
          iconType={itemIdToExpandedRowMap[item.id] ? 'arrowUp' : 'arrowDown'}
        />
      ),
    },
  ];

  const onRender = (key?: TRender, item?: ILandscape) => {
    if (key && render) {
      setRender(null)
      return
    }
    if (key) {
      setRender(key)
      if (item) setItem(item)
      return
    }

    setItem(null);
    setRender(null)
  }

  const deleteFilters = (type: TLandscapesFilter, item?: EuiComboBoxOptionOption | EuiCheckboxGroupOption | string) => {
    if (filters)
      switch (type) {
        case 'sites_count':
        case 'titles_count':
          setFilters({ ...filters, [type]: undefined })
          break;

        default:
          if (typeof item === 'object') {
            const index = filters[type]?.findIndex(q => q.value?.toString() === item?.value?.toString())

            if (index === 0 && Array.isArray(filters[type]) && filters[type]?.length === 0) {
              setFilters({ ...filters, [type]: [] })
              return
            }
            if (index || index === 0) {
              filters[type]?.splice(index, 1)
              setFilters({ ...filters, [type]: filters[type] })
            }
          }
          break;
      }
  }


  const renderBulksLeft = () => {
    return (
      <EuiFlexGroup className="euiTable-actions" gutterSize="none">
        <BulkSelecteds
          onClick={() => {
            onSelectionChange([]);
            tableRef.current?.clearSelection();
          }}
          total={selectedItems.length}
          type="Landscapes"
        />
        <SearchInput
          id="searchInputLandscapes"
          query={query}
          onChange={(e) => setQuery(e.target.value)}
          onClear={() => setQuery('')}
        />
        <EuiButtonEmpty onClick={() => onRender('filters')}>
          <RiFilter2Fill />
        </EuiButtonEmpty>
        <EuiFlexGroup className="filters--badges" >
          {filters && Object.keys(filters).map((key) => {
            const items = filters[key as TLandscapesFilter]
            switch (key as TLandscapesFilter) {

              case 'titles_count':
              case 'sites_count':
                if (filters.sites_count || filters.titles_count) {
                  return (
                    <BFilter
                      type={key}
                      // @ts-ignore
                      filter={`${filters[key][0].toLocaleString()} - ${filters[key][1].toLocaleString()} `}
                      onClear={() => deleteFilters(key as TLandscapesFilter, key)}
                    />
                  )
                }
                break

              default:
                // @ts-ignore
                return items.map((el: EuiComboBoxOptionOption) => {
                  return el.hasOwnProperty('label') ? (
                    <BFilter type={key} filter={el.label} onClear={() => deleteFilters(key as TLandscapesFilter, el)} />
                  ) : null
                })
            }
          }
          )}
        </EuiFlexGroup>
      </EuiFlexGroup>
    )
  }

  const onUpdateFilters = (value: EuiComboBoxOptionOption[] | EuiComboBoxOptionOption | string, key: TLandscapesFilter) => {
    setFilters({ ...filters, [key]: value })
  }


  const renderBulksRight = () => (
    <EuiFlexGroup className="bulk--actions" >
      {/* @ts-ignore */}
      <BulkExport hasSelecteds={hasSelecteds} onClick={() => getLandscapes({ pageSize: Number.MAX_SAFE_INTEGER })} data={csv} filename="landscapes" isLoading={loading.export} />
    </EuiFlexGroup>
  )

  const onSelectionChange = (selectedItems: ILandscape[]) => {
    setCsv(selectedItems)
    setSelectedItems(selectedItems);
  };

  const pagination: Pagination = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    totalItemCount: store?.count || 0,
    pageSizeOptions: HTable.pagination,
  };

  const sorting: EuiTableSortingType<ILandscape> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  const selection: EuiTableSelectionType<ILandscape> = {
    // selectable: (item: ILandscape) => true,
    // selectableMessage: (selectable: boolean) =>
    //   !selectable ? 'User is currently offline' : '',
    onSelectionChange: onSelectionChange,
  };

  const getLandscapes = async ({ page, pageSize, name, ordering, filters }: ILandscapesPagination) => {
    const isExport = pageSize === Number.MAX_SAFE_INTEGER
    try {
      if (isExport) {
        setLoading({ ...loading, export: true })
        setCsv([])
      } else setLoading({ ...loading, store: true });

      const response = await APILandscapes.fetchLandscapes({
        page: page || 1,
        pageSize,
        name,
        ordering,
        filters
      })

      if (isExport) {
        setCsv(response.results)

        setLoading({ ...loading, export: false })
        return
      }
      setStore(response)

      setLoading({ ...loading, store: false })
    } catch (e) {
      isExport ? setLoading({ ...loading, export: false }) : setLoading({ ...loading, store: true });
      toastsContext.addErrorToast({
        title: 'Couldn\'t complete landscape request',
        message: (e as Error).message
      });
    }
  }

  const debounceLandscapes = useCallback(
    debounce((params) => {
      getLandscapes(params)
    }, 1000),
    []
  );

  const onListenerStatus = () => {
    const rows = document.querySelectorAll(".cellWithRightBorder.euiTableRowCell--hideForMobile");

    store?.results.forEach((item, index) => {
      rows[index].classList.add(`${DStatus[item.status ? item.status : 'On']}`)
    })
  }

  useEffect(() => {
    onListenerStatus();
  }, [store])

  useEffect(() => {
    getLandscapes({ pageSize });
  }, [])

  const bulksLeft = renderBulksLeft()
  const bulksRight = renderBulksRight()

  const deleteLandscape = async (id: number) => {
    const response = await APILandscapes.deleteLandscape(id)

    if (response.status === 204) {
      const index = store?.results?.findIndex((item: ILandscape) => item.id === id)

      if (store && index && index > -1) {
        store?.results.splice(index, 1)

        onRender()
        setStore({ ...store, results: store?.results })
      }
    }
  }

  useEffect(() => {
    debounceLandscapes({ filters: selectedFilters, page: pageIndex + 1, pageSize, name: query })
  }, [filters, query])

  return (
    <PageTemplateTable
      id="landscapes-page"
      breadcrumbs={HBreadcrumbs.LANDSCAPES}
      bulksLeft={bulksLeft}
      bulksRight={bulksRight}
    >
      {(render === 'delete' && item) &&
        <MConfirm
          closeModal={onRender}
          confirm={() => { deleteLandscape(item?.id) }}
          confirmLabel="Delete Landscape"
          body={
            <>
              <EuiText>
                Are you sure you want to delete the landscape <b>{item?.name}</b>?
              </EuiText>

              <EuiText style={{ marginTop: '3%' }}>
                Deleting a landscape <b>can not be undone</b> after your confirmation.
              </EuiText>
            </>
          }
          title="Delete Landscape"
          className="modalDelete"
        />
      }
      <EuiBasicTable
        ref={tableRef}
        items={pageOfItems || []}
        itemId="id"
        tableLayout="auto"
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        isExpandable={true}
        hasActions={true}
        loading={loading.store}
        columns={columns}
        pagination={pagination}
        sorting={!loading.store ? sorting : undefined}
        isSelectable={true}
        selection={selection}
        onChange={onTableChange}
        noItemsMessage={<EuiFlexItem className="p-30">No items found</EuiFlexItem>}
      />
      {render === 'details' && <MDetails title="Details - Landscape" onClose={onRender} rows={rows} />}
      {(item && render === 'edit') &&
        <MLandscape
          onClose={onRender}
          landscape={item}
          callback={(landscape: ILandscape) => {
            const index = store?.results?.findIndex((item: ILandscape) => item.id === landscape.id)

            const rows = document.querySelectorAll(".cellWithRightBorder.euiTableRowCell--hideForMobile")
            rows[index].classList.remove(`${DStatus[store?.results[index].status]}`);

            store.results[index] = { ...store.results[index], ...landscape }

            setStore({ ...store, results: store.results })

            rows[index].classList.add(`${DStatus[landscape.status]}`);
          }}
        />
      }
      <FFLandascapes
        isOpen={render === 'filters'}
        onClose={onRender}
        filters={filters}
        onChange={onUpdateFilters}
        resetFilters={() => setFilters(undefined)}
      />
    </PageTemplateTable>
  );
};

export default Landscapes;
