
import { IDict } from "../@types/IGlobal"

export const DStatus: IDict = {
  "On": "Active",
  "Off": "Inactive",
  "Redirect": "Redirect"
}

export const DCalendarMonth: IDict = {
  '01': 'JAN',
  '02': 'FEB',
  '03': 'MAR',
  '04': 'APR',
  '05': 'MAY',
  '06': 'JUN',
  '07': 'JUL',
  '08': 'AUG',
  '09': 'SEP',
  '10': 'OCT',
  '11': 'NOV',
  '12': 'DEC',
}

export const DCategoryColor: IDict = {
  clickbait: "#000000",
  other: "#000099",
  "?": "#0000ff",
  pt: "#009900",
  game: "#009999",
  apps: "#0099ff",
  telecom: "#00ff00",
  box: "#00ff99",
  cardsharing: "#00ffff",
  cyber: "#990000",
  cyberlocker: "#990099",
  forum: "#9900ff",
  music: "#999900",
  "torrent f": "#999999",
  subtitles: "#9999ff",
  live: "#99ff00",
  torrent: "#FF8D69",
  software: "#99ff99",
  download: "#4f8080",
  iks: "#ff0000",
  iptv: "#ff0099",
  marketplace: "#ff00ff",
  blog: "#ff9900",
  streaming: "#00C1D4",
}



export const DTypeColors: IDict = {
  tv: "#00C1D4",
  movie: "#FF8D69"
}
