import { FC, Fragment, useState } from "react";
import { EuiButtonEmpty, EuiCheckboxGroup, EuiDatePicker, EuiComboBox, EuiFormRow, EuiPopover, EuiPopoverFooter, EuiComboBoxOptionOption } from "@elastic/eui";
import moment from "moment";
import { DataPickerOptionsWrapper, Recur, DataPickerBody, DataPickerRange, Row } from "./styles";
import HOptions from "src/helpers/options";
import RowDate from "../Rows/RowDate";

type TRecur = (
  "year" | "years" | "y" |
  "month" | "months" | "M" |
  "week" | "weeks" | "w" |
  "day" | "days" | "d" |
  "hour" | "hours" | "h" |
  "minute" | "minutes" | "m" |
  "second" | "seconds" | "s" |
  "millisecond" | "milliseconds" | "ms"
);

interface Props {
  readOnly?: boolean
  startDate: string | null
  endDate: string | null
  dueDate: string | null
  isForever: boolean
  recurrency: string | null
  isLoading: boolean
  onSubmit: (data: Object, callback: Function) => void
}

const DatePickerRecur: FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dueDate, setDueDate] = useState(props.dueDate ? moment(props.dueDate) : moment())
  const [startDate, setStartDate] = useState(props.startDate ? moment(props.startDate) : moment())
  const [endDate, setEndDate] = useState(props.endDate ? moment(props.endDate) : moment())
  const [recurrency, setRecurrency] = useState<EuiComboBoxOptionOption[]>(props.recurrency ? [{ value: props.recurrency, label: props.recurrency }] : []);
  const [errors, setErrors] = useState<any>(undefined)
  const [isForever, setIsForever] = useState(props.isForever)

  const hasRecur = recurrency ? recurrency.length > 0 : null

  const onForever = () => {
    setIsForever(forever => !forever)
  }

  const onSubmit = () => {
    if (!hasRecur && !moment(dueDate).isValid()) {
      setErrors({ ...errors, due_date: 'Due Date field is required' })
      return;
    }
    if (hasRecur) {
      if (!moment(startDate).isValid()) {
        setErrors({ ...errors, start_date: 'Start Date field is required' })
      }
      if (!isForever && !moment(endDate).isValid()) {
        setErrors({ ...errors, end_date: 'End Date field is required' })
      }
      if (startDate > endDate) {
        setErrors({ ...errors, start_date: 'Start Date must be less than End Date' })
      }
      return
    }
    props.onSubmit({
      due_date: !hasRecur ? moment(dueDate).format('YYYY-MM-DD') : null,
      start_date: hasRecur ? moment(startDate).format('YYYY-MM-DD') : null,
      end_date: (hasRecur && !isForever) ? moment(endDate).format('YYYY-MM-DD') : null,
      recurrency: hasRecur ? recurrency[0].value : null,
      forever: isForever
    }, () => setOpen(false))
  }


  return (
    <EuiPopover
      button={
        <RowDate
          onClick={() => props.readOnly ? null : setOpen(open => !open)}
          recurrency={props.recurrency}
          startDate={props.startDate}
          endDate={props.endDate}
          dueDate={props.dueDate}
          isForever={props.isForever}
        />
      }
      panelClassName={`popover date-picker`}
      isOpen={open}
      closePopover={() => setOpen(false)}
      anchorPosition="downLeft"
      hasArrow={false}
    >
      <DataPickerOptionsWrapper>
        <DataPickerRange>
          <EuiFormRow label={hasRecur ? 'Start date' : 'Due date'} isInvalid={(errors?.start_date || errors?.due_date) ? true : false} error={(errors?.start_date || errors?.due_date)}>
            <EuiDatePicker
              selected={hasRecur ? startDate : dueDate}
              onChange={(date: moment.Moment) => {
                delete errors?.start_date
                delete errors?.due_date
                hasRecur ? setStartDate(date) : setDueDate(date)

              }}
              placeholder={hasRecur ? 'Start date' : 'Due date'}
              startDate={hasRecur ? startDate : dueDate}
              endDate={hasRecur ? endDate : dueDate}
              // isInvalid={startDate > endDate}
              aria-label="Start date"
            />
          </EuiFormRow>
          {(hasRecur && !isForever) &&
            <Fragment>
              <div className="euiText euiText--small euiDatePickerRange__delimeter" ><div className="euiTextColor euiTextColor--subdued">→</div></div>
              <EuiFormRow label="End date" isInvalid={errors?.end_date ? true : false} error={errors?.end_date}>
                <EuiDatePicker
                  scrollableYearDropdown
                  selected={endDate}

                  onChange={(date: moment.Moment) => {
                    delete errors?.end_date
                    setEndDate(date)
                  }}

                  startDate={startDate}
                  endDate={endDate}
                  // isInvalid={startDate > endDate}
                  aria-label="End date"
                  placeholder="End date"
                />
              </EuiFormRow>
            </Fragment>
          }
        </DataPickerRange>
        <DataPickerBody>
          <EuiFormRow label="Recur">
            <Recur>
              <EuiComboBox
                compressed
                singleSelection={{ asPlainText: true }}
                sortMatchesBy="startsWith"
                placeholder="None"
                options={HOptions.recurrences}
                selectedOptions={recurrency}
                onChange={(options) => {
                  setErrors(undefined)
                  setRecurrency(options)
                }
                }
              />
              {hasRecur &&
                <EuiCheckboxGroup
                  options={[{ id: 'isForever', value: 'isForever', label: "Recur forever" }]}
                  idToSelectedMap={{ isForever }}
                  onChange={onForever}
                />
              }
            </Recur>
          </EuiFormRow>
          <EuiDatePicker
            inline
            shadow={false}
            selected={hasRecur ? startDate : dueDate}
            // @ts-ignore
            onChange={hasRecur ? null : setDueDate}
            startDate={hasRecur ? startDate : dueDate}
            endDate={hasRecur ? endDate : dueDate}
          />
        </DataPickerBody>
      </DataPickerOptionsWrapper>
      <EuiPopoverFooter>
        <EuiButtonEmpty onClick={() => setOpen(false)}>
          Cancel
        </EuiButtonEmpty>
        <EuiButtonEmpty onClick={onSubmit} isLoading={props.isLoading}>
          Save
        </EuiButtonEmpty>
      </EuiPopoverFooter>
    </EuiPopover>
  )
}

export default DatePickerRecur